.title {
    margin-bottom: 2px;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    display: flex;
    align-items: center;
}

.container {
    align-items: baseline;
    gap: 0.5rem;
}

@media screen and (min-width: 928px) {
    .container {
        flex-direction: row !important;
        justify-content: space-between !important;
    }
}
