.container {
    width: 100%;
    align-items: center;
    justify-content: center;
}

.fullScreen {
    height: 100vh;
    margin-bottom: 50vh;
}

.halfScreen {
    height: 50vh;
    margin-bottom: 50vh;
}
