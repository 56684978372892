.titleContainer {
    margin-top: 16px;
    margin-bottom: 40px;
}

.title {
    font-weight: var(--font-weight-semibold) !important;
    font-size: var(--font-h4);
    line-height: 40px;
    margin: 0;
    display: block;
}

@media screen and (min-width: 928px) {
    .title {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
    }
}
