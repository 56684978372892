.icon {
    stroke: black !important;
    margin-right: 7px;
}

.activeIcon path {
    stroke: white !important;
}

.title {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-medium);
    line-height: var(--line-medium);
    margin-bottom: var(--margin);
}
