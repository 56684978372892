@value customBoxShadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px !important;
@value customBoxShadowHover: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 6px 16px !important;
@value customTransition: box-shadow 0.2s ease 0s, -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important;
@value customBlackColor: rgba(0, 0, 0, 0.08);

.container {
    display: flex;
}

.list {
    display: flex;
    list-style: none;
    align-items: center;
    cursor: pointer;
}

.pageLink {
    font-size: var(--font-size-body);
}

.imgSize {
    height: 1rem;
}

.list li {
    margin-right: var(--padding-medium);
    height: 32px;
    width: 32px;
    justify-content: center;
    align-content: center;
    display: flex;
    border-radius: 20rem;
}

.list li:not(.active):hover {
    background: customBlackColor;
}

.list li a {
    align-self: center;
    display: flex;
    outline: 0 !important;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.active:not(a) {
    background: var(--color-gray-darker);
    color: white;
    font-weight: var(--font-weight-semibold);
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
    box-shadow: customBoxShadow;
    transition: customTransition;
}

.active a {
    color: white;
}

:global(li.next) {
    border: 1px solid customBlackColor !important;
    background-color: rgba(255, 255, 255, 0.9) !important;
    box-shadow: customBoxShadow;
    transition: customTransition;
}
:global(li.next:hover) {
    background-color: var(--color-white);
    color: var(--color-gray-darker) !important;
    box-shadow: customBoxShadowHover;
    transform: scale(1.04) !important;
}

:global(li.next) a {
    align-self: center;
    outline: 0 !important;
}

:global(li.previous) {
    border: 1px solid customBlackColor !important;
    background-color: rgba(255, 255, 255, 0.9) !important;
    box-shadow: customBoxShadow;
    transition: customTransition;
}
:global(li.previous:hover) {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(0, 0, 0) !important;
    box-shadow: customBoxShadowHover;
    transform: scale(1.04) !important;
}

:global(li.previous) a {
    align-self: center;
    outline: 0 !important;
}

.disabled {
    display: none !important;
}
