.modal {
    padding: 0 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-bottom: 48px;
}

.modalHeader {
    font-size: var(--font-xxlarge);
    font-weight: var(--font-weight-semibold);
    margin: unset;
    background-color: var(--color-white);
}

.modalRating {
    align-items: center;
}

.avgRating {
    font-size: var(--font-xlarge);
    font-weight: var(--font-weight-medium);
    margin: unset;
    margin-right: 1rem;
}

.ratings {
    font-size: var(--font-xlarge);
    margin: unset;
    color: var(--color-gray-light);
}

.icon {
    margin-right: 1rem;
    width: auto;
    height: 1.5rem;
}

.reviews {
    border-left: 1px solid var(--color-pearl);
}

.reviews > span {
    color: var(--color-grayish);
}

.optionalRating {
    justify-content: space-between;
}
.optionalRating span {
    display: flex;
    font-size: var(--font);
    margin-bottom: 0.5rem;
    width: 100%;
}

.modal > .container {
    padding: 0;
    width: 100%;
}

.container > div:last-child {
    flex-direction: column;
}

.container > div:last-child > div:last-child {
    border-left: none;
}

.container > .modalHeader {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    position: sticky;
    left: 0;
    top: 0;
    margin-left: -24px;
    width: 100vw;
    padding: 35px 24px 14px 24px;
    box-sizing: border-box;
    border-bottom: 1px solid #9da09d29;
    margin-bottom: 32px;
}

.modalHeader > span {
    font-size: var(--font-medium);
    grid-column: 2 / 3;
    justify-self: center;
    font-weight: var(--font-weight-medium);
}

.modalHeader > svg {
    width: 32px;
    height: 32px;
    stroke-width: 1px;
    stroke: #0b0b0d;
    grid-column: 1 / 2;
}

.headerSplitter {
    margin-top: 14px;
}

.splitter {
    margin: 32px 0;
}

@media screen and (min-width: 769px) {
    .modal {
        width: 90vw;
        height: 80vh;
        max-height: 768px;
        max-width: 1200px;
        box-sizing: border-box;
        padding-left: 40px;
        padding-right: 40px;
        border-radius: 8px;
    }

    .container > div:last-child {
        flex-direction: row;
    }

    .container > .modalHeader {
        width: 90vw;
        margin-left: -40px;
        padding-left: 40px;
        padding-right: 40px;
        max-width: 1200px;
    }

    .reviews {
        max-height: calc(768px - 114px - 48px);
        height: calc(80vh - 114px - 48px);
        overflow-y: scroll;
    }

    .modalRating {
        padding-bottom: 36px;
        border-bottom: 1px solid #9da09d29;
    }

    .container > div:nth-child(2) > div:first-child {
        margin-right: 0;
    }

    .container > div:nth-child(2) > div:nth-child(2) {
        margin: 0 36px;
        border-left: 1px solid #9da09d29;
        margin-bottom: 0 !important;
    }

    .modalHeader > svg {
        grid-column: 3 / 4;
        justify-self: end;
    }
}
