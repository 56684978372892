.card {
    box-sizing: border-box;
    color: black;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 16px #0b0b0d14;
    border-radius: 16px;
    min-height: 500px;
    overflow: hidden !important;
}

.container {
    margin-bottom: 3rem;
    padding-right: 0;
    padding-left: 0;
}

.stats {
    display: flex;
    align-items: center;
    flex: 0 !important;
}

.stats span {
    margin-left: 0.75rem;
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    color: #9da0aa;
    white-space: nowrap;
}

.imageContainer {
    position: relative;
    height: 320px;
}

.rectangleLeft {
    position: absolute;
    bottom: -3rem;
    left: -13rem;
}

.rectangleRight {
    position: absolute;
    top: -15rem;
    right: -10rem;
}

.header {
    padding: 2rem;
}

.title {
    font-size: var(--font-xxlarge);
    font-weight: var(--font-weight-semibold);
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.description {
    display: block;
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}

.description span {
    color: var(--color-red);
}

@media only screen and (min-width: 424px) {
    .card {
        min-height: 564px;
    }
    .imageContainer {
        height: 420px;
    }
    .rectangleLeft {
        bottom: 0rem;
        left: -13rem;
    }

    .rectangleRight {
        top: -14rem;
        right: -10rem;
    }
    .title {
        font-size: var(--font-h4);
    }

    .description {
        font-size: var(--font-medium);
    }

    .stats span {
        font-size: var(--font-medium);
    }
}
@media only screen and (min-width: 692px) {
    .card {
        min-height: 444px;
    }
    .imageContainer {
        height: 300px;
    }
    .rectangleLeft {
        bottom: -3rem;
        left: -15rem;
    }

    .rectangleRight {
        top: -15rem;
        right: -15rem;
    }
    .title {
        font-size: var(--font-xxlarge);
    }

    .description {
        font-size: var(--font);
    }

    .stats span {
        font-size: var(--font);
    }

    .container {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
    }
}
@media only screen and (min-width: 928px) {
    .card {
        min-height: 564px;
    }
    .imageContainer {
        height: 400px;
    }
    .rectangleLeft {
        bottom: -3rem;
        left: -13rem;
    }

    .rectangleRight {
        top: -15rem;
        right: -10rem;
    }
    .description {
        font-size: var(--font-medium);
    }

    .stats span {
        font-size: var(--font);
    }
}
@media only screen and (min-width: 1360px) {
    .card {
        min-height: 540px;
    }
    .imageContainer {
        height: 340px;
    }
    .rectangleLeft {
        bottom: -3rem;
        left: -13rem;
    }

    .rectangleRight {
        top: -15rem;
        right: -10rem;
    }

    .stats span {
        font-size: var(--font);
    }
}

@media only screen and (min-width: 1680px) {
    .card {
        min-height: 564px;
    }

    .imageContainer {
        height: 420px;
    }

    .title {
        font-size: var(--font-h4);
    }

    .description {
        font-size: var(--font-medium);
    }
    .rectangleLeft {
        bottom: 0rem;
        left: -13rem;
    }

    .rectangleRight {
        top: -15rem;
        right: -10rem;
    }

    .stats span {
        font-size: var(--font-medium);
    }
}
