.phoneInputContainer {
    align-items: center;
    margin-top: 4px;
}

/* Phone Input Select */
.phoneInputSelect {
    width: 9rem;
    padding: unset;
    flex: unset !important;
    margin-right: 0 !important;
}

.phoneInputSelect :global(div.dropdown__control) {
    min-height: 50px;
    background: transparent;
    border: 1px solid var(--color-gray-light-border);
    margin-top: 4px;
    padding-left: 6px;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.phoneInputSelect :global(div.dropdown__menu) {
    border: 1px solid var(--color-gray-light-border);
    border-radius: 0;
}

.phoneInputSelect :global(div.dropdown__menu-list) {
    max-height: 140px;
    background: transparent;
}

.phoneInputSelect :global(div.dropdown__option) {
    background: transparent;
}

.phoneInputSelect :global(div.dropdown__option--is-focused) {
    cursor: pointer;
    color: var(--color-gray-dark);
    background-color: var(--color-gray-lighter);
    font-weight: var(--font-weight-medium);
}

.phoneInputSelect :global(div.dropdown__option--is-selected) {
    color: var(--color-gray-dark);
    background-color: var(--color-gray-lighter);
    font-weight: var(--font-weight-medium);
}

.phoneInputSelect :global(div.dropdown__control.dropdown__control--is-focused) {
    border-bottom: thin solid var(--color-purple) !important;
}

/* Phone Input Text */

.phoneInputText {
    flex: 1;
}

.phoneInputText input {
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.phoneInputContainer.inputFocus :global(div.dropdown__control),
.phoneInputContainer.inputFocus .phoneInputText input {
    border-bottom: thin solid var(--color-purple) !important;
}

.phoneInputContainer.mouseIn :global(div.dropdown__control),
.phoneInputContainer.mouseIn .phoneInputText input {
    border-bottom: thin solid var(--color-gray-light);
}

.phoneInputContainer.mouseOut :global(div.dropdown__control),
.phoneInputContainer.mouseOut .phoneInputText input {
    border-bottom: thin solid var(--color-gray-lighter);
}

.labelDisabled {
    color: var(--color-gray-dark);
}
