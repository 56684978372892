.text {
    font-weight: var(--font-weight-regular);
    font-style: normal;
    font-size: var(--font-size-body);
    color: var(--color-gray-darker);
}

/* Font Sizes */
.center {
    text-align: center;
}

.h1 {
    font-size: var(--font-h3);
    line-height: var(--line-h3);
}

.h2 {
    font-size: var(--font-h4);
    line-height: 40px;
}

.h3 {
    font-size: var(--font-h5);
    line-height: 36px;
}

.h4 {
    font-size: var(--font-xxlarge);
    line-height: var(--line-xxlarge);
}

.h5 {
    font-size: var(--font-large);
    line-height: var(--line-large);
}

.md {
    font-size: var(--font);
    line-height: var(--line);
}

.sm {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
}

/* Colors */
.grayish {
    color: var(--color-gray-light);
}

.gray-light {
    color: var(--color-gray-light);
}

/* Font Weights */
.bold {
    font-weight: var(--font-weight-bold);
}

.semibold {
    font-weight: var(--font-weight-semibold);
}

.medium {
    font-weight: var(--font-weight-medium);
}

.regular {
    font-weight: var(--font-weight-regular);
}

.light {
    font-weight: var(--font-weight-light);
}
