.spin {
    animation: icon-spin 1.5s infinite linear;
    stroke: var(--color-green);
}

.spinBlack path {
    fill: var(--color-black);
}

.spinRed path {
    fill: var(--color-red);
}

.fullHeight {
    align-self: center;
    flex: 1;
}

.large {
    font-size: var(--font-xlarge) !important;
}

@-webkit-keyframes icon-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

@keyframes icon-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}
