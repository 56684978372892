.accordion {
    padding: 20px;
    margin-bottom: 28px;
    border: 1px solid var(--color-gray-light-border);
    border-radius: 4px;
    cursor: pointer;
}

.selected {
    border-color: var(--color-red);
}

/* 
    using !important because it styles injected html from the database 
    that comes with inline styling
*/
.details,
.details * {
    color: var(--color-grayish);
    font-weight: var(--font-weight-light);
}
