.optionsContainer {
    position: fixed !important;
    bottom: 1rem !important;
    right: 0 !important;
    left: 0 !important;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2;
    bottom: 2.7rem !important;

    flex: 0;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
}

.bottomOptionsWrapper {
    justify-content: stretch;
    align-items: stretch;
    background-color: var(--color-gray-darker);
    white-space: nowrap;
    border-radius: 10rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.bottomOptionsButton {
    min-width: 4.5rem;
    padding: var(--padding) var(--padding-medium);
    color: white;
    margin-right: 0 !important;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
}

.bottomOptionsIcon {
    width: 1.8rem;
    height: 1.8rem;
    min-width: 20px;
    min-height: 20px;
}

.bottomOptionsButton:active {
    transform: scale(0.98);
    opacity: 0.7;
}

.bottomOptionsSeparator {
    width: 0.5px;
    background-color: white;
    flex: 0 auto !important;
    margin-right: 0 !important;
}

.fixed {
    bottom: 1rem !important;
    position: sticky !important;
}

.indicator {
    color: white;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
}

.indicatorBox {
    background-color: var(--color-red);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    left: 5px;
    position: relative;
    padding: 0px 4px;
    display: flex;
    justify-content: center;
}
