.sectionRow {
    margin-bottom: 80px !important;
    position: relative;
}

.pageTitle > span,
.breadcrumbs > div > a,
.breadcrumbs > div > * {
    color: var(--color-white) !important;
}

.breadcrumbs {
    margin-top: 32px;
}

.teamSection {
    flex-direction: column !important;
    margin-top: 40px;
}

div.sectionRow > div {
    flex: unset;
    margin-right: 0;
}

div.sectionRow > div:not(:last-child) {
    margin-right: 0;
}

.col {
    width: 100%;
}

.col > .carouselContainer {
    overflow: visible;
    margin-top: 4rem !important;
}

.carouselItem {
    background-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 0 4px;
}

.carouselItem:first-child {
    /*this negative margin aligns the carousel items to the left limit*/
    margin-left: -4px;
}

.background > img {
    filter: brightness(75%);
}

.arrow {
    position: absolute;
    width: 44px;
    height: 44px;
    stroke: var(--color-black);
    stroke-width: 0.75px !important;
}

.arrowRight {
    right: -16px;
    bottom: -70px;
}

.arrowLeft {
    transform: rotate(180deg);
    left: -16px;
    bottom: -70px;
}

.leaderRectangle {
    z-index: -10;
    left: -110px;
    top: 87%;
    width: 180px;
}

.sectionRow > .teamColumn {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;
    grid-column-gap: 16px;
}

.sectionTitle {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
}

.hiringTitle {
    margin-bottom: 16px;
}

.hiringBody {
    line-height: var(--line);
    font-size: var(--font);
    display: block;
    margin-bottom: 0;
    font-weight: var(--font-weight-light);
}

.teamButton {
    width: 230px;
}

.sectionRow > * {
    z-index: 10;
}

.rect {
    position: absolute;
}

.teamRectangle {
    z-index: 0;
    width: 200px;
    height: 200px;
    top: 47%;
    left: -130px;
}

.sectionRow > .hiringRectangle {
    z-index: 0;
    bottom: -15px;
    right: -30%;
    width: 150px;
    height: 150px;
}

.joinTeam {
    font-weight: var(--font-weight-semibold);
}

@media screen and (min-width: 492px) {
    .sectionRow > .teamColumn {
        grid-column-gap: 32px;
    }

    .leaderRectangle {
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-max-width)) / -2) - 150px);
    }

    /* this keeps the rectangle to the very edge of the screen */
    .teamRectangle {
        left: calc(((100vw - var(--page-max-width)) / -2) - 100px);
    }

    /* this keeps the rectangle to the very edge of the screen */
    .sectionRow > .hiringRectangle {
        right: calc(((100vw - var(--page-max-width)) / -2) - 70px);
    }
}

@media screen and (min-width: 692px) {
    .teamBackground {
        left: calc((100vw - var(--page-max-width)) / -2);
        width: 100vw;
        transform: scale(1 + 1%);
    }

    .carouselItem {
        padding: 0 16px;
    }

    .carouselItem:first-child {
        /*this negative margin aligns the carousel items to the left limit*/
        margin-left: -16px;
    }

    .teamRectangle {
        top: 230px;
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-max-width)) / -2) - 140px);
    }

    .sectionRow > .teamColumn {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 928px) {
    .sectionRow > .teamColumn {
        grid-template-columns: repeat(4, 1fr);
    }

    .leaderRectangle {
        width: 300px;
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-width)) / -2) - 180px);
        top: 525px;
    }

    .teamSection {
        margin-bottom: 120px !important;
        margin-top: 60px !important;
    }

    .teamButton {
        margin-top: 40px;
    }

    .hiringSection {
        margin-bottom: 120px !important;
    }

    .hiringCol {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hiringTitle,
    .hiringBody {
        text-align: center !important;
    }

    .hiringTitle {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
        width: 580px;
        margin-bottom: 24px;
    }

    .hiringBody {
        width: 580px;
        font-size: var(--font-large);
        line-height: var(--line-xlarge);
    }

    .teamRectangle {
        top: unset;
        bottom: 25px;
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-width)) / -2) - 150px);
    }

    .sectionRow > .hiringRectangle {
        /* this keeps the rectangle to the very edge of the screen */
        right: calc(((100vw - var(--page-width)) / -2) - 70px);
    }
}

@media screen and (min-width: 1360px) {
    .carouselItem {
        padding: 0 20px;
    }

    .carouselItem:first-child {
        /* this negative margin aligns the carousel items to the left limit */
        margin-left: -20px;
    }

    .teamSection {
        margin-bottom: 160px !important;
    }

    .teamButton {
        margin-top: 56px;
    }

    .teamButton > span {
        font-weight: var(--font-weight-light);
    }

    .teamRectangle {
        bottom: -200px;
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-width)) / -2) - 120px);
    }

    .sectionRow > .teamColumn {
        grid-template-columns: repeat(5, 1fr);
    }

    .hiringSection {
        margin-bottom: 160px !important;
    }

    .hiringTitle {
        font-size: var(--font-h2);
        line-height: var(--line-h2);
    }

    .joinTeam {
        display: block;
        margin-top: 4px;
    }

    .sectionTitle {
        margin-bottom: 56px;
    }
}

@media screen and (min-width: 1440px) {
    .sectionRow > .teamColumn {
        grid-template-columns: repeat(6, 1fr);
    }

    .leaderRectangle {
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-max-width)) / -2) - 180px);
    }

    .teamRectangle {
        top: 300px;
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-max-width)) / -2) - 120px);
    }

    .sectionRow > .hiringRectangle {
        /* this keeps the rectangle to the very edge of the screen */
        right: calc(((100vw - var(--page-max-width)) / -2) - 70px);
    }
}

@media screen and (min-width: 1680px) {
    .carouselItem {
        max-width: 428px;
    }
}
