.searchInput div input {
    box-sizing: border-box;
    border-color: #e2e3e7;
    padding: var(--margin-small) var(--margin) !important;
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    margin: 0;
}

.searchInput div {
    margin: 0;
}

.searchHeader {
    margin-bottom: var(--margin);
}

.arrowBack {
    align-self: center;
    margin-right: var(--margin-small);
    min-width: 24px;
    min-height: 24px;
    height: 24px;
    width: 24px;
}

.noResultsText {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    color: var(--color-grayish);
}

.noResultsText strong {
    font-weight: var(--font-weight-medium);
}

.resultItem {
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: var(--margin) 0;
    border-bottom: 1px solid var(--color-gray-lighter);
}

.resultItem:last-child {
    border-bottom: none;
}

.searchResultTitleHighlight {
    font-weight: var(--font-weight-medium);
    background-color: white;
    padding: unset !important;
}

.centerSpinner {
    display: flex;
    justify-content: center;
}

.addressComplete {
    border: 1px solid var(--color-gray-light-border);
    border-radius: 56px;
    box-sizing: border-box;
}
