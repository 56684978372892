:root {
    --padding-small: 0.5rem;
    --padding: 0.7rem;
    --padding-medium: 1.5rem;
    --padding-large: 2rem;
    --padding-xlarge: 3rem;
    --padding-xxlarge: 5rem;

    --xs_space: 4px;
    --margin-internal: 8px;
    --margin-small: 12px;
    --small-extra_space: 14px;
    --margin: 16px;
    --normal-extra_space: 20px;
    --margin-medium: 24px;
    --margin-large: 32px;
    --margin-xlarge: 40px;
    --margin-xxlarge: 48px;
    --margin-xl-space: 56px;
    --xl-extra_space: 64px;
    --margin-xxxlarge: 80px;
    --margin-xxxxlarge: 120px;

    --box-shadow-hover: 0px 2px 1px 0px var(--color-gray-lighter);
    --box-radius: 2px;
    --button-radius: 3px;

    --radius: 2px;
    --input-radius: 40px;

    --tablet-small: 492px;
    --tablet: 692px;
    --desktop-small: 928px;
    --desktop: 1360px;
    --desktop-big: 1680px;
}

.smallPadding {
    padding: var(--padding-small);
}

.smallMarginBottom {
    margin-bottom: var(--margin-small);
}

.mediumPaddingHorizontal {
    padding-left: var(--padding-medium);
    padding-right: var(--padding-medium);
}

.componentPaddingVertical {
    padding-top: var(--padding);
    padding-bottom: var(--padding);
}

.internalMarginBottom {
    margin-bottom: var(--margin-internal);
}

.mediumPadding {
    padding: var(--padding-medium);
}

.presentationMarginTop {
    margin-top: var(--margin-small);
}

.componentMarginBottom {
    margin-bottom: var(--margin);
}

@media only screen and (max-width: 491px) {
    :root {
        --page-width: calc(100vw - 40px);
        --page-max-width: calc(100vw - 40px);
    }
}

@media only screen and (min-width: 492px) {
    :root {
        --page-width: 444px;
        --page-max-width: 444px;
    }
}

@media only screen and (min-width: 692px) {
    :root {
        --page-width: 644px;
        --page-max-width: 644px;
    }
}

@media only screen and (min-width: 928px) {
    :root {
        --page-width: 880px;
        --page-max-width: 880px;
    }
}

@media only screen and (min-width: 1360px) {
    :root {
        --page-width: 1160px;
        --page-max-width: 1160px;
    }
}

@media only screen and (min-width: 1680px) {
    :root {
        --page-width: 1364px;
        --page-max-width: 1364px;
    }
}
