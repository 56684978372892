.left {
    justify-content: flex-start;
    align-items: center;
    flex-basis: 70% !important;
}

.left .mobile {
    display: none;
}

.left .desktop {
    display: flex;
}

.linksContainer {
    justify-content: flex-start;
    align-items: center;
    display: none;
}

.logo {
    margin-right: 40px !important;
    display: flex;
    align-items: center;
    flex: 0 !important;
    cursor: pointer;
    width: auto;
}

.logo > svg {
    max-width: 100px;
    max-height: 16px;
}

.right {
    display: none;
    justify-content: flex-end;
    flex-basis: 30% !important;
    align-items: center;
}

.text {
    margin-right: 40px;
    font-size: var(--font-xsmall) !important;
    line-height: var(--line-xsmall);
    font-family: var(--font-family-secondary) !important;
    font-weight: var(--font-weight-light) !important;
    margin-bottom: 0px;
    letter-spacing: 0.5px;
}

@media only screen and (min-width: 320px) {
    .left {
        flex-basis: 20% !important;
    }

    .left .mobile {
        display: block;
    }

    .left .desktop {
        display: none;
    }

    .right {
        flex-basis: 80% !important;
    }
}

@media screen and (min-width: 691px) {
    .left {
        flex-basis: 50% !important;
    }

    .left .mobile {
        display: block;
    }

    .left .desktop {
        display: none;
    }

    .right {
        flex-basis: 50% !important;
    }
}

@media screen and (min-width: 928px) {
    .left {
        flex-basis: 75% !important;
        margin-right: 0 !important;
    }

    .left .mobile {
        display: none;
    }

    .left .desktop {
        display: flex;
    }

    .right {
        flex-basis: 25% !important;
    }

    .linksContainer {
        display: flex;
    }

    .menu {
        display: none !important;
    }

    .white {
        color: #fff;
    }

    .headerLogoPages {
        margin-right: 40px !important;
    }
}

@media screen and (min-width: 1360px) {
    .logo > svg {
        max-width: 140px;
        max-height: 24px;
    }
}
