.car {
    height: 37vw;
    align-self: center;
    position: absolute;
    right: -11vw;
    top: 10vw;
    bottom: 0;
    margin: auto;
    animation: normal;
    animation-name: slideLeft;
    animation-duration: 2s;
    -webkit-animation: normal;
    -webkit-animation-name: slideLeft;
    -webkit-animation-duration: 2s;
}

.banner {
    background-image: url('../../../../assets/images/design/intro_banner.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    width: 100%;
    height: 50vw;
    min-height: 40rem;
    z-index: -1;
    right: 0;
    position: absolute;
    left: 0;
    top: 12vh;
    bottom: 0;
    overflow: hidden;
}

.wide {
    background-image: url('../../../../assets/images/design/intro_banner_wide.svg') !important;
    width: 152vw;
}

.relative {
    position: relative;
}

.relative .wide {
    top: -10.5rem !important;
    height: 118vw !important;
    position: relative !important;
}

.relative .wide .car {
    height: 86vw !important;
    top: 20vw !important;
    right: 1rem;
}

.relative .wide .banner {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
}

.carBannerRectangleMd {
    position: absolute;
    top: 37%;
    left: -11rem;
}

.carBannerRectangleSm {
    position: absolute;
    top: 8rem;
    left: 2rem;
}

@media screen and (min-width: 692px) {
    .relative .wide .car {
        top: 0vw !important;
        right: -8rem;
    }

    .relative .wide {
        left: -8rem;
        height: 97vw !important;
    }
}

@media screen and (min-width: 1360px) {
    .car {
        top: 6vw;
    }

    .banner {
        height: 55vw;
        min-height: unset;
    }
}

@media screen and (min-width: 1680px) {
    .car {
        height: 48rem;
        right: -11vw;
        top: 6rem;
    }

    .banner {
        height: 65rem;
        right: 0;
        left: 0;
    }
}

@keyframes slideLeft {
    0% {
        transform: translateX(60rem);
    }
    100% {
        transform: translateX(0rem);
    }
}

@-webkit-keyframes slideLeft {
    0% {
        transform: translateX(60rem);
    }
    100% {
        transform: translateX(0rem);
    }
}
