.arrowForward {
    width: 20px !important;
    height: 20px !important;
}

.arrowForward > path {
    stroke-width: 25px;
    stroke: var(--color-red) !important;
}

.searchSection {
    max-width: var(--page-max-width);
    width: var(--page-width);

    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
}
