:root {
    --font-family: 'Open Sans', sans-serif;
    --font-family-secondary: 'Poppins', sans-serif !important;

    /* FONT WEIGHT */
    --font-weight-bold: 700 !important;
    --font-weight-semibold: 600 !important;
    --font-weight-medium: 500 !important;
    --font-weight-regular: 400 !important;
    --font-weight-light: 300 !important;
    --font-weight-extra-light: 200 !important;

    /* DEFAULT FONTS */
    --font-xxsmall: 12px;
    --font-xsmall: 14px;
    --font-small: 15px;
    --font: 16px;
    --font-medium: 18px;
    --font-large: 20px;
    --font-xlarge: 22px;
    --font-xxlarge: 24px;
    --font-xxxlarge: 26px;

    /* LARGER FONTS */
    --font-h5: 28px;
    --font-h4: 32px;
    --font-h3: 48px;
    --font-h2: 60px;
    --font-h1: 72px;

    /* DEFAULT LINE-HEIGHTS */
    --line-xsmall: 20px;
    --line-small: 22px;
    --line: 24px;
    --line-medium: 26px;
    --line-large: 28px;
    --line-xlarge: 30px;
    --line-xxlarge: 32px;
    --line-xxxlarge: 34px;

    /* LARGER LINE-HEIGHTS */
    --line-h5: 38px;
    --line-h4: 42px;
    --line-h3: 58px;
    --line-h2: 72px;
    --line-h1: 84px;

    /* OLD STUFF [DONT USE] */
    --font-size-h1: 3.5rem;
    --font-size-h2: 2rem;
    --font-size-h3: 1.15rem;
    --font-size-subtitle: 1.15rem;
    --font-size-body: 1rem;
    --font-size-caption: 0.85rem;

    /* OLD STUFF [DONT USE] */
    --ratio: 1.15;
    --p: 0.85rem;
    --input: var(--p);
    --weight-extra-light: 100;

    /* OLD STUFF [DONT USE] */
    --caption: calc(var(--p) / var(--ratio));
    --subcaption: calc(var(--caption) / var(--ratio));
}

@media only screen and (max-width: 768px) {
    :root {
        --ratio: 1.1;
        --input: 16px;
        --p: 14px;
        --caption: 12px;
        --subcaption: 12px;
        --font-size-input: 16px;
    }
}

@media (max-width: 768px) {
    :root {
        --input: 16px;
        --p: 14px;
        --caption: 14px;
        --subcaption: 12px;
        --font-size-input: 16px;
    }
}

.family {
    font-family: var(--font-family);
}

.h1 {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h1);
    line-height: 1;
    margin: var(--margin-medium) 0;
}

.p {
    font-size: var(--p);
}

.caption {
    font-size: var(--caption);
}

.label {
    font-weight: var(--font-weight-regular);
    font-style: normal;
    font-size: var(--font-size-caption);
    color: var(--color-gray);
    margin-bottom: var(--margin-internal);
}

.light {
    font-weight: var(--font-weight-light);
}

.regular {
    font-weight: var(--font-weight-regular);
}

.medium {
    font-weight: var(--font-weight-medium);
}

.semiBold {
    font-weight: var(--font-weight-semibold);
}

.bold {
    font-weight: var(--font-weight-bold);
}

.uppercase {
    text-transform: uppercase;
}
