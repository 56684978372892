.modal {
    position: absolute;
    top: 5%;
    left: 0px;
    right: 0px;
    bottom: 0px;
    transform: translate(0, 0);
    max-width: unset;
    width: unset;
    max-height: 95%;
    background-color: var(--color-white);
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 100;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
}

.overlay:global(.ReactModal__Overlay--after-open) {
    opacity: 1;
}

.overlay:global(.ReactModal__Overlay--after-close) {
    opacity: 0;
}

.bottomFit:global(.ReactModal__Content) {
    transform: unset !important;
    top: unset !important;
    width: 100vw;
    min-height: 30%;
    position: absolute;
    bottom: -5rem;
    left: 0;
    right: 0;
    transition: bottom 0.3s;
}

.bottomFit:global(.ReactModal__Content--after-open) {
    bottom: 0;
}

.childrenContainer {
    padding: var(--margin-medium);
    width: auto;
}

.headerAligner {
    flex: 1;
    justify-content: space-between;
}

.bodyOpen {
    overflow: hidden;
}

.htmlOpen {
    overflow: hidden;
}

.scrollLock {
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
}

.modal:focus {
    outline: none;
}

@media (min-width: 492px) {
    .childrenContainer {
        padding: var(--margin-large) var(--margin-large) var(--margin-xlarge);
    }
}

@media (min-width: 692px) {
    .modal {
        width: fit-content;
        max-height: 98%;
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: unset;
        overflow-y: auto;
        overflow-x: hidden;
        transform: translate(-50%, -50%) !important;
    }

    .bottomFit {
        top: unset !important;
    }

    .modalSmall {
        width: 25rem;
    }

    .modalMedium {
        width: 30rem;
    }

    .modalLarge {
        width: 35rem;
    }

    .modalFit {
        width: fit-content;
    }

    .modal50 {
        width: 50%;
    }
}

@media (min-width: 928px) {
    .modal {
        width: fit-content;
        max-height: 98%;
        position: absolute;
        top: 50%;
        left: 50%;
        overflow-y: auto;
        overflow-x: hidden;
        transform: translate(-50%, -50%) !important;
    }
}
