.container {
    height: 100vh;
    display: grid;
    grid-template-rows: min-content 100%;
}

.header {
    margin-top: 9px;
    margin-bottom: var(--margin-medium);
}

.header svg {
    height: 24px;
    width: 24px;
}

.headerContainer {
    margin-bottom: var(--margin-medium);
}

.arrowBack {
    align-self: center;
    margin-right: var(--margin-small);
}

.headerTitle {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    align-self: center;
}

.footer {
    box-shadow: 0px -2px 16px 0px #0b0b0d14;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100vw;
    padding-top: var(--margin-medium) !important;
    padding-bottom: var(--normal-extra_space) !important;
    background-color: var(--color-white);
}

.dropdownTitle {
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: var(--margin-small);
}

.dateRange > div {
    max-width: 444px;
    margin: auto;
}

.inputsContainer {
    margin-bottom: var(--margin-medium);
    max-width: 260px;
}

.disabled {
    color: var(--color-gray-light);
}

.timeDropdown {
    height: 0 !important;
    width: 100% !important;
    margin: 0;
}

.timeDropdown input {
    opacity: 0;
    width: 100% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.timeDropdownInput {
    justify-content: space-between;
    background: var(--color-white);
    padding: 0;
    margin: 0;
    padding-bottom: var(--margin-internal);
    border-bottom: 1px solid #e2e3e7;
    -webkit-appearance: none;
}

.timeDropdownInput span {
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
}

.timeDropdownInputDisabled {
    background: var(--color-white);
    padding: 0;
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
    padding-bottom: var(--margin-internal);
    border: unset;
    border-bottom: 1px solid #e2e3e7;
}

.timeDropdownInputDisabled span {
    color: var(--color-gray-light);
}

.timeDropdownInputDisabled svg path {
    color: var(--color-gray-light);
}

.headerClearBtn {
    margin-bottom: var(--margin-medium);
    height: 48px;
}

@media (min-width: 692px) {
    .header {
        justify-content: center;
    }

    .headerTitle {
        margin: auto;
    }
}
