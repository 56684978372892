.filterRow {
    flex-direction: column !important;
}

.filterRow .clearButton {
    display: none;
}

.clearButton {
    flex: 0 !important;
    white-space: nowrap;
    cursor: pointer;
}

.clearButton:hover {
    opacity: 0.7;
}

.modalButton {
    flex: 0 !important;
    white-space: nowrap;
}

.container {
    padding: 0;
}

.content {
    padding: 1rem 1.5rem;
}

.header {
    flex: 1;
    max-height: 2.5rem;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-gray-light);
}

.headerTitle {
    font-size: 1.275rem;
    font-weight: var(--font-weight-medium);
}

.filterTitle {
    position: relative;
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-medium);
    color: var(--color-black);
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.closeIcon {
    position: absolute;
    left: 1rem;
}

.closeBar {
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background: var(--color-grayish);
    position: absolute;
    top: 0.725rem;
    left: 50%;
    transform: translateX(-50%);
}

.label {
    font-size: var(--font) !important;
    line-height: var(--line) !important;
    color: var(--color-black);
    font-weight: var(--font-weight-light);
    text-align: center;
}
