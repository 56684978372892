.photo {
    border-radius: var(--radius);
    object-fit: cover;
}

.raised {
    box-shadow: 0 0 10px 0 var(--color-gray-light);
}

.noImage {
    background-color: var(--color-gray-lighter);
    background-image: url('../../../assets/images/Logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.5rem;
    opacity: 0.7;
}

.photo.mini {
    width: 5rem;
    height: 5rem;
    max-width: 5rem;
    max-height: 5rem;
    min-width: 5rem;
    min-height: 5rem;
}

.photo.small {
    width: 7rem;
    height: 7rem;
    max-width: 7rem;
    max-height: 7rem;
    min-width: 7rem;
    min-height: 7rem;
}

.photo.medium {
    width: 11rem;
    height: 11rem;
    max-width: 11rem;
    max-height: 11rem;
    min-width: 11rem;
    min-height: 11rem;
}

.photo.large {
    width: 14rem;
    height: 14rem;
    max-width: 14rem;
    max-height: 14rem;
    min-width: 14rem;
    min-height: 14rem;
}

.photo.xlarge {
    width: 20rem;
    height: 20em;
    max-width: 20rem;
    max-height: 20rem;
    min-width: 20rem;
    min-height: 20rem;
}

.photo.miniWide {
    width: 7rem;
    height: 5rem;
    max-width: 7rem;
    max-height: 5rem;
    min-width: 7rem;
    min-height: 5rem;
}

.photo.smallWide {
    width: 10rem;
    height: 7rem;
    max-width: 10rem;
    max-height: 7rem;
    min-width: 10rem;
    min-height: 7rem;
}

.photo.mediumWide {
    width: 17rem;
    height: 11rem;
    max-width: 17rem;
    max-height: 11rem;
    min-width: 17rem;
    min-height: 11rem;
}

.photo.largeWide {
    width: 22rem;
    height: 12.5rem;
    max-width: 22rem;
    max-height: 12.5rem;
    min-width: 22rem;
    min-height: 12.5rem;
}

.photo.xlargeWide {
    width: 50rem;
    height: 28.5rem;
    max-width: 50rem;
    max-height: 28.5rem;
    min-width: 50rem;
    min-height: 28.5rem;
}

.photo.relative {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .photo.xlargeWide,
    .photo.xlarge {
        width: 90vw;
        max-width: 90vw;
        min-width: 90vw;
        min-height: 12.5rem;
        max-height: 12.5rem;
        height: 12.5rem;
    }

    .photo.smallWide {
        width: 10rem;
        height: 7rem;
        max-width: 10rem;
        max-height: 7rem;
        min-width: 10rem;
        min-height: 7rem;
    }
}
