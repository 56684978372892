.content {
    width: 100%;
    z-index: 0;
    overflow: hidden;
}

@media screen and (max-width: 927px) {
    .banner {
        display: none;
    }
}

@media screen and (min-width: 928px) {
    .bannerWide {
        display: none;
    }
}
