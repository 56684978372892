.container {
    --assetDetailsHeader-height: 45rem;

    position: relative;
    height: var(--assetDetailsHeader-height);
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: nowrap;
    overflow: hidden;
}

.container :global(.dot) {
    outline: none;
}

.headerCarousel {
    height: var(--assetDetailsHeader-height);
}

.headerCarousel :global(.carousel-slider),
.headerCarousel :global(.slider),
.headerCarousel :global(.slider-wrapper),
.headerCarousel :global(.slider-wrapper .slider),
.headerCarousel :global(.slider-wrapper .slider .slide) {
    height: 100%;
    background-color: transparent;
}

.headerPhoto {
    height: var(--assetDetailsHeader-height);
}

.icon {
    flex: 0 !important;
}

.actionOverlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 16px 24px;
    background: linear-gradient(180deg, rgb(11 11 13 / 50%) 0%, rgb(11 11 13 / 0%) 100%);
}

.overlayIconContainer {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex: 1;
}

.overlayIconContainer :last-child {
    flex: 0 !important;
}

.overlayIconContainer :last-child > * {
    margin-left: 1.5rem;
}

.overlayIconContainer .arrowBack {
    height: 30px;
    width: 30px;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.35));
    stroke-width: 0.095rem;
    stroke: white;
    cursor: pointer;
}

.overlayIconContainer .share {
    height: 24px;
    width: 24px;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.35));
    stroke-width: 0.1rem;
    stroke: white;
    cursor: pointer;
}

.overlayIconContainer .like {
    height: 24px;
    width: 24px;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.35));
    stroke-width: 0.1rem;
    stroke: white;
    cursor: pointer;
}

.container:hover .arrow {
    opacity: 1;
}

.arrow {
    position: absolute;
    bottom: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
    z-index: 2;
    opacity: 0;
    transition: all 200ms ease-in;
}

.arrowWrapper {
    background-color: white;
    border-radius: 20rem;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0.5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
    box-shadow: 0 0 10px rgb(0 0 0 / 49%), 0 0 0 1px rgb(0 0 0 / 7%);
    transition: height ease-in-out 250ms;

    cursor: pointer;
}

.arrowWrapper:active {
    transform: scale(0.98);
    opacity: 0.7;
}

.arrow img {
    width: unset !important;
}

.arrowNext {
    right: 1.5rem;
}

.arrowNext img {
    transform: translateX(1px);
}

.arrowPrev {
    left: 1.5rem;
}

.arrowPrev img {
    transform: translateX(-1px);
}

@media only screen and (max-width: 1360px) {
    .container {
        --assetDetailsHeader-height: 35rem;
    }
}

@media only screen and (max-width: 928px) {
    .container {
        --assetDetailsHeader-height: 25rem;
    }
}

@media only screen and (max-width: 691px) {
    .container {
        --assetDetailsHeader-height: 20rem;
    }
}

@media only screen and (max-width: 480px) {
    .container {
        --assetDetailsHeader-height: 15rem;
    }
}

@media screen and (max-width: 491px) {
    .container {
        border-radius: 0px !important;
    }
}

@media screen and (min-width: 692px) {
    .container {
        max-width: var(--page-max-width);
        width: var(--page-width);
        margin: var(--margin) auto;
        border-radius: 5px;
    }
}
