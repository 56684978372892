.item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: var(--margin-medium);
}

.icon {
    margin: 0 var(--margin);
    display: flex;
    align-items: center;
}

.icon svg {
    height: 1.3rem;
    width: 1.3rem;
}

.icon svg path {
    stroke: var(--color-gray-dark);
    fill: var(--color-gray-dark);
}

.children {
    font-weight: var(--font-weight-regular);
    font-size: var(--font);
    line-height: var(--line);
    text-align: left;
}

.childrenMobile {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
}
