@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

@import url('./colors.css');

html,
body,
div#root {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: var(--font-xsmall);
    -webkit-appearance: none;
    -moz-appearance: none;
    --radius: 2px;
}

.overflowXHidden {
    overflow-x: hidden;
}

p {
    margin: 0;
}

.noPadding {
    padding: 0;
}

button {
    font-family: 'Poppins', 'Open Sans', sans-serif;
}
