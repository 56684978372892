.item {
    margin-bottom: var(--margin-medium);
    margin-top: var(--margin);
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-secondary);
    padding: var(--padding-small);
    align-items: center;
}

.header {
    flex: 0 !important;
    flex-direction: row;
    margin-top: var(--margin);
}

.title {
    font-size: var(--font-xlarge);
    font-weight: var(--font-weight-semibold);
    line-height: var(--font-large);
    font-family: 'Poppins';
}

.photo {
    height: 190px;
    max-height: 190px;
    min-height: 190px;
    width: 335px;
    background: rgba(255, 255, 255, 0.8);
    cursor: pointer;
}

.price {
    font-size: var(--font-large);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-large);
    margin: 0 var(--margin-internal) 0 0 !important;
}

.priceInfo {
    font-size: var(--font-large);
    font-weight: var(--font-weight-light);
    line-height: var(--line-large) !important;
}

.categoryContainer {
    align-items: flex-end;
    margin-right: 0 !important;
}

.categoryContainer :last-child {
    margin-left: 3px;
    color: red;
    text-decoration: underline;
}

.priceContainer {
    align-items: flex-end;
    margin-top: var(--margin);
}

.totalContainer > span {
    text-decoration: underline;
    color: var(--color-grayish);
}

.iconsWrapper {
    align-items: center;
    display: flex;
}

.iconsWrapper > svg {
    margin-right: 7px;
    fill: var(--color-grayish);
}

.iconsWrapper > svg path {
    fill: var(--color-grayish);
}

.iconsWrapper > .seatsIcon path {
    fill: white;
    stroke: var(--color-grayish);
}

.iconsWrapper > span {
    color: var(--color-grayish);
}

.ratingContainer {
    flex-direction: row;
    align-items: center;
}

.ratingContainer > * {
    margin-right: 3px;
    margin-bottom: 0 !important;
}

.rating {
    color: var(--color-gray-darker);
    font-weight: var(--font-weight-medium);
    margin-right: 5px;
}

.ratingIcon {
    width: 12px;
}

.bottomContainer {
    display: flex;
}

.detailsIcon {
    width: 21px;
    max-width: 21px;
    min-width: 21px;
}

.iconsWrapper > *,
.categoryContainer > *,
.totalContainer > * {
    margin-bottom: 0 !important;
    font-size: var(--font-xsmall);
    width: max-content;
}

.iconsContainer,
.iconsWrapper,
.bottomContainer > * {
    flex: 0 !important;
}

.suitcasesInfo {
    display: none !important;
}

.listItem {
    margin-bottom: 0;
}

.listItem .header {
    display: block;
    width: 335px;
}

.listItem .category {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    color: var(--color-red);
    font-weight: var(--font-weight-light);
    margin-bottom: var(--margin-internal);
}

.listItem .bookLink {
    width: 100%;
    background-color: var(--color-red);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 48px;
    color: var(--color-white);
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    font-family: 'Poppins';
    margin-top: var(--margin-medium);
}

.listItem .bookLinkLeft {
    width: 100%;
    background-color: var(--color-red);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 48px;
    color: var(--color-white);
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    font-family: 'Poppins';
    margin-top: 1.5rem;
    display: none;
}

.listItem .priceContainer {
    align-items: flex-start;
}

.listItem .bookButtonContainer {
    justify-content: center;
    margin-bottom: var(--margin-xlarge);
}

.listItem .priceDay {
    margin-bottom: 4px;
    align-items: flex-start;
}

.listItem .priceInfo {
    font-size: var(--font);
    line-height: var(--line-medium);
    margin-bottom: 0;
}

.listItem .iconsContainer {
    margin: 16px 0px;
}

.listItem .seeDetails {
    cursor: pointer;
    color: var(--color-red);
    font-size: var(--font-small);
    line-height: var(--line-small);
    text-decoration: underline;
    text-decoration-thickness: 0.5px;
    text-underline-offset: 1px;
    font-weight: var(--font-weight-medium);
}

.listItem .seeDetailsContainer {
    display: block;
    margin-bottom: var(--margin);
}

.splitter {
    margin-top: 32px !important;
}

.assetDescription {
    color: var(--color-grayish);
    font-size: var(--font-xsmall);
    line-height: 20px;
    font-weight: var(--font-weight-light);
}

.seeDetails {
    text-decoration: underline;
    color: var(--color-red);
}

@media only screen and (min-width: 492px) {
    .item {
        margin-bottom: var(--margin-medium);
        position: relative;
        display: flex;
        flex-direction: column;
        font-family: var(--font-family-secondary);
        max-height: fit-content;
        flex-wrap: wrap;
        width: 100%;
    }
    .listItem .bookButtonContainer {
        justify-content: flex-end;
    }

    .listItem .header {
        margin-top: 0;
        width: 444px;
    }

    .listItem .bookLink {
        width: 214px;
        margin-top: 0;
    }

    .listItem .bookLinkLeft {
        width: 214px;
        margin-top: 0;
        display: none;
    }
    .photo {
        height: 190px;
        max-height: 190px;
        min-height: 190px;
        width: 444px;
        max-width: 444px;
        background: rgba(255, 255, 255, 0.8);
        margin-bottom: var(--margin-small);
    }

    .listItem .seeDetailsContainer {
        display: block;
        margin-bottom: var(--margin-small) !important;
    }

    .bottomContainer {
        flex-direction: column !important;
        width: 100% !important;
    }

    .priceContainer {
        align-items: unset;
    }

    .bookButtonContainer {
        margin-right: auto;
        margin-top: var(--margin-medium);
    }
}

@media only screen and (min-width: 692px) {
    .item {
        margin-bottom: var(--margin-medium);
        position: relative;
        display: flex;
        flex-direction: row;
        font-family: var(--font-family-secondary);
        max-height: fit-content;
        align-items: flex-start;
        flex-wrap: nowrap;
        width: 100%;
    }
    .listItem .bookButtonContainer {
        justify-content: flex-end;
    }

    .listItem .header {
        flex: 1 !important;
        max-width: 380px;
        margin-left: var(--margin-medium);
    }

    .listItem .bookLink {
        width: 210px;
        margin-top: 0;
        display: none;
    }

    .left {
        display: flex;
    }

    .listItem .bookLinkLeft {
        width: 240px;
        display: flex;
        left: 0;
    }
    .bookLeftButtonContainer {
        margin-top: var(--margin-medium);
        display: flex;
        top: 0;
    }

    .photo {
        height: 180px;
        max-height: 180px;
        min-height: 180px;
        width: 240px;
        margin-bottom: 0px;
    }

    .listItem .seeDetailsContainer {
        display: block;
        margin-bottom: var(--margin-small) !important;
    }

    .bottomContainer {
        flex-direction: row !important;
        width: 100% !important;
        padding: 0;
        margin-left: var(--margin-medium);
    }

    .priceContainer {
        align-items: unset;
        margin-left: 0px;
    }

    .bookButtonContainer {
        margin-right: auto;
    }
}

@media (min-width: 928px) {
    .listItem .header {
        flex: 1 !important;
        max-width: 332px;
        margin-left: var(--margin);
    }

    .listItem .titleContainer {
        margin-bottom: var(--margin-small);
        margin-right: 0 !important;
    }

    .listItem .title {
        font-size: var(--font-xxlarge);
        font-weight: var(--font-weight-semibold);
        line-height: var(--line-xxlarge);
    }

    .listItem .itemSpecsCol {
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
    }

    .listItem .bookLink {
        display: flex;
    }
    .listItem .bookLinkLeft {
        display: none;
    }
    .photo {
        height: 158.67px;
        max-height: 158.67px;
        min-height: 158.67px;
        max-width: 280px;
        width: 280px;
        background: rgba(255, 255, 255, 0.8);
    }

    .listItem .seeDetailsContainer {
        display: block;
        margin-bottom: var(--margin-small) !important;
    }

    .assetDescription {
        color: var(--color-grayish);
        font-size: var(--font-xsmall);
        line-height: var(--line-xsmall);
        font-weight: var(--font-weight-light);
        margin: 0;
        width: 85%;
    }

    .bottomContainer {
        flex-direction: column !important;
        justify-content: space-between;
        align-items: flex-end;
        max-width: 210px;
        margin-left: 0;
    }

    .listItem .priceContainer {
        align-items: flex-end;
    }

    .listItem .price {
        font-size: var(--font-large);
        line-height: var(--line-medium);
        margin-bottom: 0;
    }

    .listItem .priceInfo {
        font-size: var(--font-medium);
    }

    .bookButtonContainer {
        justify-content: center;
    }
}

@media (min-width: 1360px) {
    .listItem .header {
        flex: 1 !important;
        max-width: 420px;
        margin-left: var(--margin);
    }

    .item {
        margin-bottom: var(--margin-medium);
        position: relative;
        display: flex;
        flex-direction: row;
        font-family: var(--font-family-secondary);
        max-height: fit-content;
        flex-wrap: nowrap;
        width: 100%;
    }

    .listItem .titleContainer {
        margin-bottom: var(--margin);
    }

    .suitcasesInfo {
        display: flex !important;
    }

    .listItem .seeDetailsContainer {
        margin-bottom: var(--margin-small) !important;
    }
    .assetDescription {
        color: var(--color-grayish);
        font-size: var(--font-xsmall);
        line-height: var(--line-xsmall);
        font-weight: var(--font-weight-light);
        margin: 0;
        width: 100%;
    }

    .photo {
        height: 204px;
        max-height: 204px;
        min-height: 204px;
        width: 360px;
        max-width: 360px;
    }
    .listItem .bookLink {
        width: 211px;
        display: flex;
        right: 0;
        bottom: var(--margin-small);
        margin-left: 7px;
    }

    .listItem .title {
        font-size: var(--font-h5);
        line-height: 36px;
    }

    .listItem .price {
        font-size: var(--font-xxlarge);
    }

    .bottomContainer {
        flex-direction: column !important;
        width: 100% !important;
    }

    .priceContainer {
        align-items: unset;
        right: 0;
    }
}

@media (min-width: 1680px) {
    .listItem .header {
        flex: 1 !important;
        max-width: 520px;
        margin-left: var(--margin);
    }

    .item {
        margin-bottom: var(--margin-medium);
        position: relative;
        display: flex;
        flex-direction: row;
        font-family: var(--font-family-secondary);
        max-height: fit-content;
        flex-wrap: nowrap;
        width: 100%;
    }

    .listItem .titleContainer {
        margin-bottom: var(--margin);
        width: 278px;
    }

    .suitcasesInfo {
        display: flex !important;
    }

    .listItem .seeDetailsContainer {
        margin-bottom: var(--margin-small) !important;
    }
    .assetDescription {
        color: var(--color-grayish);
        font-size: var(--font-xsmall);
        line-height: var(--line-xsmall);
        font-weight: var(--font-weight-light);
        margin: 0;
        width: 100%;
    }

    .photo {
        height: 204px;
        max-height: 204px;
        min-height: 204px;
        width: 360px;
        max-width: 360px;
    }
    .listItem .bookLink {
        width: 211px;
        display: flex;
        right: 0;
        bottom: var(--margin-small);
        margin-left: 7px;
    }

    .listItem .title {
        font-size: var(--font-h5);
        line-height: 36px;
    }

    .listItem .price {
        font-size: var(--font-xxlarge);
    }

    .bottomContainer {
        flex-direction: column !important;
        width: 100% !important;
    }

    .priceContainer {
        align-items: unset;
        right: 0;
    }
}
