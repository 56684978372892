.header {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--margin-small) !important;
}

.modal {
    border-radius: 10px;
}

.subtitle {
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-medium);
    margin: 0 !important;
}

.detailsContainer {
    margin-top: var(--margin-xlarge);
    margin-bottom: var(--margin-large);
    flex-wrap: wrap;
    flex-direction: column !important;
}

.splitter {
    display: flex;
}

.largeSplitter {
    display: none;
}

.iconTransmission path {
    fill: black;
}

.moreInfoText {
    margin: 0;
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}

.regular {
    font-weight: var(--font-weight-light);
}

.modalContainer {
    padding: 0;
}

.modalHeader {
    margin: var(--margin-medium);
    justify-content: flex-start;
}

.modalHeader span {
    margin-left: auto !important;
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-medium);
}

.modalHeader img {
    margin-left: auto !important;
}

.contentContainer {
    margin-bottom: 64px !important;
}

.moreInfoSubtitle {
    margin-bottom: var(--margin-medium) !important;
}

.marginBottom {
    margin-bottom: var(--margin-internal);
}

@media screen and (min-width: 492px) {
    .modal {
        border-radius: 10px;
        width: 100%;
    }
}

@media screen and (min-width: 692px) {
    .detailsContainer {
        margin-top: var(--margin-xlarge);
        margin-bottom: var(--margin-large);
        flex-wrap: wrap;
        flex-direction: row !important;
    }

    .splitter {
        display: none;
    }

    .largeSplitter {
        display: flex;
    }
}
