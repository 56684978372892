.container {
    display: flex !important;
    flex-direction: row !important;
}

.image {
    width: 104px !important;
    height: 61px !important;
    margin-right: 20px;
}

.title {
    margin-bottom: 2px;
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-medium);
    display: flex;
    justify-content: flex-start;
}

.subtitle {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    color: var(--color-red);
    display: flex;
    justify-content: flex-start;
}
