:root {
    --color-alice: #e04335;
    --color-primary: #280f55;
    --color-purple: #280f55;
    --color-purple-t-dark: #280f55cc;

    --color-red: #e14436;
    --color-dark-red: #ac362c;
    --color-white: #ffffff;
    --color-gray: #70798c;
    --color-gray-shadow: #73737380;
    --color-pearl: #f5f1ed;
    --color-beige: #c7bfab;
    --color-gray-darker: #0b0b0d;
    --color-gray-dark: #3e424d;

    --color-gray-transparent: #70798c66;

    --color-grayish: #9da0aa;
    --color-gray-light: #b1b5c0;
    --color-gray-light-border: #e2e3e7;

    --color-gray-lighter: #f2f2f4;
    --color-gray-faded: #f9f9f9;
    --color-black: #0b0b0d;

    --color-gray-light-datepicker: #e4e7e7;
    --color-gray-datepicker: #cacccd;
}
