.teamCard {
    width: 100%;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: unset !important;
    max-width: 230px;
}

.imgPlaceholder {
    width: 100%;
    border-radius: 50%;
    max-width: 206px;
    margin-bottom: 8px;
}

.nameText {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-semibold);
    margin-bottom: 0;
}

.label {
    margin-bottom: 0;
    text-align: center;
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-light);
}

@media screen and (min-width: 492px) {
    .imgPlaceholder {
        margin-bottom: 12px;
    }
}
