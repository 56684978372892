.dropdown {
    font-size: var(--font-size-body);
    margin-bottom: var(--margin);
}

.dropdown :global(.dropdown__control) {
    list-style: none;
    margin: 0;
    border: none;
    box-shadow: none;
    background-color: var(--color-gray-lighter);
    font-size: inherit;
    border-radius: var(--radius);
    border: thin solid var(--color-gray-lighter);
}

.dropdown :global(.dropdown__value-container),
.dropdown :global(.dropdown__placeholder) {
    font-size: inherit !important;
}

.dropdown :global(.dropdown__control):hover {
    cursor: pointer;
    border: thin solid var(--color-gray-lighter);
    border-bottom: thin solid var(--color-gray-light);
}

.dropdown :global(.dropdown__indicator-separator) {
    display: none;
}

.dropdown :global(.dropdown__indicator) {
    color: var(--color-black);
    padding: 9px;
}

.dropdown :global(.dropdown__indicators) {
    justify-content: flex-end;
    flex-grow: 0;
}

.dropdown :global(.dropdown__menu) {
    border-radius: var(--radius);
    margin-top: var(--margin-internal);
    z-index: 4 !important;
}

.dropdown :global(.dropdown__option) {
    padding: var(--padding);
    color: var(--color-gray-dark);
    font-size: var(--font-size-body);
    background-color: var(--color-gray-lighter);
    border: thin solid transparent;
    transition: background-color 0.2s, color 0.2s, font-weight 0.2s;
    font-weight: var(--font-weight-regular);
    flex-grow: 1;
}

.dropdown :global(.dropdown__option):active {
    color: var(--color-pearl);
    background-color: var(--color-gray-dark);
    font-weight: var(--font-weight-semibold);
}

.dropdown :global(.dropdown__option):hover {
    cursor: pointer;
    background-color: var(--color-gray);
    color: var(--color-gray-lighter);
}

.dropdown :global(.dropdown__option--is-focused) {
    color: var(--color-pearl);
    background-color: var(--color-gray);
    font-weight: var(--font-weight-semibold);
}

.dropdown :global(.dropdown__option--is-selected) {
    color: var(--color-pearl);
    background-color: var(--color-gray-dark);
    font-weight: var(--font-weight-semibold);
}

.dropdown.round :global(.dropdown__control) {
    border-radius: 25px !important;
    padding-left: 1rem;
    background-color: white !important;
    border: thin solid transparent;
}

.dropdown.round :global(.dropdown__control):hover {
    cursor: pointer;
    border: thin solid transparent;
    border-bottom: thin solid transparent;
}
