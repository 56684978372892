.container {
    flex: 0 !important;
    margin: var(--margin) 0;

    -webkit-flex: unset !important;
    flex-shrink: 0 !important;
}

.option {
    flex: 0 !important;
    line-height: 1.6;
    display: flex;
    align-items: center;
    padding: var(--padding-internal) 0 var(--padding-internal) 0;
    margin-bottom: var(--margin-medium);
}

.option > input,
.option > span {
    margin: 0;
}

.option > span {
    font-size: var(--font);
    font-weight: var(--font-weight-light);
}

.option:hover {
    cursor: pointer;
}

.option input {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin-right: 1rem;
    font: inherit;
    color: currentColor;
    width: 24px !important;
    height: 24px;
    border: 1px solid var(--color-grayish);
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

.option input::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--color-red);
}

.option input:checked {
    border-color: var(--color-red);
}

.option input:checked::before {
    transform: scale(1);
}

.span:checked {
    font-weight: var(--font-weight-medium);
}

.option.disabled {
    opacity: 0.6;
}

.option.disabled:hover {
    cursor: unset;
}
