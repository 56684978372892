.cardHeader {
    margin-bottom: var(--margin-small);
}

.profileImg {
    justify-content: center;
    border-radius: 50%;
    margin-right: var(--margin-medium);
    width: 4rem;
    max-height: 4rem;
}
.userRating {
    justify-content: space-around;
}

.card {
    flex: 0 24%;
    margin-bottom: 0;
    min-width: 200px;
}

.description {
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-light);
}
