.container {
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #e2e3e7;
    border-radius: 56px;
}

.noBorder {
    border: 0;
    padding: 0;
    padding: 0 var(--margin);
}
