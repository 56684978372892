.pageContent {
    flex: 1;
}

.leftList {
    flex: 1;
    margin-right: 0 !important;
}

.mapContainer {
    flex: 1;
}

.leftListWrapper {
    margin-right: 0 !important;
}

.leftArrow {
    margin-top: 10px;
    margin-bottom: 15px;
    display: none;
}

.barInfo {
    text-align: center;
    color: white;
    margin: var(--margin-medium);
}

.barInfo span {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 20rem;
    padding: var(--margin-internal);
    padding-right: 2rem;
    padding-left: 2rem;
}

.leftListHeader {
    justify-content: space-between;
    align-items: center;
    flex: 0 !important;
    -webkit-flex: unset !important;
    flex-shrink: 0 !important;
}

.headerTitle {
    font-size: var(--font-xxlarge);
    line-height: var(--line-xxlarge);
    font-weight: var(--font-weight-medium);
    margin: var(--margin-internal) 0 var(--margin);
}

.mapSearch {
    position: absolute;
    top: var(--margin);
    right: var(--margin);
    z-index: 10;
    cursor: grab;
}

.mapUserLocationActions {
    position: absolute;
    bottom: var(--padding-medium);
    right: var(--padding-large);
    z-index: 10;
    cursor: grab;
}

.resultsText {
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
}

.text {
    color: var(--color-white);
}

.pagination {
    justify-content: center;
    padding: 0 !important;

    margin-top: var(--margin-medium);
    margin-bottom: var(--margin-large) !important;
}

@media screen and (min-width: 692px) {
    .headerTitle {
        margin: var(--margin-internal) 0 var(--margin-medium);
    }
}

@media screen and (min-width: 928px) {
    .headerTitle {
        font-size: var(--font-h4);
        line-height: var(--line-h4);
        font-weight: var(--font-weight-semibold);
    }
    .pageContent {
        justify-content: center;
    }

    .leftListWrapper {
        max-width: 880px;
        padding: 0;
    }

    .leftArrow {
        margin-top: 10px;
        margin-bottom: 15px;
        display: flex;
    }
}

@media screen and (min-width: 1360px) {
    .leftListWrapper {
        max-width: 1360px;
    }
}
