.container {
    margin-bottom: var(--margin-medium);
    flex-direction: column !important;
    display: flex;
}

.characteristicsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--margin-large);
    flex: unset !important;
}

.title {
    font-weight: var(--font-weight-medium) !important;
    font-size: var(--font-large) !important;
    line-height: var(--line-large);
    margin: 0 0 var(--margin-large) !important;
}

.iconLabel > * {
    font-size: var(--font-xsmall);
    color: var(--color-black);
    line-height: var(--line-xsmall);
    margin-bottom: unset;
}

.iconTransmission svg path {
    fill: black;
}

.seeMore {
    color: var(--color-alice);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    text-decoration: underline;
    cursor: pointer;
    /* Prevent selection */
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* supported by Chrome, Edge, Opera and Firefox */
    white-space: nowrap;
}

.grid {
    align-items: flex-start;
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-bottom: var(--margin-xlarge);
}

@media only screen and (min-width: 692px) {
    .characteristicsGrid {
        grid-gap: var(--margin-medium);
    }
}

@media only screen and (min-width: 928px) {
    .container {
        flex-direction: row;
        display: flex;
        justify-content: space-between !important;
    }

    .grid {
        align-items: center;
        display: flex;
        flex-direction: column !important;
        align-items: flex-start !important;
        margin-bottom: var(--margin-xlarge);
    }

    .title {
        font-weight: var(--font-weight-medium) !important;
        font-size: var(--font-xlarge) !important;
    }
}

@media only screen and (min-width: 1360px) {
    .characteristicsGrid {
        grid-gap: var(--margin-xlarge);
    }
}
