.container :global(.DayPicker_weekHeader) {
    padding: 0 !important;
    border-bottom: 1px solid #dbdbdb;
}

.container :global(.DayPicker_weekHeader_li) small {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-regular);
    color: var(--color-black);
}

.container :global(.CalendarMonthGrid) {
    width: 100% !important;
    max-width: 444px;
}

.container :global(.CalendarMonth_table) {
    width: 100%;
    max-width: 444px;
}

.container :global(.DayPicker_weekHeader_ul) {
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    max-width: 444px;
    height: 40px;
    margin: auto;
}

.container :global(.DayPicker_transitionContainer) {
    padding-top: var(--margin-large);
    padding-bottom: 400px;
}

.container :global(.CalendarMonth_caption) {
    text-align: start;
    padding-top: var(--margin-large);
    padding-bottom: var(--margin);
}

.container :global(.CalendarMonth_caption) strong {
    font-size: var(--font);
    font-weight: var(--font-weight-medium);
    line-height: var(--line);
}

.container :global(.CalendarDay__default) {
    border: none;
    font-size: var(--font);
    font-weight: var(--font-weight-light);
    line-height: var(--line);
    height: 40px !important;
}

.container :global(.CalendarDay__selected) {
    background: none !important;
    padding: 0;
}

.container :global(.CalendarDay__selected_start) .outerBackground {
    background: var(--color-gray-lighter);
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
}

.container :global(.CalendarDay__selected_end) .outerBackground {
    background: var(--color-gray-lighter);
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
}

.container :global(.CalendarDay__selected) .outerBackground .round {
    background: var(--color-black);
    font-weight: var(--font-weight-medium);
    border-radius: 100%;
    height: 40px !important;
    width: 40px !important;
    display: inline-block;
    padding: var(--margin-internal);
}

.container :global(.CalendarDay__selected_span) {
    background: var(--color-gray-lighter);
    color: var(--color-black);
    padding: var(--margin-internal);
}

/* Hotfix, should appear at the bottom of months */
.container :global(.DayPickerNavigation) {
    display: none;
}

.container :global(.CalendarDay__blocked_out_of_range) {
    text-decoration: line-through;
    background-color: transparent;
    color: var(--color-gray-datepicker);
}

.container :global(.CalendarDay__blocked_calendar) {
    text-decoration: line-through;
    background-color: transparent;
    position: relative;
    color: var(--color-gray-datepicker);
}
