.itemContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-gray-light-border);
    border-radius: 3px;
    padding: 16px;
    cursor: pointer;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    position: relative;
}

.itemContainer:hover {
    opacity: 0.6;
}

.itemContainer:active {
    transform: scale(0.98);
}

.title {
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: 2px;
}

.subtitle {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    white-space: normal;
}

.subtitle > span {
    font-weight: var(--font-weight-light);
}

.selectionIndicator {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background: var(--color-alice);
    opacity: 0;
    transition: opacity 150ms ease-in-out;
    border-radius: 50rem;
}

.selected {
    border: 1px solid var(--color-gray-darker);
}

.selected .selectionIndicator {
    opacity: 1;
}
