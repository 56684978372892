.modal {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    flex-direction: column;
    max-height: 98%;
    top: 2%;
}

@media screen and (min-width: 492px) {
    .modal {
        min-height: 50%;
        top: auto;
        bottom: 0;
    }
}

@media screen and (min-width: 692px) {
    .modal {
        min-height: unset;
        top: 50%;
        bottom: auto;
        border-radius: 16px;
    }
}
