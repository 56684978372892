.container {
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
    border-radius: 20px;
    max-width: 49%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28rem;
}

.title {
    text-align: start;
    line-height: var(--line-h4);
    font-family: var(--font-family-secondary);
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 24px;
}

.description {
    font-weight: var(--font-weight-light);
    line-height: var(--line);
    font-size: var(--font);
    font-family: var(--font-family-secondary);
    margin-bottom: var(--margin-large);
}

@media screen and (min-width: 928px) {
    .container {
        margin-top: 0;
    }

    .title {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 16px;
    }
}

@media screen and (min-width: 1360px) {
    .title {
        font-size: 56px;
        line-height: 66px;
    }

    .description {
        max-width: 460px;
    }

    .container {
        height: 32rem;
    }
}

@media screen and (min-width: 1680px) {
    .title {
        font-size: var(--font-h1);
        line-height: 82px;
    }

    .description {
        font-size: var(--font-medium);
        line-height: var(--line-large);
    }

    .container {
        height: 42rem;
    }

    .howItWorksButton {
        font-size: var(--font-medium) !important;
        line-height: var(--line-medium);
    }
}

@media screen and (max-width: 927px) {
    .container {
        max-width: unset;
        height: unset;
        margin-top: 3rem;
    }
}
