.page {
    margin-bottom: 90px !important;
}

.pageTitle {
    font-size: var(--font-h3) !important;
    line-height: var(--line-h3) !important;
}

.cardsRow {
    margin-top: var(--margin-medium);
    flex-direction: column !important;
}

.walletContainer {
    margin-bottom: var(--margin-medium);
    flex-direction: column !important;
}

.cardsRow > div:not(:first-child) {
    margin-left: 0px !important;
}

.cardsRow strong {
    color: black;
}

.card {
    border-radius: 8px;
    box-shadow: none;
}

.card svg {
    stroke-width: 1px;
}

.minHeight {
    height: fit-content;
}

.heightFitContent {
    height: fit-content;
}

.logoutBtn {
    width: fit-content;
    margin-top: var(--margin-large);
    color: var(--color-black);
}

@media only screen and (min-width: 492px) {
}

@media only screen and (min-width: 692px) {
}

@media only screen and (min-width: 928px) {
    .card {
        box-shadow: 0 2px 16px rgba(11, 11, 13, 0.08);
        padding: 32px;
    }

    .cardsRow {
        flex-direction: row !important;
        margin-top: var(--margin);
        flex: 1 1 !important;
    }
    .walletContainer {
        margin-bottom: var(--margin-medium);
        flex-direction: column !important;
        margin-right: var(--margin-medium);
    }

    .walletContainer {
        margin-top: var(--margin-xlarge);
    }
}

@media only screen and (min-width: 1360px) {
    .cardsRow > div:not(:first-child) {
        margin-left: var(--margin-medium) !important;
    }
    .cardsRow {
        margin-bottom: 0px;
        margin-top: var(--margin-xlarge);
        flex: 1 1 !important;
    }
}

@media only screen and (min-width: 1680px) {
}
