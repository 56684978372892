.itemContainer {
    max-width: 332px;
}

.image {
    margin-bottom: var(--margin-large);
}

.title {
    text-align: left;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-large);
    line-height: var(--line-large);
    margin-bottom: 12px;
}

.description {
    text-align: left;
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: 24px;
}

.button {
    width: fit-content;
}

@media (min-width: 928px) {
    .itemContainer {
        max-width: 282px;
    }
}

@media (min-width: 1360px) {
    .itemContainer {
        max-width: 332px;
    }
}
