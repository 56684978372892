.breadcrumb > div {
    flex-direction: row;
    align-items: center;
    margin-right: 0 !important;
    flex: unset !important;
}

.breadcrumb > div > a,
.breadcrumb > div > span {
    margin-bottom: 0;
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    font-family: 'Poppins';
    color: var(--color-grayish);
    text-decoration: underline;
    text-underline-offset: 1px;
}

.breadcrumb > div:not(:last-child) {
    margin-right: 0;
}

.breadcrumb > div > .slash {
    margin: 0 4px;
    text-decoration: none;
}
