.contentWrapper {
    min-height: 100vh;
    justify-content: space-between;
}

.contentWrapper > div:not(:last-child) {
    flex: 1;
}

.contentWrapper > div:first-child {
    flex: unset;
}
