.container :global(.SingleDatePickerInput),
.container :global(.SingleDatePicker),
.container :global(.SingleDatePicker > div:first-child),
.container :global(.DateInput input),
.container :global(.DateInput) {
    height: 100%;
    width: 100%;
}

.container :global(#DateTimePicker_DateStart) :global(.DateInpu),
.container :global(#DateTimePicker_DateEnd) :global(.DateInpu) {
    cursor: default;
}

.container {
    z-index: 1;
    border-radius: 20px;
    background-color: #ffffff;
    padding: 20px;
    position: relative;
    box-shadow: #00000012 0 25px 84px;
}

.container > .switcherRow {
    margin-top: 12px;
    display: none;
}

.switcherRow > div {
    display: flex;
    align-items: center;
}

.switcherRow > div span {
    margin-bottom: 0;
}

.container .row > div:not(:first-child) {
    flex: 0.5;
}

.wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.division {
    padding: 0rem;
    margin-right: var(--margin) !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: stretch;
    transition: border ease-in-out 1s;
    flex: 0.2;
}

.lastDivision {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: unset !important;
    margin-top: 28px;
}

.lastDivisionIcon {
    padding: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    background: black;
    border-radius: 15px;
    cursor: pointer;
    min-width: 2rem;
    min-height: 2rem;
    margin-right: 0.35rem;
    width: 7rem;
    background-color: #e14436;
    color: #fff;
    height: 31px;
    font-weight: var(--font-weight-medium);
}

.lastDivisionIcon:active {
    transform: scale(0.96);
}

.division > .serachInputReturn {
    margin-top: 20px;
    display: none;
}

.searchInput > div {
    width: 100%;
    margin: 0 !important;
    max-width: 85%;
}

.searchInput input[type='text'],
.searchInput input[type='password'] {
    outline: 0;
    border-width: 0;
    border-radius: 0;
    margin-bottom: 0;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.searchInput input::placeholder {
    color: var(--color-gray-light);
}

.searchInput > ul {
    width: 100%;
    top: 4.4rem;
    left: 0;
    transition: height ease-in-out 250ms;
}

.inputIcon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    color: var(--color-gray-light);
    stroke-width: 1.5;
}

.centeringHelper {
    display: none;
}

.dropdown {
    position: absolute;
    right: 1.2rem;
    z-index: -1;
    left: 1.2rem;
    background: var(--color-white);
    border-radius: var(--radius);
    height: 0;
    opacity: 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    transition: height ease-in-out 250ms;
    padding: var(--margin);
    overflow: scroll;
}

.focus {
    top: 4rem;
    height: 200px;
    opacity: 1;
    z-index: 5;
}

.whereText {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    margin: 0 0 8px;
}

.datesText {
    flex: 0 !important;
    margin-right: var(--margin-internal) !important;
    font-family: var(--font-family-secondary);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    margin: 0 0 8px;
}

.dateInput {
    cursor: pointer;
}

:global(.CalendarDay__selected) {
    background-color: var(--color-gray-darker);
    border: none !important;
    outline: none;
}

.SingleDatePicker {
    height: 10px !important;
}

.returnOptions {
    margin-left: 16px;
    display: flex;
    align-items: center;
}

.returnOptions > .radioContainer {
    margin-top: 0;
    flex-direction: row;
}

.returnOptions span {
    font-weight: var(--font-weight-light);
}

.radioContainer > div {
    flex: unset !important;
    margin: 0;
    margin-left: 24px;
}

.radioContainer > div > input {
    border-radius: 4px;
    cursor: pointer;
}

.radioContainer > div > input::before {
    color: var(--color-white);
    z-index: 100;
    box-shadow: none;
    height: 7px;
    width: 12px;
    border-left: var(--color-white) solid 2px;
    border-bottom: var(--color-white) solid 2px;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg) !important;
    margin-bottom: 4px;
}

.radioContainer > div > input:checked {
    border-radius: 4px;
    background-color: var(--color-red);
}

.radioContainer > div > span {
    font-size: var(--font-xsmall);
}

@media (max-width: 320px) {
    .division:nth-child(1) {
        display: flex !important;
    }

    .locationText {
        display: none;
    }
}

@media (min-width: 321px) and (max-width: 491px) {
    .division:nth-child(1) {
        display: flex !important;
    }

    .locationText {
        display: none;
    }
}

@media (min-width: 492px) and (max-width: 691px) {
    .division:nth-child(1) {
        display: flex !important;
        margin-right: 0 !important;
        border-right: 0 !important;
    }

    .lastDivision {
        margin-left: 1rem;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .division:first-child:hover {
        border-right: initial !important;
        background: initial !important;
        border-radius: initial !important;
    }
}

@media (max-width: 1359px) {
    .division {
        flex: 0.25;
    }
}

@media (max-width: 927px) {
    .whereText {
        font-size: 0.9rem;
    }

    .division {
        flex: 1 !important;
    }

    .division:not(:first-child) {
        display: none !important;
    }
}

@media (max-width: 927px) {
    .container {
        padding: 0.25rem;
        justify-content: center;
        border-radius: 2.5rem;
        border: var(--color-gray-light) solid 1px;
    }

    .lastDivision {
        display: none;
    }

    .whereText {
        display: none;
    }

    .searchInput {
        margin: 0 !important;
        border: none;
        justify-content: center;
        position: relative;
    }

    .inputIcon {
        position: absolute;
        top: 7px;
        left: calc(50% - 100px);
        z-index: 10;
    }

    .searchInput > div {
        flex: 1 !important;
    }

    .searchInput input::placeholder {
        font-size: var(--font-small) !important;
        color: var(--color-black) !important;
        font-weight: var(--font-weight-light) !important;
        text-align: center;
    }

    .division {
        margin: 0;
        margin-right: 0 !important;
    }

    .inputIcon {
        margin: 0;
    }

    .centeringHelper {
        display: block;
        width: 10px;
        background-color: transparent;
        height: 10px;
        width: 24.2px;
        flex: none !important;
        margin: 0 !important;
    }
}

@media (min-width: 928px) {
    .container > .switcherRow {
        display: block;
    }

    .division > .serachInputReturn {
        display: flex;
    }

    .error {
        margin-top: 4px;
        color: var(--color-red);
    }
}

@media (min-width: 1360px) {
    .division > div:nth-child(2),
    .division > div:nth-child(3) {
        box-sizing: border-box;
        height: 56px;
        align-items: center;
    }

    .division > div:nth-child(2) input {
        font-size: var(--font);
    }

    .division {
        margin-right: var(--margin-medium) !important;
    }

    .lastDivision {
        flex: 0.2 !important;
        margin-top: 32px;
    }

    .lastDivisionIcon {
        height: 56px;
        box-sizing: border-box;
        width: 160px;
        font-size: var(--font);
        line-height: var(--line);
    }

    .whereText {
        margin: 0 0 12px;
    }

    .datesText {
        margin: 0 0 12px;
    }

    .container {
        padding: 24px;
    }
}

@media (min-width: 1680px) {
    .container {
        padding: 32px;
    }

    .lastDivision {
        margin-top: 36px;
    }

    .lastDivisionIcon {
        font-size: var(--font-medium);
        line-height: var(--line-medium);
    }

    .whereText,
    .datesText {
        font-size: var(--font);
        line-height: var(--line);
    }
}

.mobileInputPlaceholder {
    font-size: var(--font-small);
    line-height: var(--line-small);
}

.mobileInputIcon {
    width: 20px;
    height: 20px;
    margin-right: var(--margin-small);
    color: var(--color-gray-light);
    stroke-width: 1.5;
}
