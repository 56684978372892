.container {
    margin-top: 40px !important;
    position: relative;
}

.section {
    margin-bottom: 80px !important;
}

.row {
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.card img {
    width: 100%;
    height: auto;
}

.row:first-child {
    margin-top: 0;
}

.row:nth-child(2) {
    margin: 190px 0;
}

.card {
    max-width: 100% !important;
}

.cardBody {
    padding: 24px 32px;
}

.cardTitle h2 {
    margin: 0 0 8px;
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-semibold);
}

.cardText h3 {
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
    color: var(--color-gray);
    margin: unset;
}

@media screen and (max-width: 691px) {
    .row {
        flex-direction: column !important;
        align-items: center !important;
    }
    .row > div:not(:last-child) {
        margin-right: 0rem !important;
        margin-bottom: 32px !important;
    }

    .section {
        margin-top: -80px;
    }
}

@media screen and (min-width: 691px) and (max-width: 927px) {
    .row {
        flex-direction: column !important;
        align-items: center !important;
    }
    .row > div:not(:last-child) {
        margin-right: 0rem !important;
        margin-bottom: 32px !important;
    }

    .section {
        margin: auto;
    }

    .container {
        max-width: 480px;
    }

    .header {
        display: block;
        margin: unset;
        text-align: center;
    }
}

@media screen and (min-width: 928px) and (max-width: 1359px) {
    .cardText h3 {
        font-size: var(--font) !important;
    }
    .cardTitle h2 {
        font-size: var(--font-medium) !important;
        line-height: var(--line-medium);
    }
}

@media screen and (min-width: 928px) {
    .cardText h3 {
        font-size: var(--font-small);
        line-height: var(--line-small);
    }

    .container {
        margin-top: 40px !important;
    }

    .section {
        margin-top: 80px;
        margin-bottom: 211px !important;
    }

    .container > .row > div:not(:last-child) {
        margin-right: var(--margin-large) !important;
    }

    .cardBody {
        padding: 24px 24px 32px;
    }

    .card {
        min-height: 375px !important;
    }
}

@media screen and (min-width: 1360px) {
    .section {
        margin-top: 145px;
        margin-bottom: 226px !important;
    }

    .cardText h3 {
        font-size: var(--font);
        line-height: var(--line);
    }

    .card {
        min-height: 398px !important;
    }

    .cardBody {
        padding: 32px;
    }

    .container {
        margin-top: 56px !important;
    }

    .container > .row > div:not(:last-child) {
        margin-right: var(--margin-xlarge) !important;
    }
}

@media screen and (min-width: 1680px) {
    .cardTitle h2 {
        font-size: var(--font-xxlarge);
        line-height: var(--line-xxlarge);
        margin: 0 0 12px;
    }

    .section {
        margin-top: 141px;
    }

    .container {
        margin-top: 80px !important;
    }

    .cardText h3 {
        font-size: var(--font-medium);
        line-height: var(--line-medium);
    }

    .card {
        min-height: 452px !important;
    }
}
