.container {
    margin-bottom: var(--margin-large);
}

.title {
    font-size: var(--font-h5);
    line-height: var(--line-h5);
    font-weight: var(--font-weight-semibold);
    flex: 1 !important;
    margin-bottom: 12px;
}

.description {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
    color: var(--color-gray-darker);
}

.ratingContainer {
    flex-direction: row;
    align-items: center;
}

.ratingContainer > * {
    margin-right: 5px;
    margin-bottom: 0 !important;
}

.rating {
    color: var(--color-gray-darker);
    font-weight: var(--font-weight-light);
    margin-right: 5px;
    font-size: var(--font-xsmall);
}

.reviews {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
}

.ratingIcon {
    width: 16px;
    height: 16px;
}

.categoryContainer {
    align-items: center;
    margin-bottom: 12px;
}

.categoryContainer > * {
    color: var(--color-gray-darker);
    font-weight: var(--font-weight-light);
    margin-right: 5px !important;
    font-size: 13px !important;
    margin-bottom: 0 !important;
}

.category {
    color: var(--color-red) !important;
    font-weight: var(--font-weight-light) !important;
}

.actions {
    justify-content: space-between;
    align-items: center;
}

.actionRow {
    flex: 0 !important;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
}

.share {
    stroke: black;
    height: 24px;
    width: 24px;
    stroke-width: 0.1rem;
    cursor: pointer;
}

@media (min-width: 1360px) {
    .title {
        font-size: var(--font-h4);
        line-height: var(--line-h4);
    }

    .categoryContainer > * {
        font-size: var(--font-medium) !important;
        line-height: var(--line-medium);
    }

    .container {
        margin-bottom: var(--margin-xlarge);
        flex: 0 !important;
    }
}
