.price {
    font-size: var(--font-large);
    line-height: var(--line);
    font-weight: var(--font-weight-semibold);
    margin-right: 5px !important;
    flex: 0 !important;
}

.slash {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-light);
    text-align: start;
}

.message {
    font-size: var(--font);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
    margin-right: var(--xs_space) !important;
    text-align: start;
}

.underline {
    text-decoration: underline;
}

.infoIcon {
    width: 20px;
    height: 20px;
    stroke-width: 1px;
    stroke: var(--color-grayish);
}

.totalContainer {
    width: fit-content;
}

.priceDayContainer {
    min-width: max-content;
}

@media screen and (min-width: 692px) {
    .price {
        font-size: var(--font-h5);
        line-height: var(--line-xxlarge);
    }
}
