.container {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
}

.header {
    flex: 1;
    max-height: 2.5rem;
    padding: 2rem 1rem 0.825rem;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-gray-light);
}

.closeIcon {
    position: absolute;
    left: 1rem;
}

.closeBar {
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background: var(--color-grayish);
    position: absolute;
    top: 0.725rem;
    left: 50%;
    transform: translateX(-50%);
}

.headerTitle {
    font-size: 1.275rem;
    font-weight: var(--font-weight-medium);
}

.content {
    flex: 1;
    padding: 1rem 1.5rem;
}

.filterTitle {
    font-size: 1.5rem;
    font-weight: var(--font-weight-semibold);
    color: var(--color-black);
    margin-bottom: 1rem;
}

.actionRow {
    padding: 1.5rem 1.5rem 3rem;
    box-shadow: 0 1px 14px 0 rgb(0 0 0 /14%);
}

.modalButton {
    flex: 1;
    padding: 1.825rem;
    border-radius: 2rem;
}

.modalButton span {
    font-size: 1.2rem;
    font-weight: var(--font-weight-regular);
}

.label .span {
    font-size: var(--font) !important;
    line-height: var(--line) !important;
    color: var(--color-black);
    font-weight: var(--font-weight-light);
    text-align: center;
}

.radio .radio_option {
    font-size: var(--font) !important;
    line-height: var(--line) !important;
    color: var(--color-black);
    font-weight: var(--font-weight-light) !important;
    text-align: center;
}
