.container {
    flex: 1;
}

.cardHeader {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.cardHeader > span {
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-medium);
    margin: 0;
}

.editIcon {
    cursor: pointer;
    color: var(--color-red);
    width: 20px;
    height: 20px;
    justify-self: flex-end;
}
