.itemContainer {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.itemContainerRow {
    padding-bottom: var(--margin);
    align-items: center;
    display: flex;
    flex-direction: row;
}
.itemContainer .icon {
    height: 35px;
    width: 35px;
    margin-bottom: var(--margin);
}

.itemContainerRow .icon {
    height: 35px;
    width: 35px;
    margin-right: var(--margin-small);
}

.itemContainerRow > * {
    margin-bottom: unset;
}

.itemContainer span {
    margin-bottom: 0;
    color: var(--color-gray-darker);
    white-space: nowrap !important;
}

.itemContainerRow span {
    margin-left: 20px;
    margin-bottom: 0;
    color: var(--color-gray-darker);
    white-space: nowrap !important;
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}
