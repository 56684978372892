.container {
    position: relative;
}

.container :global(.SingleDatePickerInput),
.container :global(.SingleDatePicker),
.container :global(.SingleDatePicker > div:first-child),
.container :global(.DateInput input),
.container :global(.DateInput) {
    height: 100%;
    display: flex;
    flex: 1;
}

.container :global(.DateRangePickerInput) {
    border: none !important;
    outline: none;
    width: 100%;
    cursor: pointer;
    display: flex;
}

.container :global(.DateRangePickerInput_arrow) {
    text-align: center;
    flex: 1;
    margin-right: var(--margin);
}

.container :global(.CalendarDay__selected) {
    background-color: var(--color-gray-darker);
    border: none !important;
    outline: none;
}

.container :global(.DateInput_input) {
    outline: none;
    border: none !important;
    width: 90px;
    position: absolute;
}

.container :global(.DateInput) {
    width: 90px;
}

.container :global(.CalendarDay__blocked_out_of_range) {
    text-decoration: line-through;
    background-color: transparent;
    color: var(--color-gray-datepicker);
}

.container :global(.CalendarDay__blocked_calendar) {
    text-decoration: line-through;
    background-color: transparent;
    position: relative;
    color: var(--color-gray-datepicker);
}

:global(.DateRangePicker_picker) {
    z-index: 3;
    box-shadow: 0 0 24px 0 #0b0b0d1f;
    border-radius: 8px;
}

:global(.DayPicker_portal__vertical) {
    top: 80px;
    position: relative;
}

:global(.DayPickerNavigation__verticalDefault) {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 80%;
}

:global(.DayPickerNavigation_button__verticalDefault) {
    width: calc(var(--page-max-width) / 2);
    padding: 0;
}

:global(#endDate) {
    visibility: hidden;
}

.container .placeholder {
    font-family: var(--font-family-secondary);
    font-size: var(--font-small);
    color: gray;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1 !important;
    background: var(--color-white);
    cursor: pointer;
    margin-right: 0 !important;
    vertical-align: middle !important;
}

.container .renderDate {
    font-family: var(--font-family-secondary);
    font-size: var(--font-small);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1 !important;
    background: var(--color-white);
    cursor: pointer;
    margin-right: 0 !important;
    display: flex;
    align-items: center;
}

.container,
.container .renderDate:focus-visible {
    outline: none;
}

.division {
    margin: 0 16px !important;
    width: 1px;
}

.dateContainer {
    display: flex;
}

.renderDate .clearButton {
    flex: 0;
    white-space: nowrap;
    cursor: pointer;
}

.clearButton:hover {
    opacity: 0.7;
}

@media (min-width: 928px) {
    :global(.CalendarMonthGrid) {
        left: 0;
    }

    :global(.CalendarMonth_caption) {
        color: var(--color-gray-darker);
        font-size: var(--font);
    }

    :global(.CalendarMonth_caption) > strong {
        font-weight: var(--font-weight-medium);
    }

    :global(.DayPicker_weekHeader) {
        padding-right: 2px !important;
        padding-left: 2px !important;
    }

    :global(.DayPicker_weekHeader_li) {
        font-size: var(--font);
        line-height: var(--line);
        color: var(--color-grayish);
    }

    :global(.CalendarMonth_table) {
        margin-top: 20px;
    }

    :global(.CalendarDay) {
        border: 0;
    }

    :global(.CalendarDay):hover {
        border: 0;
    }

    :global(.CalendarDay__selected_span) {
        background-color: #00000000 !important;
        color: var(--color-gray-darker);
        font-weight: var(--font-weight-medium);
        position: relative;
        padding: 0;
    }

    :global(.CalendarDay__selected_span):hover {
        background-color: #00000000;
        color: var(--color-gray-darker);
        border: 0;
    }

    :global(.CalendarDay__selected_start) span,
    :global(.CalendarDay__selected_end) span {
        color: #fff;
        font-weight: var(--font-weight-medium);
    }

    :global(.CalendarDay__hovered_span) {
        background-color: var(--color-gray-lighter);
        color: var(--color-gray-darker);
    }

    :global(.CalendarDay__hovered_span):hover {
        background-color: var(--color-gray-darker);
        color: var(--color-white);
        border: 0;
        border-radius: 50%;
    }

    :global(.CalendarDay__hovered_span):hover > span {
        background-color: var(--color-gray-darker);
        color: var(--color-white);
        border: 0;
        border-radius: 50%;
    }

    :global(.CalendarDay__hovered_span):hover > .layer {
        background-color: var(--color-gray-lighter);
        width: 15px;
        right: 0;
    }

    :global(.CalendarDay__selected_start),
    :global(.CalendarDay__selected_end) {
        position: relative;
        padding: 0;
    }

    :global(.CalendarDay__selected_start) > .layer,
    :global(.CalendarDay__selected_end) > .layer {
        background-color: var(--color-gray-lighter);
        width: 15px;
        height: 30px;
        top: 4px;
        position: absolute;
        z-index: 0;
    }

    :global(.CalendarDay__selected_start) > .layer {
        right: 0;
    }

    :global(.CalendarDay__selected_end) > .layer {
        left: 0;
    }

    :global(.CalendarDay__selected_start),
    :global(.CalendarDay__selected_end) {
        border-radius: 50%;
    }

    :global(.CalendarDay__selected_start) > span,
    :global(.CalendarDay__selected_end) > span {
        width: 100%;
        height: 100%;
        background-color: #000;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
    }

    :global(.CalendarDay__selected_span) > .layer {
        background-color: var(--color-gray-lighter);
        width: 39px;
        height: 30px;
        top: 4px;
        left: 0;
        position: absolute;
        z-index: 0;
    }

    :global(.CalendarDay__selected_span) > span {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    :global(.CalendarDay__hovered_span) {
        background-color: #00000000;
        position: relative;
    }

    :global(.CalendarDay__hovered_span) > .layer {
        width: 100%;
        height: 30px;
        background-color: var(--color-gray-lighter);
        position: absolute;
        top: 4px;
        left: 0;
    }

    :global(.CalendarDay__hovered_span) > span {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    :global(.CalendarDay__selected_start.CalendarDay__selected_end) > .layer {
        width: 0;
    }

    .next {
        position: absolute;
        right: 24px;
        transform: rotate(180deg);
        top: 18px;
        padding: 4px;
    }

    .prev {
        position: absolute;
        left: 10px;
        top: 18px;
        padding: 4px;
    }

    .next > svg > path,
    .prev > svg > path {
        stroke: black;
    }
}
