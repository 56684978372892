.container {
    display: flex;
    flex-direction: column;
}

.cardHeader {
    align-items: center;
    margin-bottom: 16px;
}

.cardHeader > span {
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-medium);
    margin: 0;
}

.description {
    font-size: var(--font-small);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
}

.accordionContainer {
    margin-top: var(--margin);
}

.accordionHeader {
    padding: 12px 0;
    border-bottom: 1px solid var(--color-gray-lighter);
}

.accountInfo {
    align-items: center;
    margin: 12px 0;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--color-gray-lighter);
}

.accountInfo span:not(:first-child) {
    margin-top: 4px;
}

.iconsContainer {
    align-items: center;
    justify-content: flex-end;
}

.icon {
    width: 24px;
    height: 24px;
    color: var(--color-red);
    margin-left: 12px;
}

.link {
    color: var(--color-red);
    margin: 12px 0;
    text-decoration: underline;
    font-size: var(--font-small);
    line-height: var(--line-small);
}

.mainBilling {
    margin: 12px 0 24px;
}

.mainBilling span {
    margin-top: 4px;
}

.mainBilling span:not(:first-child) {
    margin-left: 4px;
}

@media only screen and (min-width: 492px) {
    .container {
        display: flex;
        flex-direction: column;
    }

    .cardHeader {
        align-items: center;
        margin-bottom: 16px;
    }

    .cardHeader > span {
        font-size: var(--font-large);
        line-height: var(--line-large);
        font-weight: var(--font-weight-medium);
        margin: 0;
    }

    .description {
        font-size: var(--font-small);
        line-height: var(--line-small);
        font-weight: var(--font-weight-light);
        color: var(--color-grayish);
    }

    .accordionContainer {
        margin-top: var(--margin);
    }

    .accordionHeader {
        padding: 12px 0;
        border-bottom: 1px solid var(--color-gray-lighter);
    }

    .accountInfo {
        align-items: center;
        margin: 12px 0;
        padding-bottom: 12px;
        border-bottom: 1px solid var(--color-gray-lighter);
    }

    .accountInfo span:not(:first-child) {
        margin-top: 4px;
    }

    .iconsContainer {
        align-items: center;
        justify-content: flex-end;
    }

    .icon {
        width: 24px;
        height: 24px;
        color: var(--color-red);
        margin-left: 12px;
    }

    .link {
        color: var(--color-red);
        margin: 12px 0;
        text-decoration: underline;
        font-size: var(--font-small);
        line-height: var(--line-small);
    }

    .mainBilling {
        margin: 12px 0 24px;
    }

    .mainBilling span {
        margin-top: 4px;
    }

    .mainBilling span:not(:first-child) {
        margin-left: 4px;
    }
}

@media only screen and (min-width: 692px) {
    .container {
        display: flex;
        flex-direction: column;
    }

    .cardHeader {
        align-items: center;
        margin-bottom: 16px;
    }

    .cardHeader > span {
        font-size: var(--font-large);
        line-height: var(--line-large);
        font-weight: var(--font-weight-medium);
        margin: 0;
    }

    .description {
        font-size: var(--font-small);
        line-height: var(--line-small);
        font-weight: var(--font-weight-light);
        color: var(--color-grayish);
    }

    .accordionContainer {
        margin-top: var(--margin);
    }

    .accordionHeader {
        padding: 12px 0;
        border-bottom: 1px solid var(--color-gray-lighter);
    }

    .accountInfo {
        align-items: center;
        margin: 12px 0;
        padding-bottom: 12px;
        border-bottom: 1px solid var(--color-gray-lighter);
    }

    .accountInfo span:not(:first-child) {
        margin-top: 4px;
    }

    .iconsContainer {
        align-items: center;
        justify-content: flex-end;
    }

    .icon {
        width: 24px;
        height: 24px;
        color: var(--color-red);
        margin-left: 12px;
    }

    .link {
        color: var(--color-red);
        margin: 12px 0;
        text-decoration: underline;
        font-size: var(--font-small);
        line-height: var(--line-small);
    }

    .mainBilling {
        margin: 12px 0 24px;
    }

    .mainBilling span {
        margin-top: 4px;
    }

    .mainBilling span:not(:first-child) {
        margin-left: 4px;
    }
}

@media only screen and (min-width: 928px) {
    .container {
        display: flex;
        flex-direction: row;
    }

    .personalWalletCard {
        width: 50%;
        margin-left: var(--margin-xlarge);
    }

    .businessWalletCard {
        width: 50%;
    }

    .cardHeader {
        align-items: center;
        margin-bottom: 16px;
    }

    .cardHeader > span {
        font-size: var(--font-large);
        line-height: var(--line-large);
        font-weight: var(--font-weight-medium);
        margin: 0;
    }

    .description {
        font-size: var(--font-small);
        line-height: var(--line-small);
        font-weight: var(--font-weight-light);
        color: var(--color-grayish);
    }

    .accordionContainer {
        margin-top: var(--margin);
    }

    .accordionHeader {
        padding: 12px 0;
        border-bottom: 1px solid var(--color-gray-lighter);
    }

    .accountInfo {
        align-items: center;
        margin: 12px 0;
        padding-bottom: 12px;
        border-bottom: 1px solid var(--color-gray-lighter);
    }

    .accountInfo span:not(:first-child) {
        margin-top: 4px;
    }

    .iconsContainer {
        align-items: center;
        justify-content: flex-end;
    }

    .icon {
        width: 24px;
        height: 24px;
        color: var(--color-red);
        margin-left: 12px;
    }

    .link {
        color: var(--color-red);
        margin: 12px 0;
        text-decoration: underline;
        font-size: var(--font-small);
        line-height: var(--line-small);
    }

    .mainBilling {
        margin: 12px 0 24px;
    }

    .mainBilling span {
        margin-top: 4px;
    }

    .mainBilling span:not(:first-child) {
        margin-left: 4px;
    }
}
