.sectionTitle {
    text-align: center;
}

@media only screen and (min-width: 1360px) {
    .sectionTitle {
        font-size: 40px !important;
        line-height: 50px !important;
    }
}
