.title {
    align-items: center;
    font-size: var(--font-xxlarge);
    font-weight: 275;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    margin-top: 0.5rem;
    margin-bottom: 3rem;
    align-items: center;
}

.logo {
    height: 23px;
}

.closeButton {
    cursor: pointer;
}

.input {
    min-width: 320px;
    width: 100%;
}

.input input {
    background-color: white !important;
    border: 1px solid #e2e3e7 !important;
    border-radius: 4px !important;
    text-align: left;
}

.input span {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xsmall);
    color: #0b0b0d;
}

.registerLink {
    color: #e14436;
}

.registerLabel {
    color: #9da0aa;
    text-align: center !important;
    margin: 0;
}

.register {
    align-items: center;
    display: flex;
    flex-direction: column;
    column-gap: 5px;
}

@media screen and (min-width: 492px) {
    .container {
        text-align: left;
        border-radius: 8px;
        max-width: 400px;
        width: calc(100% - 48px);
        margin: auto;
        height: fit-content !important;
        bottom: unset !important;
        top: 20% !important;
    }
}
