div.column {
    display: flex;
    flex-direction: column;
}

div.margin {
    margin-bottom: var(--margin-medium);
}

div.column button:last-child {
    margin-bottom: 0;
}

div.row,
div.dynamic {
    display: flex;
    flex-direction: row;
}

div.row.end {
    justify-content: flex-end;
}

div.row > div:not(:last-child),
div.row > button:not(:last-child),
div.dynamic > div:not(:last-child),
div.dynamic > button:not(:last-child) {
    margin-right: var(--margin-medium);
}

div.row div,
div.dynamic div {
    flex: 1;
}

@media only screen and (max-width: 400px) {
    div.dynamic {
        flex-direction: column;
    }

    div.dynamic > div:not(:last-child),
    div.dynamic > button:not(:last-child) {
        margin-right: 0;
    }

    div.dynamic div {
        flex: unset;
    }
}
