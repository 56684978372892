.text {
    font-size: var(--font-h4);
    font-weight: var(--font-weight-extra-light);
    line-height: 40px;
    color: var(--color-black);
    margin: 0;
}

@media screen and (min-width: 1680px) {
    .text {
        font-size: 40px;
        line-height: 50px;
    }
}
