body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-multi-carousel-list {
    overflow: visible !important;
}

.react-multi-carousel-track {
    overflow: visible !important;
}

img {
    /* Prevent image ghost dragging */
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none;
}

a {
    width: fit-content;
}

input {
    width: 100% !important;
}

/* Remove Chakra UI Focus*/
:focus,
*[data-focus] {
    box-shadow: none !important;
    outline: 0 !important;
}
