.header {
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--margin-large) !important;
}

.insuranceItems {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
}

.splitter {
    margin-top: var(--margin-xlarge);
}
