.avatar {
    height: 7rem;
    width: 7rem;
    object-fit: cover;
    object-position: top;
    border-color: var(--color-gray);
    border-style: solid;
    border-width: thin;
    margin-bottom: var(--margin-medium);
    margin-right: var(--margin-medium);
    border-radius: 50%;
}
