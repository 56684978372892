.cardHeader {
    align-items: center;
    margin-bottom: 16px;
}

.cardHeader > span {
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    margin: 0;
}

.description {
    font-size: var(--font-small);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
}

.accordionContainer {
    margin-top: var(--margin);
}

.accordionHeader {
    padding: 12px 0;
    border-bottom: 1px solid var(--color-gray-lighter);
}

.accountInfo {
    align-items: center;
    margin: 12px 0;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--color-gray-lighter);
}

.accountInfo span:not(:first-child) {
    margin-top: 4px;
}

.iconsContainer {
    align-items: center;
    justify-content: flex-end;
}

.icon {
    width: 24px;
    height: 24px;
    color: var(--color-red);
    margin-left: 12px;
}

.link {
    color: var(--color-red);
    margin: 12px 0;
    text-decoration: underline;
    font-size: var(--font-small);
    line-height: var(--line-small);
}

.editLink {
    color: var(--color-red);
    text-decoration: underline;
    font-size: var(--font-small);
    line-height: var(--line-small);
}

.mainBilling {
    margin: 12px 0 24px;
}

.mainBilling span {
    margin-top: 4px;
}

.mainBilling span:not(:first-child) {
    margin-left: 4px;
}

.paymentProfile {
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-xsmall);
    margin-top: var(--margin-large);
}

.briefcaseIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.noCards {
    align-items: center;
    margin-top: var(--small-extra_space);
    font-weight: var(--font-weight-medium);
}
