.overlay {
    background-color: var(--color-gray-dark);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s;
    margin-right: 0 !important;
}

.overlay.open {
    z-index: 14;
    opacity: 0.6;
    position: fixed;
}

.container {
    --sidebarWidth: 250px;
    --padding: var(--padding-medium);

    background-color: var(--color-white);
    position: fixed;
    top: 0;
    left: calc(-1 * var(--sidebarWidth) - (var(--padding) * 2));
    bottom: 0;
    width: var(--sidebarWidth);
    z-index: 15;
    transition: left 0.3s;

    padding: var(--padding);
}

.container.open {
    left: 0;
    box-shadow: 2px 2px 10px var(--color-gray-dark);
}

@media only screen and (max-width: 500px) {
    .container {
        --sidebarWidth: 75%;
        --padding: var(--padding-medium);
    }

    .container.open {
        left: 0;
        box-shadow: 2px 2px 10px var(--color-gray-dark);
    }
}
