.leaderCard {
    box-sizing: border-box;
    box-shadow: 0 4px 16px #0b0b0d14;
}

.img {
    left: 0;
    width: 100%;
    height: auto;
}

.leadersTitle {
    margin-bottom: 40px;
}

.leaderCard > *:not(img) {
    padding: 0 32px;
}

.name {
    font-size: var(--font-large);
    line-height: var(--line-xlarge);
    font-weight: var(--font-weight-semibold);
    margin-top: 32px;
    margin-bottom: 0;
}

.jobTitle {
    margin-bottom: 8px;
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
}

.body {
    display: block;
    font-size: var(--font);
    line-height: var(--line);
    color: var(--color-grayish);
    margin-bottom: 16px;
    font-weight: var(--font-weight-light);
}

.closed {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.seeMore {
    font-size: var(--font-small);
    line-height: 21px;
    font-weight: var(--font-weight-semibold);
    color: var(--color-red);
    text-decoration: underline;
    text-underline-offset: 1px;
    margin-bottom: 32px;
    cursor: pointer;
}
