.component {
    display: flex;
    margin-right: 1rem;
}

.filled {
    width: auto;
    height: 1.2rem;
}

.unfilled {
    fill: white;
    width: auto;
    height: 1.2rem;
}
