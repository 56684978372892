.cardContainer {
    width: 70%;
    max-width: 1000px;
    margin-bottom: var(--margin);
    background: #fff;
    border-radius: 10px;
    padding: 12px 24px;
    box-sizing: border-box;
}

.cardHeader {
    color: #000;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardTitle {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-medium);
}

.cardIcon {
    font-size: var(--font-h4);
}

.contentPlaceholder {
    padding: 16px 40px 16px 0;
    font-size: 1.145rem;
    color: var(--color-gray);
}

@media (max-width: 540px) {
    .cardContainer {
        width: 100%;
    }

    .cardTitle {
        font-size: 1.145rem;
    }

    .contentPlaceholder {
        padding: 1rem 1rem 1rem 0;
        font-size: 0.975rem;
    }
}

@media (min-width: 492px) {
    .cardContainer {
        padding: 16px 32px;
    }
}

@media (min-width: 1360px) {
    .cardContainer {
        margin-bottom: var(--margin-medium);
    }
}
