.navLinks {
    color: var(--color-grayish);
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
}

.link {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    color: '#9da0aa';
    margin-right: 0.3rem;
    margin-left: 0.3rem;
    text-decoration: underline;
}

.title {
    font-size: var(--font-h4);
    line-height: 40px;
    font-weight: var(--font-weight-semibold);
}

.headerRow {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.historyLink {
    text-decoration: underline;
    font-weight: normal;
    font-size: var(--font);
    color: var(--color-red);
    cursor: pointer;
    line-height: var(--line);
}

.tabs {
    margin-top: 2rem;
}

.tab {
    text-align: center;
    font-weight: var(--font-weight-light);
    font-size: 1rem;
    margin: auto !important;
    cursor: pointer;
    color: var(--color-grayish);
    width: 335px;
}

.bookingSpan {
    display: none;
}

.active {
    color: var(--color-red);
    font-weight: var(--font-weight-medium);
}

.border {
    margin-top: 1rem;
    border-bottom: 1px solid var(--color-grayish);
}

.activeBorder {
    border-bottom: 2px solid var(--color-red);
}

.container {
    margin-bottom: 120px !important;
}

@media screen and (max-width: 329px) {
    .tab {
        margin-bottom: 20px !important;
    }
}

@media screen and (min-width: 492px) {
}

@media screen and (min-width: 928px) {
    .bookingSpan {
        display: inline;
    }

    .tab {
        font-size: var(--font-medium);
    }

    .headerRow {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .historyLink {
        border: 1px solid var(--color-gray-light);
        box-sizing: border-box;
        border-radius: 8px;
        text-decoration: underline;
        padding: 12px 16px;
        font-weight: normal;
        font-size: var(--font);
        color: var(--color-red);
        cursor: pointer;
    }

    .title {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
        font-weight: var(--font-weight-semibold);
    }
}
