.cardContainer {
    margin-bottom: var(--margin);
    background: #fff;
    box-sizing: border-box;
}

.cardHeader {
    color: #000;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cardTitle {
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-xsmall);
}

.defaultIconStyles {
    width: 20px;
    height: 16px;
}

.openedIcon {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
}

.closedIcon {
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
}
