.container {
    position: relative;
    margin-bottom: 120px !important;
}

.stick {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.backIcon {
    cursor: pointer;
}

.content {
    flex: unset !important;
    margin-bottom: var(--margin-xlarge);
    flex-direction: column !important;
    justify-content: space-between;
}

.contentDetails {
    flex: 1 !important;
    margin-right: 0 !important;
    max-width: 800px;
}

.backIcon {
    width: 24px;
    height: 24px;
    margin-bottom: var(--margin);
}

.sectionTitle {
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--margin-medium);
}

.cancelationFirst {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}

.cancelationBodyBold {
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    display: initial;
}

.cancelationFirst span {
    font-weight: var(--font-weight-medium);
}

.cancelationLast {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    margin-bottom: 0;
}

.clickableLink {
    color: var(--color-alice);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    text-decoration: underline;
    cursor: pointer;
    align-items: center;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: pre-wrap;
}

.clickableLinkRound {
    cursor: pointer;
    align-items: center;
    margin-bottom: 8px;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: pre-wrap;
    border: 1px solid #e2e3e7;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px 24px;
    display: none;
}

.bookingButton {
    max-width: 238px;
    max-height: 56px;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-large);
    line-height: var(--line-large);
    display: none;
}

.addDriver :first-child {
    margin-right: 10px;
}

.panelContainer {
    max-width: 400px;
    flex: 1 !important;
    align-items: flex-start;
}

.splitter {
    margin-top: 40px;
}

.addCode {
    margin-bottom: 0;
}

.assetPanel {
    position: sticky !important;
    top: var(--margin-xlarge);
    padding: 0;
    width: 100%;
    box-shadow: none;
}

.paymentMethod {
    max-width: 260px;
}

.editBilling {
    margin-bottom: 0;
}

.addDriverContainer {
    margin-top: 24px;
}

@media screen and (min-width: 492px) {
    .clickableLinkRound {
        display: flex;
    }
}

@media screen and (min-width: 692px) {
    .content {
        flex-direction: row !important;
    }

    .panelContainer,
    .contentDetails {
        width: calc(50% - var(--margin-xlarge) / 2) !important;
    }
    .contentDetails {
        margin-right: var(--margin-xlarge) !important;
    }

    .panelContainer {
        display: flex !important;
    }

    .assetPanel {
        box-shadow: rgb(11 11 13 / 8%) 2px 2px 16px;
        padding: var(--padding-large);
    }

    .bookingButton {
        display: block !important;
    }
}

@media screen and (min-width: 928px) {
    .panelContainer,
    .contentDetails {
        width: calc(50% - var(--margin-xxxlarge) / 2) !important;
    }

    .panelContainer {
        flex: unset !important;
    }

    .contentDetails {
        margin-right: var(--margin-xxxlarge) !important;
    }
}

@media screen and (min-width: 1360px) {
    .isRow {
        flex-direction: row !important;
        justify-content: space-between;
    }

    .contentDetails {
        margin-right: var(--margin-xxxxlarge) !important;
    }

    .paymentMethod {
        width: 260px;
    }

    .addDriverContainer {
        margin-top: 0;
    }
}
