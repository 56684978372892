.loadingContainer {
    flex: 1;
    align-items: center;
    justify-content: center;
}

.loadingSpinner {
    width: 48px;
    height: 48px;
    color: var(--color-black);
}

.mainBilling input {
    background-color: transparent;
    border: 1px solid var(--color-gray-light-border);
    border-radius: var(--xs_space);
}

.billingData {
    font-style: normal;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    color: black;
}

.actionRow {
    width: 100%;
}

.cancel {
    align-self: center;
    font-size: var(--font-small);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-small);
    text-decoration: underline;
}

.cancelBtn {
    display: flex;
    cursor: pointer;
}

.resetBtn {
    display: none;
}

.submit {
    flex: 1;
}

@media screen and (min-width: 692px) {
    .actionRow {
        width: 100%;
        border-radius: 0px 0px 8px 8px;
    }

    .cancelBtn {
        display: none;
    }

    .resetBtn {
        display: flex;
        cursor: pointer;
    }
}
