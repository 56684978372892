.sectionTitle {
    font-size: var(--font-h4);
    font-weight: var(--font-weight-semibold);
    line-height: 40px;
    color: var(--color-black);
    margin: 0;
    word-break: normal;
}

@media screen and (min-width: 1680px) {
    .sectionTitle {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
    }
}
