.pageTitle > span,
.breadcrumbs > div > a,
.breadcrumbs > div > * {
    color: var(--color-white);
}

.breadcrumbs {
    margin-top: 32px;
}

.sectionRow {
    margin-bottom: 80px !important;
    position: relative;
}

.buttonBlack {
    height: 48px;
    width: 216px;
    border-radius: 88px;
    font-size: var(--font);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    font-weight: var(--font-weight-medium);
    background-color: var(--color-black);
    color: var(--color-white);
    text-decoration: none;
}

.hero {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 720px;
    text-align: center;
}

.heroTitle {
    text-align: center;
    font-size: var(--font-h4) !important;
    line-height: 40px !important;
}

.background {
    max-height: 532px !important;
    padding-left: 3px;
    margin-left: -2px;
}

.background img {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    transform: translate(0, -30%);
    min-height: 50rem;
    object-fit: cover;
}

@media only screen and (min-width: 692px) {
    .heroTitle {
        max-width: 480px;
    }
}

@media only screen and (min-width: 1360px) {
    .heroTitle {
        max-width: 680px;
        text-align: center;
        font-size: var(--font-h2) !important;
        line-height: var(--line-h2) !important;
    }
}

@media (max-width: 492px) {
    .background {
        max-height: 540px !important;
    }
}

@media (max-width: 692px) {
    .background {
        max-height: 460px !important;
    }

    .heroTitle {
        text-align: left;
    }

    .hero {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }
}

@media (max-width: 928px) {
    .background {
        max-height: 440px !important;
    }
}

@media (max-width: 1360px) {
    .background {
        max-height: 500px !important;
    }
}
