.sortBarContainer {
    position: relative;
    width: fit-content;
    flex: 1 !important;
    display: flex;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    flex-grow: 0 !important;
    white-space: nowrap;
}

.sortBar {
    background: var(--color-gray-darker);
    color: white;
    border-radius: 20rem;
    text-align: center;
    padding: 10px 20px;
    border: 2px solid var(--color-gray-darker);
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: stretch;
    align-items: center;
}

.sortBar span {
    font-weight: var(--font-weight-light);
    font-size: 15px;
    line-height: 22px;
}

.dropdown {
    position: absolute;
    right: 0.015rem;
    z-index: -1;
    left: 0.1rem;
    background: var(--color-white);
    color: var(--color-gray-darker);
    opacity: 0;
    transition: flex ease-in-out 100ms;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    border-radius: 0.3rem;
    padding: var(--padding) var(--padding-medium);
    width: fit-content;
}

.dropdownItem {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
}

.focus .dropdown {
    top: 45px;
    opacity: 1;
    z-index: 5;
    flex: 1;
}

.active {
    color: red;
    font-weight: var(--font-weight-medium);
}
