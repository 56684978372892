.businessContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    background-image: url('../../../../assets/images/design/business_banner.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.pageContainer {
    margin-top: 80px;
    margin-bottom: 80px !important;
    flex-direction: row !important;
    justify-content: space-between;
}

.descriptionContainer {
    width: 50%;
    justify-content: center;
}

.cardContainer {
    position: relative;
    width: 45%;
    align-items: flex-end;
}

.card {
    max-width: 400px;
    min-height: 375px;
    padding: 40px 32px;
    position: absolute;
    z-index: 1;
    justify-content: space-between;
}

.cardSection {
    align-items: center;
    margin-bottom: 40px;
}

.cardSection span {
    margin-top: 32px;
    padding: unset;
    text-align: center;
    font-size: var(--font-medium);
    line-height: var(--line-medium);
}

.cardImage {
    width: 200px;
}

.title {
    color: #fff;
    margin: unset;
    margin-bottom: 16px;
}

.description {
    color: #fff;
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: 32px;
}

.cardText span {
    padding: unset;
}

.boldText {
    font-weight: var(--font-weight-semibold);
}

.lightText {
    font-weight: var(--font-weight-light);
}

@media (hover: hover) and (pointer: fine) {
    .button:hover {
        background-color: #fff !important;
        color: #0b0b0d;
    }
}

@media (max-width: 1359px) {
    .businessContainer {
        justify-content: flex-start;
        min-height: 200px;
    }

    .cardContainer {
        width: 45%;
        margin-left: 2rem;
    }

    .descriptionContainer {
        width: 45%;
    }

    .cardImage {
        width: 160px;
    }
}

@media (max-width: 927px) {
    .pageContainer {
        flex-direction: column !important;
    }

    .businessContainer {
        flex-direction: column;
        align-items: flex-start;
    }

    .descriptionContainer {
        width: 100%;
    }

    .cardContainer {
        margin-top: 2rem;
        margin-left: 0;
        width: 100%;
    }

    .card {
        margin: 1rem auto 0 auto !important;
        position: relative;
        top: 0;
    }

    .cardImage {
        width: 140px;
    }
}

@media screen and (min-width: 692px) {
    .pageContainer {
        width: 480px;
    }
}

@media screen and (min-width: 928px) {
    .pageContainer {
        width: 100%;
    }

    .description {
        margin-bottom: 40px;
    }

    .card {
        top: -110px;
    }
}

@media screen and (min-width: 1360px) {
    .cardSection span {
        font-size: var(--font-large);
        line-height: var(--line-large);
    }

    .card {
        top: -156px;
    }
}

@media screen and (min-width: 1680px) {
    .description {
        font-size: var(--font-medium);
        line-height: var(--line-medium);
    }
}
