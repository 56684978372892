.footer {
    background-color: #f9f9f9;
    position: relative;
    padding-top: 40px;
}

.container {
    padding-bottom: 40px !important;
}

.mainSectionsContainer {
    display: flex;
    flex-direction: column;
}

.allLinksSectionsContainer {
    display: flex;
    flex-direction: column;
}

.linksSectionsContainer {
    display: flex;
    flex-direction: row;
}

.footerHeaderContainer {
    max-width: 362px;
}

.companyLinksContainer {
    width: 92px;
    margin-right: 96px;
}

.rummoLinksContainer {
    width: 147px;
}

.logo {
    padding-bottom: 18px;
}

.logo div {
    max-width: 152px;
    max-height: 24px;
    height: 24px;
    width: 152px;
}

.footerDescription {
    font-size: var(--font-small);
    line-height: 21px;
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
    font-family: var(--font-family-secondary);
    margin-bottom: 40px;
}

.text {
    color: var(--color-grayish);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-family-secondary);
    font-size: var(--font-small);
    line-height: var(--line-xxlarge);
}

.subtitle {
    font-weight: var(--font-weight-semibold);
    font-family: var(--font-family-secondary);
    font-size: var(--font-medium);
    line-height: var(--line-medium);
    margin-bottom: var(--margin);
}

.linkContainer a:link {
    text-decoration: none;
}

.linkContainer a:last-child {
    padding-bottom: 0;
}

.linkContainer a:hover > span {
    color: var(--color-black);
}

.socialMedia {
    margin: 0 12px;
    margin-left: 0;
}

.contactsContainer {
    margin-top: 40px;
}

.contactsContainer > div:nth-child(2) {
    display: flex;
}

.contactsContainer > div {
    display: flex;
    align-items: flex-end;
}

.contactLink {
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--margin-small);
    font-size: var(--font-large);
    line-height: var(--line-large);
}

.contactsContainer .text {
    margin-bottom: 4px;
    font-size: var(--font-xsmall);
    color: var(--color-grayish);
    font-weight: var(--font-weight-light);
    font-family: var(--font-family-secondary);
    line-height: var(--line-xsmall);
}

.copyrights {
    background-color: var(--color-black);
    color: #fff;
    display: flex;
    justify-content: center;
    height: 48px;
    align-items: center;
}

div.linkContainer {
    flex-direction: column;
}

@media only screen and (min-width: 492px) {
    .companyLinksContainer {
        margin-right: 120px;
    }
}

@media only screen and (min-width: 692px) {
    .footerDescription {
        margin-bottom: 56px;
    }

    .footer {
        padding-top: 56px;
    }

    .container {
        padding-bottom: 56px !important;
    }

    .allLinksSectionsContainer {
        display: flex;
        flex-direction: row;
    }

    .companyLinksContainer {
        margin-right: 80px;
    }

    .rummoLinksContainer {
        margin-right: 64px;
    }

    .contactsContainer {
        margin-top: 0;
    }

    .contactsContainer .text {
        line-height: var(--line-xsmall);
        margin-bottom: var(--xs_space);
    }
}

@media (min-width: 928px) {
    .companyLinksContainer {
        margin-right: 160px;
    }

    .rummoLinksContainer {
        margin-right: 160px;
    }
}

@media only screen and (min-width: 1360px) {
    .mainSectionsContainer {
        flex-direction: row;
    }

    .footer {
        padding-top: 80px;
    }

    .container {
        padding-bottom: 80px !important;
    }

    .logo {
        padding-bottom: 26px;
    }

    .logo div {
        max-width: 180px;
        max-height: 30px;
        width: 180px;
        height: 30px;
    }

    .footerDescription {
        font-size: var(--font);
        line-height: var(--line);
        margin-bottom: 0;
    }

    .footerHeaderContainer {
        margin-right: 160px;
    }

    .companyLinksContainer {
        width: 102px;
        margin-right: 80px;
    }

    .rummoLinksContainer {
        width: 156px;
        margin-right: 64px;
    }

    .contactsContainer {
        margin-top: 0;
        width: 320px;
    }

    .copyrights {
        height: 80px;
    }

    .subtitle {
        font-size: var(--font-large);
        line-height: var(--line-large);
        font-weight: var(--font-weight-semibold);
    }
}

@media only screen and (min-width: 1680px) {
    .footerHeaderContainer {
        max-width: 480px;
    }

    .companyLinksContainer {
        width: 136px;
    }

    .rummoLinksContainer {
        width: 208px;
    }
}
