.arrowForward {
    width: 20px !important;
    height: 20px !important;
}

.arrowForward > path {
    stroke-width: 25px;
    stroke: var(--color-red) !important;
}

.mobileDatesSelection > div:first-child button {
    padding: 0;
    font-size: var(--font-small);
}

.mobileDatesSelection > div:first-child > div {
    justify-content: space-between;
}

.mobileDatesSelection {
    max-width: var(--page-max-width);
    width: var(--page-width);
    margin-left: auto;
    margin-right: auto;
}
