.title {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    margin-bottom: var(--margin-medium);
    white-space: nowrap;
}

.icon {
    align-self: center;
    width: 36px;
    height: 36px;
    margin-bottom: var(--margin-medium);
    margin-right: var(--margin-medium);
}

.info {
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
}

.info span {
    font-weight: var(--font-weight-medium);
}

.description {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    margin-bottom: var(--margin-large);
    max-width: 640px;
}

.buttonsRow {
    flex-direction: column !important;
    gap: 1rem;
}

.container {
    text-align: start;
    border-radius: 8px;
}

.navigateButton {
    font-size: 1rem !important;
    font-weight: var(--font-weight-regular) !important;
    width: 208px;
}

.profileButton {
    font-size: 1rem !important;
    font-weight: var(--font-weight-regular) !important;
    width: 208px;
}

@media screen and (min-width: 492px) {
    .title {
        font-size: var(--font-h5);
        line-height: 36px;
    }

    .buttonsRow {
        flex-direction: row !important;
        align-items: center;
        justify-content: start;
        margin-bottom: 1rem !important;
    }
}

@media screen and (min-width: 928px) {
    .title {
        font-size: var(--font-h4);
        line-height: 40px;
    }

    .description {
        font-size: var(--font);
        line-height: var(--line);
    }
}

@media screen and (min-width: 1680px) {
    .title {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
    }
}
