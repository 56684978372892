.loadingContainer {
    flex: 1;
    align-items: center;
    justify-content: center;
}

.loadingSpinner {
    width: 48px;
    height: 48px;
    color: var(--color-black);
}

.checkIcon {
    color: var(--color-red);
    margin-left: 8px;
}

.container {
    margin-left: 32px;
}

.container strong {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-medium);
}

.container input {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
    padding: 12px 18px;
    margin-top: 4px;
    background-color: transparent;
    border: 1px solid var(--color-gray-light-border);
}

.container > div {
    margin-bottom: 16px;
}

.link {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    font-family: var(--font-family-secondary);
    line-height: var(--line-small);
    text-decoration: underline;
    color: var(--color-red);
}

.avatar {
    width: 80px;
    height: 80px;
    margin: 0;
}

.editButtons {
    margin-top: 32px;
}

.cancelBtn:hover {
    box-shadow: none;
    filter: opacity(0.5);
}

@media only screen and (min-width: 928px) {
    .avatar {
        width: 96px;
        height: 96px;
    }
}

@media only screen and (min-width: 1360px) {
    .avatar {
        width: 160px;
        height: 160px;
    }
}
