.cardHeader {
    align-items: center;
    margin-bottom: 16px;
}

.cardHeader > span {
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    margin: 0;
}

.trashIcon {
    color: var(--color-red);
}

.trashIcon:hover {
    cursor: pointer;
}

.description {
    font-size: var(--font-small);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
}

.briefcaseIcon {
    width: 16px;
    height: 16px;
    color: var(--color-black);
    margin-right: 10px;
}

.accordionContainer {
    margin-top: var(--margin);
}

.accordionHeader {
    padding: 12px 0;
    border-bottom: 1px solid var(--color-gray-lighter);
}

.accountInfo {
    align-items: center;
    border-bottom: 1px solid var(--color-gray-lighter);
    font-weight: var(--font-weight-medium);
    padding-bottom: var(--margin-small);
}

.paymentMethodTitle::first-letter {
    text-transform: capitalize;
}

.paymentMethodTitle {
    align-items: center;
    margin-top: var(--small-extra_space);
    font-weight: var(--font-weight-medium);
}

.accountInfo span:not(:first-child) {
    margin-top: 4px;
    width: 296px;
}

.iconsContainer {
    align-items: center;
    justify-content: flex-end;
}

.icon {
    width: 24px;
    height: 24px;
    color: var(--color-red);
    margin-left: 12px;
}

.link {
    color: var(--color-red);
    margin: 12px 0;
    text-decoration: underline;
    font-size: var(--font-small);
    line-height: var(--line-small);
}

.mainBilling {
    margin: 12px 0 24px;
}

.billingData {
    font-weight: var(--font-weight-light) !important;
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
}

.mainBilling span {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xsmall);
    margin-bottom: var(--margin-internal);
    line-height: var(--line-xsmall);
}

.paymentProfile {
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-xsmall);
    margin-top: var(--margin-large);
}

.companyData {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    margin-bottom: var(--margin-internal);
    font-style: normal;
}
