.gridContainer {
    flex: unset !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 20px;
}

.splitter {
    border-color: rgba(0, 0, 0, 0);
}

.containerSpinner {
    margin: var(--margin-xlarge);
}

.spinnerText {
    color: var(--color-gray);
    text-align: center;
    margin-top: var(--margin);
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-light);
}

/* Loading spinner animation  */
.spinnerText::after {
    display: inline-block;
    animation: dots steps(1, end) 1s infinite;
    content: '';
    width: 0.55rem;
}

@keyframes dots {
    0% {
        content: '';
    }
    25% {
        content: '.';
    }
    50% {
        content: '..';
    }
    75% {
        content: '...';
    }
    100% {
        content: '';
    }
}

@media screen and (min-width: 928px) {
    .splitter {
        border-color: var(--color-gray-lighter);
        margin-top: 32px;
    }
}
