.infoContainer {
    align-items: center;
    margin-bottom: var(--margin-internal);
}

.infoContainer span {
    text-align: start;
}

.infoContainer:last-child {
    margin-bottom: 0;
}

.icon {
    display: none;
    width: 12px;
    height: 12px;
    margin-right: var(--margin-internal);
}

.column {
    margin-bottom: var(--margin-medium);
}

@media screen and (min-width: 692px) {
    .icon {
        display: block;
        width: 18px;
        height: 18px;
    }
}
