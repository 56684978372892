.drawer {
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    padding: var(--margin) var(--margin-medium) var(--margin-xlarge);
    justify-content: space-around;
    display: flex;
    z-index: 1;

    flex: 0;
    /* Prevent selection */
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* supported by Chrome, Edge, Opera and Firefox */

    /* White */

    background: #ffffff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
}

.sticky {
    position: absolute;
    width: 134px;
    height: 5px;
    left: calc(50% - 134px / 2);
    bottom: 8px;

    /* Rich Black - Main color */

    background: #0b0b0d;
    border-radius: 100px;
    cursor: pointer;
}

.modal {
    width: 100% !important;
    position: absolute;
    bottom: 0 !important;
    box-shadow: 0px 0px 16px rgba(11, 11, 13, 0.08);
    border-radius: 8px 8px 0px 0px;
    padding-bottom: 16px;
}

.closeIcon {
    align-self: baseline;
    margin-bottom: 1rem;
    cursor: pointer;
}

@media screen and (min-width: 492px) {
    .submitButton {
        width: 221px;
    }
}
