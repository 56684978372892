.link {
    font-family: var(--font-family-secondary);
    color: var(--color-gray-dark);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-caption);
    text-decoration: none;
    transition: opacity 0.2s;
}

.link:hover {
    opacity: 0.6;
}

.link:active {
    opacity: 0.8;
}
