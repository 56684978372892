.splitter {
    border-top-width: 1px;
    border-top-color: #0b0b0d;
    border-top-style: solid;
    margin-top: var(--margin);
    margin-bottom: var(--margin);
}

.item {
    margin-bottom: var(--margin);
}

.label {
    margin-right: 0 !important;
}

.label span:first-child {
    text-decoration: underline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding-right: 8px;
    text-align: start;
    max-width: 85%;
    font-weight: var(--font-weight-light);
}

.item div:last-child {
    flex: 0;
}

.collapse {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.info,
.titleInfo,
.label span:last-child {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
}

.label {
    color: var(--color-black);
}

.info {
    color: var(--color-grayish);
}

.titleInfo {
    margin-left: 4px;
    color: var(--color-grayish);
    display: none;
}

.titleContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.buttonLink > svg {
    width: 30px !important;
    height: 30px !important;
}

.priceBreakdownIcon {
    display: none;
}

@media screen and (min-width: 692px) {
    .label span:first-child {
        max-width: 188px;
    }

    .priceBreakdownIcon {
        display: block;
    }
}

@media screen and (min-width: 928px) {
    .info {
        margin-left: 4px;
    }

    .titleInfo {
        display: block;
    }
}
