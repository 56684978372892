.title {
    font-size: var(--font-large);
    line-height: var(--line-h5);
    font-weight: var(--font-weight-medium);
    text-align: start;
}

.light .title {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-medium);
    line-height: var(--line-medium);
}

.price {
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-semibold);
    margin-right: 5px;
    flex: 0 !important;
    text-align: start;
}

.info {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
    text-align: start;
}

.message {
    font-size: var(--font);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
    color: var(--color-gray);
    text-align: start;
}

.messageIcon {
    font-size: 1.2rem;
    font-weight: var(--font-weight-bold);
    color: var(--color-red);
}

@media screen and (min-width: 692px) {
    .price {
        font-size: var(--font-xxlarge);
        line-height: var(--line-xxlarge);
    }
}
