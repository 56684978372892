.title {
    margin: unset;
    margin-right: var(--margin-medium);
    font-size: var(--font-xxlarge);
    font-weight: var(--font-weight-semibold);
}
.subtitle {
    margin: unset;
    font-size: var(--font-medium);
}

.totalReviews {
    margin: unset;
    margin-left: var(--margin-small);
    color: var(--color-gray-light);
    font-size: var(--font-medium);
}
.starIcon {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 16px;
    width: 18px;
}
