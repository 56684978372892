.photo {
    flex: 0;
}

.photoInput {
    border: 1px solid lightgray;
    cursor: pointer;
}

.photoInput:focus {
    outline-width: 0px;
}

.photoInput:hover > span {
    text-decoration: underline;
}

.carousel :global(.carousel-slider),
.carousel :global(.slider),
.carousel :global(.slider-wrapper),
.carousel :global(.slider-wrapper .slider),
.carousel :global(.slider-wrapper .slider .slide) {
    height: 100%;
    background-color: transparent;
}

.actionIcon {
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: var(--radius);
    background: var(--color-white);
    margin-bottom: var(--margin-small);
    padding: var(--padding-small);
}

.actionIcon:hover {
    transform: scale(1.1);
}

.actionContainer {
    flex: 0 0 !important;
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    box-shadow: -2px -1px 10px -1px var(--color-gray-light);
    background: var(--color-primary);
    padding: var(--padding-small);
    max-height: 11.5rem;
}
