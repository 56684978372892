.confirmLocationButtonContainer {
    position: fixed;
    bottom: var(--normal-extra_space);
    right: 0;
    width: 100vw;
    padding: 0 var(--normal-extra_space);
}

.header {
    margin-top: var(--margin-small);
    margin-bottom: var(--margin-medium);
}

.header svg {
    height: 24px;
    width: 24px;
}

.mainText {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
}

.dropdown {
    margin-top: var(--margin-medium);
    margin-bottom: var(--margin-medium);
    max-width: unset;
}

.currentLocation {
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    width: 100%;
}

.currentLocationChevron {
    align-self: center;
}

.currentLocationContainer {
    margin-bottom: var(--margin-medium);
}

.description {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    color: var(--color-grayish);
}

.container {
    justify-content: space-between;
    height: 100%;
}

@media (min-width: 492px) {
    .confirmLocationButtonContainer {
        padding: 0;
    }
}

@media (min-width: 692px) {
    .dropdown {
        margin-bottom: var(--margin-large);
    }

    .currentLocation {
        margin-bottom: var(--margin);
    }

    .currentLocationContainer {
        margin-bottom: var(--margin-large);
    }

    .updateAddressButton {
        font-weight: var(--font-weight-medium);
        font-size: var(--font-small);
        line-height: var(--line-small);
        text-decoration: underline;
        color: var(--color-red);
    }
}
