.inputContainer {
    position: relative;
    display: flex;
    flex-direction: column;
}

div.inputContainer .caption:last-child {
    min-height: var(--font-size-caption);
    margin-bottom: var(--margin-internal);
}

div.inputContainer.error .caption:last-child {
    color: var(--color-red);
}

.inputContainer input {
    padding: 10px;
    background-color: #ffffff00;
    font-family: var(--font-family-secondary);
    font-weight: var(--font-weight-regular);
    transition: border 0.3s;
    -webkit-appearance: none;
}

div.inputContainer.error input {
    border: thin solid var(--color-red) !important;
}

div.inputContainer input::placeholder {
    color: var(--color-gray-light);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-family-secondary);
}

div.inputContainer input:disabled {
    color: var(--color-gray-light);
    cursor: not-allowed;
}

.input {
    white-space: nowrap;
    text-overflow: ellipsis;
}

div.inputContainer input:focus {
    outline: none;
}
