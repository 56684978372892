.carousel {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    margin-bottom: 90px !important;
}

.arrow {
    position: absolute;
    width: 44px;
    height: 44px;
    stroke: var(--color-black);
    stroke-width: 0.75px !important;
    cursor: pointer;
}

.arrowRight {
    right: 10px;
    bottom: -70px;
}

.arrowLeft {
    transform: rotate(180deg);
    left: 10px;
    bottom: -70px;
}
