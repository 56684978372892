.labelText {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-semibold);
    margin-right: var(--margin-internal) !important;
    flex: none !important;
}

.valueText {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
    display: flex;
    align-items: center;
}

.valueText svg {
    width: 16px;
    height: 16px;
    margin-left: var(--margin-internal);
}
