.sectionInfo {
    line-height: var(--line);
    font-size: var(--font-large);
    display: block;
    margin-bottom: 56px;
    color: var(--color-black);
    font-weight: var(--font-weight-light);
    margin-top: 16px;
}

.fakecard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    max-width: 232px;
    margin-left: 1rem;
}

.buttonBlack {
    height: 48px;
    width: 216px;
    border-radius: 88px;
    font-size: var(--font);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    font-weight: var(--font-weight-medium);
    background-color: var(--color-black);
    color: var(--color-white);
    text-decoration: none;
}

@media only screen and (min-width: 1360px) {
    .sectionTitle {
        font-size: 40px !important;
        line-height: 50px !important;
    }
}
