.inputContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--margin);
}

.inputContainer .caption:last-child {
    min-height: var(--font-size-caption);
    margin-bottom: var(--margin-internal);
}

.inputContainer.error .caption:last-child {
    color: var(--color-red);
}

.input {
    padding: var(--padding) var(--padding);
    border: thin solid transparent;
    background-color: var(--color-gray-lighter);
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-regular);
    border-radius: var(--radius);
    color: var(--color-gray-darker);
    margin-bottom: var(--margin-internal);
    transition: border 0.3s;
    box-sizing: border-box;
    width: 100%;
}

.input:hover {
    border-bottom: thin solid var(--color-gray-light);
}

.input.error {
    border: thin solid var(--color-red) !important;
}

.input::placeholder {
    color: var(--color-gray-light);
    font-weight: var(--font-weight-regular);
}

.input:disabled {
    background-color: var(--color-gray-lighter);
    border: thin solid var(--color-gray-lighter);
    color: var(--color-gray-light);
    cursor: not-allowed;
}

.input:focus {
    outline: none;
    border-bottom: thin solid var(--color-purple);
}
