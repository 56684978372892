.card {
    box-sizing: border-box;
    color: black;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 16px #0b0b0d14;
    border-radius: 16px;
    padding: 2rem;
    min-height: 472px;
}

.container {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.title {
    font-size: var(--font-xxxlarge);
    line-height: var(--line-xlarge);
    font-weight: var(--font-weight-semibold);
}

.description {
    display: block;
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: 16px;
    font-weight: var(--font-weight-light);
}

@media only screen and (min-width: 492px) {
    .card {
        min-height: 472px;
    }
}

@media only screen and (min-width: 692px) {
    .card {
        min-height: 472px;
    }
}

@media only screen and (min-width: 928px) {
    .card {
        min-height: 472px;
    }
}

@media only screen and (min-width: 1360px) {
    .card {
        min-height: 463px;
    }
}

@media only screen and (min-width: 1680px) {
    .card {
        min-height: 472px;
    }
}
