.container {
    position: relative;
    margin-bottom: 120px !important;
}

.stick {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.content {
    flex: unset !important;
    margin-bottom: var(--margin-xlarge);
    flex-direction: column !important;
    justify-content: space-between;
}

.contentDetails {
    flex: 1 !important;
    margin-right: 0 !important;
    max-width: 800px;
}

.backIcon {
    width: 24px;
    height: 24px;
    margin-bottom: var(--margin);
}

.pageTitle {
    font-size: var(--font-h4);
    line-height: 40px;
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--margin-large);
}

.sectionTitle {
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--margin-large);
}

.cancelationFirst {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}

.cancelationFirst span {
    font-weight: var(--font-weight-medium);
}

.cancelationLast {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
}

.clickableLink {
    color: var(--color-alice);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    text-decoration: underline;
    cursor: pointer;
    align-items: center;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: pre-wrap;
}

.clickableLinkRound {
    color: var(--color-alice);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    text-decoration: underline;
    cursor: pointer;
    align-items: center;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: pre-wrap;
    border: 1px solid #e2e3e7;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px 24px;
}

.bookingButton {
    max-width: 238px;
    max-height: 56px;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-large);
    line-height: var(--line-large);
}

.assetPanel {
    position: sticky;
    top: var(--margin-xlarge);
    padding: 0;
    width: 100%;
    box-shadow: none;
}

.addDriver :first-child {
    margin-right: 10px;
}

.panelContainer {
    max-width: 400px;
    flex: 1 !important;
    align-items: flex-start;
}

.bookingDetails {
    flex: 1 !important;
    align-items: flex-start;
}

.separator {
    border-bottom: 12px solid #f2f2f4;
    position: static;
    left: 0%;
    right: 0%;
    top: 16.53%;
    bottom: 82.92%;
    margin-left: -15rem;
    margin-right: -15rem;
}

@media screen and (min-width: 692px) {
    .content {
        flex-direction: row !important;
    }

    .panelContainer,
    .contentDetails {
        width: calc(50% - var(--margin-xlarge) / 2) !important;
    }
    .contentDetails {
        margin-right: var(--margin-xlarge) !important;
    }

    .assetPanel {
        box-shadow: rgb(11 11 13 / 8%) 2px 2px 16px;
        padding: var(--padding-large);
    }
}

@media screen and (min-width: 928px) {
    .panelContainer,
    .contentDetails {
        width: calc(50% - var(--margin-xxxlarge) / 2) !important;
    }

    .contentDetails {
        margin-right: var(--margin-xxxlarge) !important;
    }

    .separator {
        display: none;
    }
}

@media screen and (min-width: 1360px) {
    .contentDetails {
        margin-right: var(--margin-xxxxlarge) !important;
    }

    .bookingDetails {
        width: max-content;
    }

    .assetPanel {
        margin-top: -20rem;
    }
}
