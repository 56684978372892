.results {
    position: absolute;
    list-style-type: none;
    width: var(--page-width);
    max-width: var(--page-max-width);
    top: 55px;
    left: 24px;
    right: 24px;
    z-index: 10;
    padding: 0;
    margin: 0;
    background-color: var(--color-white);
    transition: height ease-in-out 250ms;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    overflow: scroll;
}

.results li {
    padding: 18px;
    font-family: var(--font-family-secondary);
    font-weight: var(--font-weight-regular);
    font-size: var(--font-xsmall);
    border-top: 1px solid var(--color-gray-lighter);
    cursor: pointer;
}

.results li:hover {
    background-color: var(--color-gray-lighter);
}

.results li:first-child {
    border: none;
}

.input {
    flex: 1;
}

.input input {
    padding: 0 !important;
    font-weight: var(--font-weight-light) !important;
}

.input input::placeholder {
    font-weight: var(--font-weight-light) !important;
}

.closeIcon {
    width: 10px;
    height: 10px;
    margin-left: 16px;
}

.input {
    width: 163px !important;
}

@media only screen and (min-width: 492px) {
    .results {
        position: absolute;
        top: 48px;
        background: var(--color-white);
        border-radius: var(--radius);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
        transition: height ease-in-out 250ms;
        overflow: scroll;
        margin: 0 auto;
        max-width: var(--page-max-width);
        width: 100%;
        left: 0;
    }
}
