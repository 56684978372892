.container {
    display: flex;
    margin: 1rem;
    font-size: 2rem;
    max-width: var(--page-max-width);
    width: var(--page-width);
    margin: 0 auto 120px auto;
}

.sectionHeader {
    max-width: 864px;
    margin: 20px 0 40px;
}

.sectionTitle {
    font-size: var(--font-xlarge);
    line-height: var(--line-xxlarge);
    font-weight: var(--font-weight-semibold);
    margin: 0;
}

.sectionDescription {
    font-size: var(--font);
    line-height: var(--line);
    margin-top: 16px;
    font-weight: var(--font-weight-light);
}

.title {
    margin-top: 16px;
    margin-bottom: 40px;
}

.title > span {
    font-weight: var(--font-weight-semibold) !important;
    font-size: var(--font-xxxlarge);
}

.container > .accordionContainer {
    flex-direction: column;
}

.accordionContainer > div {
    margin-right: 0 !important;
    width: 100%;
    max-width: 864px;
}

.faqAccordion {
    box-shadow: 0 16px 80px #00000014;
    border-radius: 8px;
    margin-bottom: 16px;
}

.faqAccordion strong {
    font-weight: var(--font-weight-medium);
}

.faqAccordion > header > span:first-child {
    font-size: var(--font);
}

.faqAccordion > header > span:last-child {
    box-sizing: border-box;
    padding-left: 32px;
}

.faqAccordion > section p {
    font-size: var(--font-small);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}

.faqAccordion > section p:not(:last-child) {
    margin-bottom: 12px;
}

.faqAccordion > section ol {
    padding: revert;
}

.faqAccordion > section ul {
    padding: revert;
}

.viewMore {
    border-radius: 48px;
    width: 206px;
    margin-top: 40px;
    font-size: var(--font);
    height: 44px;
}

@media screen and (min-width: 928px) {
    .pageTitle span {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
    }

    .sectionHeader {
        margin: 40px 0;
    }

    .sectionTitle {
        font-size: var(--font-h4);
        line-height: 40px;
    }

    .title > span {
        font-size: var(--font-h4);
    }

    .faqAccordion > header > span:first-child {
        font-size: var(--font-large);
    }

    .faqAccordion > section p {
        font-size: var(--font);
        line-height: var(--line);
    }

    .faqAccordion > section p:not(:last-child) {
        margin-bottom: 16px;
    }
}
