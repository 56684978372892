.cardHeader {
    align-items: center;
    justify-content: space-between;
}

.cardHeader > span {
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--margin-internal);
}

.description {
    font-size: var(--font-small);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
}

.buttonsContainer {
    margin-top: var(--margin-xlarge);
    box-sizing: border-box;
}

.buttonsContainer button {
    max-width: fit-content;
}

.buttonsContainer div:not(:first-child) {
    margin-top: var(--margin-medium);
}

.editDocumentButton {
    margin-bottom: var(--margin-medium);
}

.closeIcon {
    visibility: flex;
    cursor: pointer;
}

.editDocumentButton *:not(:first-child) {
    margin-left: 8px;
}

.editDocumentButton :last-child {
    margin-left: 26px;
}

.textNote {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
}

.redIcon {
    color: var(--color-red);
}

.plusIcon {
    width: 24px;
    height: 24px;
    color: var(--color-red);
    margin-right: 8px;
}

.loadingIcon {
    color: var(--color-red);
    margin-right: 8px;
    margin-left: 4px;
}
