@import url('../../../../styles/colors.css');

.text {
    composes: h1 from '../../../../styles/font.module.css';
    composes: internalMarginBottom from '../../../../styles/layout.module.css';
}

.h1 {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h4);
    line-height: 40px;
    margin: var(--margin-medium) 0;
}

@media screen and (min-width: 1360px) {
    .h1 {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
    }
}
