.container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    margin-bottom: var(--margin) !important;
}

.container.checkmarkRight {
    width: 100%;
}

.container.checkmarkLeft {
    padding-left: 35px;
}

.label {
    font-size: 1.25rem;
    font-weight: var(--font-weight-light);
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    height: 22px;
    width: 22px;
    border: 1px solid var(--color-gray-light);
    background-color: var(--color-white);
    border-radius: 3px;
}

.checkmarkLeft {
    top: 0;
    left: 0;
}

.checkmarkRight {
    top: 0;
    right: 0;
}

/* On mouse-over, add a grey background color */
.container:hover div {
    opacity: 0.6;
}

.container:not(.checked):hover .checkmark:after {
    display: block;
    opacity: 0.3;
    background-color: var(--color-white);
}

.container.checked span {
    font-weight: var(--font-weight-medium);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

.container.checked .checkmark {
    background-color: var(--color-red);
    border: 1px transparent;
}

/* Show the checkmark when checked */
.container.checked .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 6px;
    top: 0px;
    width: 8px;
    height: 15px;
    border: solid var(--color-white);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
