.container {
    margin-bottom: 120px !important;
}

.container div {
    margin-bottom: var(--margin-xxxlarge) !important;
}

.container div:last-child {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
}

@media (min-width: 928px) {
    .container {
        flex-direction: row !important;
    }

    .container div {
        margin-bottom: 0 !important;
        margin-right: var(--margin-xlarge) !important;
    }
}

@media (min-width: 1360px) {
    .container div {
        margin-right: var(--margin-xxxlarge) !important;
    }
}

@media (min-width: 1680px) {
    .container div {
        margin-right: 184px !important;
    }
}
