.title {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-h4);
    line-height: 40px;
    margin-bottom: var(--margin-medium);
}

.icon {
    align-self: center;
}

.description {
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: var(--margin-large);
}

.container {
    text-align: center;
    border-radius: 8px;
}
