.dataOrder {
    flex: 0 !important;
    margin-bottom: 16px !important;
}

.dataOrder div {
    margin-right: 4px !important;
}

@media only screen and (max-width: 520px) {
    .dataOrder {
        display: none !important;
    }
}

.sortBarContainer {
    position: relative;
    width: fit-content;
    flex: 0 !important;
    display: flex;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: nowrap;
}

.sortBar {
    border-radius: 20rem;
    text-align: center;
    padding: 10px 20px;
    border: 1px solid var(--color-gray-light);
    cursor: pointer;
    display: flex;
    flex: 1;
    justify-content: stretch;
    align-items: center;
}

.dropdown {
    position: absolute;
    z-index: -1;
    left: 0.005rem;
    background: var(--color-white);
    color: var(--color-gray-darker);
    opacity: 0;
    transition: flex ease-in-out 100ms;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    border-radius: 0.3rem;
    padding: 0 var(--padding-medium);
    margin-top: 12px;
}

.dropdownItem {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    font-size: var(--font-xsmall) !important;
}

.focus .dropdown {
    top: 45px;
    opacity: 1;
    z-index: 5;
    flex: 1;
}

.focus .sortBar {
    font-weight: var(--font-weight-semibold);
    border: 1px solid black;
}

.filteredBox {
    border: 1px solid black;
}

.filterIndicator {
    position: absolute;
    display: none;
    right: 0px;
    color: white;
    cursor: pointer;
    background-color: black;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    justify-content: center;
}

.filterIndicatorText {
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
}

.hasFilters {
    display: inline-block;
}

.text {
    font-weight: var(--font-weight-light);
    font-size: 15px;
    line-height: 22px;
}
