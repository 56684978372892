.container {
    position: relative;
    max-width: 100vw;
    width: 100%;
    padding: 0 var(--padding-medium);
}

.container > * {
    color: white;
}

.withBackgroundSub {
    margin-bottom: 140px;
    margin-top: 100px;
    max-width: 600px;
    font-size: var(--font-h4);
    line-height: var(--line-h4);
}

@media (max-width: 324px) {
    .withBackgroundSub {
        margin-bottom: 100px !important;
    }
}

@media (max-width: 692px) {
    .withBackgroundSub {
        font-size: var(--font-xxxlarge);
        margin-bottom: 70px;
    }
}

@media (max-width: 324px) {
    .withBackgroundSub {
        margin-bottom: 100px !important;
    }
}

@media (max-width: 692px) {
    .withBackgroundSub {
        font-size: var(--font-xxxlarge);
        margin-top: 70px;
        margin-bottom: 160px;
    }
}

.withBackground {
    margin-bottom: 300px;
}

@media (min-width: 492px) {
    .container {
        max-width: var(--page-width);
        padding: 0;
    }
}

@media (min-width: 692px) {
    .withBackgroundSub {
        max-width: 480px;
    }
}

@media (min-width: 928px) {
    .titleContainer {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media (min-width: 1360px) {
    .withBackground {
        margin-bottom: 440px;
    }
}

@media (min-width: 2560px) {
    .withBackground {
        margin-bottom: 550px;
    }
}
