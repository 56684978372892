.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-white);
    width: 100%;
    position: relative;
    margin-top: 80px;
    margin-bottom: 80px !important;
}

.section > div {
    position: relative;
}

.rectangleSm {
    position: absolute;
    top: 0px;
    left: -190px;
}

.rectangleLg {
    position: absolute;
    top: 480px;
    right: -120px;
    width: 220px;
    height: 220px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}

.content li {
    padding: 0.2rem 0;
}

.content > div {
    width: 100%;
}

.section section {
    overflow: hidden;
}

.button > span {
    font-weight: var(--font-weight-regular);
}

.button:hover > span {
    color: var(--color-white);
}

.titleContainer {
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    z-index: 10;
}

.titleContainer > span {
    text-align: center;
    margin: 0 0 64px 0;
}

.content > .faqAccordion {
    box-shadow: 0 16px 80px #00000014;
    border-radius: 8px;
}

.faqAccordion > header > span:first-child {
    font-size: var(--font);
    max-width: 80%;
}

.faqAccordion > section p {
    font-size: var(--font-small);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
}

.faqAccordion > section p:not(:last-child) {
    margin-bottom: 12px;
}

@media screen and (min-width: 692px) {
    .rectangleSm {
        left: -150px;
        top: -50px;
    }

    .rectangleLg {
        top: 450px;
        right: -20vw;
    }
}

@media screen and (min-width: 928px) {
    .section {
        margin-top: 220px;
        margin-bottom: 120px !important;
    }

    .faqAccordion > header > span:first-child {
        font-size: var(--font-medium);
    }

    .faqAccordion > section p {
        font-size: var(--font);
        line-height: var(--line);
        font-weight: var(--font-weight-light);
    }

    .faqAccordion > section p:not(:last-child) {
        margin-bottom: 12px;
    }

    .button {
        width: 204px;
    }

    .rectangleSm {
        left: -20vw;
        top: 200px;
    }

    .rectangleLg {
        top: 200px;
        right: -18vw;
        width: 300px;
        height: 300px;
    }
}

@media screen and (min-width: 1360px) {
    .section {
        margin-top: 229px;
    }

    .content {
        width: 1100px;
        margin: auto;
    }

    .rectangleLg {
        right: 0;
        right: calc(0px - 150px - ((100vw - 1280px) / 2));
        top: 200px;
    }

    .rectangleSm {
        left: calc(50% - 50vw - 50px);
        top: 200px;
    }
}

@media screen and (min-width: 1680px) {
    .titleContainer > span {
        margin: 0 0 80px 0;
    }
}
