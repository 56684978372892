div.logoContainer {
    height: var(--font-size-h2);
    width: 145px;
    background-image: url('../../assets/images/Logo_Full.svg');
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 145px;
    min-width: 145px;
}

div.logoContainer.medium {
    width: var(--font-size-h2);
}

div.logoContainer.large {
    width: var(--font-size-h1);
}
