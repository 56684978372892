.container {
    position: relative;
    margin-bottom: 120px !important;
}

.card {
    box-shadow: rgb(11 11 13 / 8%) 2px 2px 16px;
    border-radius: 8px;
    width: 400px;
}

.content {
    flex: unset !important;
    margin-bottom: var(--margin-xlarge);
    flex-direction: column !important;
    justify-content: space-between;
}

.contentDetails {
    flex: 1 !important;
    margin-right: 0 !important;
    max-width: 800px;
}

.backIcon {
    width: 24px;
    height: 24px;
    margin-bottom: var(--margin);
    cursor: pointer;
}

.pageTitle {
    font-size: var(--font-h4);
    line-height: 40px;
    font-weight: var(--font-weight-semibold);
    margin-bottom: 64px;
}

.panelContainer > div {
    position: sticky;
    top: var(--margin-xlarge);
    padding: 0;
    width: 100%;
    box-shadow: none;
}

@media screen and (min-width: 692px) {
    .content {
        flex-direction: row !important;
    }

    .panelContainer,
    .contentDetails {
        width: calc(50% - var(--margin-xlarge) / 2) !important;
    }

    .contentDetails {
        margin-right: var(--margin-xlarge) !important;
    }

    .panelContainer {
        max-width: 400px;
        flex: unset;
        width: 50%;
    }

    .panelContainer > div {
        /* padding vars in rem have no 24px - it's not a multiple of 14px */
        padding: 24px;
        box-shadow: rgb(11 11 13 / 8%) 2px 2px 16px;
    }
}

@media screen and (min-width: 928px) {
    .panelContainer,
    .contentDetails {
        width: calc(50% - var(--margin-xxxlarge) / 2) !important;
    }

    .panelContainer {
        flex: unset !important;
    }

    .contentDetails {
        margin-right: var(--margin-xxxlarge) !important;
    }
}

@media screen and (min-width: 1360px) {
    .contentDetails {
        margin-right: var(--margin-xxxxlarge) !important;
    }
}
