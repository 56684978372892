.card {
    box-shadow: rgb(11 11 13 / 8%) 2px 2px 16px;
    border-radius: 8px;
    padding: var(--padding-large);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: unset;
    z-index: 1;
}

.checkReceipt {
    background: var(--color-black);
    margin-bottom: 0;
}

.checkReceipt span {
    color: white;
}

.marginToPriceBreakdown {
    display: none;
}

.card.relative {
    position: relative;
    width: 100%;
    box-shadow: none;
    padding: 0;
}

.payProfile::first-letter {
    text-transform: capitalize;
}

.payProfile {
    margin-top: var(--margin);
    font-size: var(--font);
    font-weight: var(--font-weight-medium);
    line-height: var(--line);
}

.cardInfo {
    margin-top: var(--margin-internal);
    margin-bottom: var(--margin);
}

.cardInfoText {
    margin-left: var(--margin-internal);
    font-size: var(--font-xsmall) !important;
    font-weight: var(--font-weight-light);
}

.cardInfoText span {
    font-weight: var(--font-weight-medium);
}

@media only screen and (min-width: 692px) {
    .marginToPriceBreakdown {
        display: block;
    }

    .card {
        position: relative;
        width: 100%;
    }

    .submitButton {
        display: block;
    }

    .submitButton {
        margin-top: var(--margin-medium);
    }

    .buttonStack {
        align-items: flex-start !important;
        margin: 0;
    }

    .checkReceipt {
        margin-bottom: 0.5rem !important;
    }
}

@media only screen and (min-width: 928px) {
    .buttonStack {
        flex-direction: row !important;
        justify-content: space-around;
    }
}

.message {
    font-size: var(--font);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
    color: var(--color-gray);
    text-align: start;
    margin-bottom: var(--margin-medium);
}

.messageIcon {
    font-size: 1.2rem;
    font-weight: var(--font-weight-bold);
    color: var(--color-red);
}
