.container {
    display: flex;
    max-width: fit-content;
    align-content: center;
}

.label {
    font-weight: var(--font-weight-medium);
    white-space: nowrap;
    font-size: var(--font-xsmall);
    color: var(--color-red);
    line-height: var(--line-xsmall);
    margin-left: 0.5rem;
}

.pendingLabel {
    color: var(--color-grayish);
}

.completedLabel {
    color: var(--color-black);
}

.completedLabel path {
    stroke: var(--color-black);
}

.activeLabel {
    color: var(--color-red);
}

.failedLabel {
    color: var(--color-red);
}

.cancelledLabel {
    color: var(--color-black);
}
