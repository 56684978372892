.container {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.header {
    flex: 1;
    position: relative;
    max-height: 2rem;
    padding: 2rem 2rem;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-gray-light);
}

.closeIcon {
    position: absolute;
    right: 1rem;
}

.headerTitle {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-medium);
}

.content {
    flex: 1;
    padding: 1rem 1.5rem 8.875rem;
    overflow-y: scroll;
}

.actionRow {
    position: absolute;
    background: var(--color-white);
    left: 0;
    bottom: 0;
    right: 0;
    padding: 2rem 1.5rem;
    box-shadow: 0 1px 14px 0 rgb(0 0 0 /14%);
    justify-content: space-between;
    align-items: baseline;
}

.clearButton {
    flex: 0 !important;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-black);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    margin-left: 1rem;
}
