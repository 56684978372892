@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
.icon_icon__YkNOG {
    height: calc(var(--font-size-body) + var(--padding));
    align-self: center;
}

.spinner_spin__3Qv1E {
    -webkit-animation: spinner_icon-spin__2IiIA 1.5s infinite linear;
            animation: spinner_icon-spin__2IiIA 1.5s infinite linear;
    stroke: var(--color-green);
}

.spinner_spinBlack__3j8fM path {
    fill: var(--color-black);
}

.spinner_spinRed__2fc87 path {
    fill: var(--color-red);
}

.spinner_fullHeight__iuz6o {
    align-self: center;
    flex: 1 1;
}

.spinner_large__1XUrm {
    font-size: var(--font-xlarge) !important;
}

@-webkit-keyframes spinner_icon-spin__2IiIA {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}

@keyframes spinner_icon-spin__2IiIA {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
}

div.column_column__18h9e {
    display: flex;
    flex-direction: column;
}

div.column_margin__wQ0XN {
    margin-bottom: var(--margin-medium);
}

div.column_column__18h9e button:last-child {
    margin-bottom: 0;
}

div.column_row__3UGQ8,
div.column_dynamic__1mMEy {
    display: flex;
    flex-direction: row;
}

div.column_row__3UGQ8.column_end__1Dd-y {
    justify-content: flex-end;
}

div.column_row__3UGQ8 > div:not(:last-child),
div.column_row__3UGQ8 > button:not(:last-child),
div.column_dynamic__1mMEy > div:not(:last-child),
div.column_dynamic__1mMEy > button:not(:last-child) {
    margin-right: var(--margin-medium);
}

div.column_row__3UGQ8 div,
div.column_dynamic__1mMEy div {
    flex: 1 1;
}

@media only screen and (max-width: 400px) {
    div.column_dynamic__1mMEy {
        flex-direction: column;
    }

    div.column_dynamic__1mMEy > div:not(:last-child),
    div.column_dynamic__1mMEy > button:not(:last-child) {
        margin-right: 0;
    }

    div.column_dynamic__1mMEy div {
        flex: unset;
    }
}

.loading_container__8TIF7 {
    width: 100%;
    align-items: center;
    justify-content: center;
}

.loading_fullScreen__3T5Au {
    height: 100vh;
    margin-bottom: 50vh;
}

.loading_halfScreen__2o0pd {
    height: 50vh;
    margin-bottom: 50vh;
}

.emailVerificationModal_emailVerificationModal__vnFeV {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

div.row_row__2jEwN,
div.row_dynamic__Lcfsn {
    display: flex;
    flex-direction: row;
}

div.row_rowEnd__3rg4z {
    justify-content: flex-end;
}

div.row_row__2jEwN > div:not(:last-child),
div.row_row__2jEwN > button:not(:last-child),
div.row_dynamic__Lcfsn > div:not(:last-child),
div.row_dynamic__Lcfsn > button:not(:last-child) {
    margin-right: var(--margin-medium);
}

div.row_row__2jEwN > div,
div.row_dynamic__Lcfsn > div {
    flex: 1 1;
}

.text_text__1Fq9m {
    font-weight: var(--font-weight-regular);
    font-style: normal;
    font-size: var(--font-size-body);
    margin: 0 0 var(--margin) 0;
    color: var(--color-gray-darker);
    line-height: 1.6;
}

.text_noMargin__3qwCw {
    margin: 0 !important;
}

.text_center__1Q1Jt {
    text-align: center;
}

.modal_modal__340Md {
    position: absolute;
    top: 5%;
    left: 0px;
    right: 0px;
    bottom: 0px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    max-width: unset;
    width: unset;
    max-height: 95%;
    background-color: var(--color-white);
}

.modal_overlay__3-4XG {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 100;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
}

.modal_overlay__3-4XG.ReactModal__Overlay--after-open {
    opacity: 1;
}

.modal_overlay__3-4XG.ReactModal__Overlay--after-close {
    opacity: 0;
}

.modal_bottomFit__3-rob.ReactModal__Content {
    -webkit-transform: unset !important;
            transform: unset !important;
    top: unset !important;
    width: 100vw;
    min-height: 30%;
    position: absolute;
    bottom: -5rem;
    left: 0;
    right: 0;
    transition: bottom 0.3s;
}

.modal_bottomFit__3-rob.ReactModal__Content--after-open {
    bottom: 0;
}

.modal_childrenContainer__2vqm7 {
    padding: var(--margin-medium);
    width: auto;
}

.modal_headerAligner__2UzeH {
    flex: 1 1;
    justify-content: space-between;
}

.modal_bodyOpen__bBRHr {
    overflow: hidden;
}

.modal_htmlOpen__31OaD {
    overflow: hidden;
}

.modal_scrollLock__H39gn {
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
}

.modal_modal__340Md:focus {
    outline: none;
}

@media (min-width: 492px) {
    .modal_childrenContainer__2vqm7 {
        padding: var(--margin-large) var(--margin-large) var(--margin-xlarge);
    }
}

@media (min-width: 692px) {
    .modal_modal__340Md {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-height: 98%;
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: unset;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-transform: translate(-50%, -50%) !important;
                transform: translate(-50%, -50%) !important;
    }

    .modal_bottomFit__3-rob {
        top: unset !important;
    }

    .modal_modalSmall__1f7dW {
        width: 25rem;
    }

    .modal_modalMedium__tvXYB {
        width: 30rem;
    }

    .modal_modalLarge__309w7 {
        width: 35rem;
    }

    .modal_modalFit__1__Yo {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .modal_modal50__3SIGh {
        width: 50%;
    }
}

@media (min-width: 928px) {
    .modal_modal__340Md {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-height: 98%;
        position: absolute;
        top: 50%;
        left: 50%;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-transform: translate(-50%, -50%) !important;
                transform: translate(-50%, -50%) !important;
    }
}

.driverVerificationsModal_title__212Pq {
    font-size: var(--font-medium);
    line-height: var(--line);
    font-weight: bold;
    font-family: var(--font-family-secondary);
    margin-bottom: 24px;
}

.driverVerificationsModal_icon__3LsBr {
    align-self: center;
    width: 56px;
    height: 56px;
    margin-bottom: 24px;
    margin-top: 24px;
}

.driverVerificationsModal_description__17y8F {
    font-size: var(--font-small);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    font-family: var(--font-family-secondary);
    margin-bottom: 32px;
}

.driverVerificationsModal_buttonsRow__2u2bn {
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem !important;
}

.driverVerificationsModal_container__tAe4u {
    text-align: center;
    border-radius: 8px;
    width: 400px;
}

.driverVerificationsModal_navigateButton__27Mdf {
    font-size: 1rem !important;
    font-weight: var(--font-weight-regular) !important;
}

.driverVerificationsModal_profileButton__8A33M {
    font-size: 1rem !important;
    font-weight: var(--font-weight-regular) !important;
}

@media screen and (max-width: 691px) {
    .driverVerificationsModal_container__tAe4u {
        text-align: center;
        border-radius: 8px;
        max-width: 400px;
        width: calc(100% - 48px);
        margin: auto;
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
        bottom: unset !important;
        top: 20% !important;
    }
}

div.margin_margin__2VDyO {
    margin-bottom: var(--margin-medium);
    flex: 0 1 !important;

    -webkit-flex: unset !important;
    flex-shrink: unset !important;
}

.logoLarge_red__2Htmu path,
.logoLarge_red__2Htmu polygon {
    fill: var(--color-red);
}

.login_title__34IE- {
    align-items: center;
    font-size: var(--font-xxlarge);
    font-weight: 275;
}

.login_container__2g6aU {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login_header__2GgJM {
    margin-top: 0.5rem;
    margin-bottom: 3rem;
    align-items: center;
}

.login_logo__fBmEb {
    height: 23px;
}

.login_closeButton__1_Dyv {
    cursor: pointer;
}

.login_input__1yz5M {
    min-width: 320px;
    width: 100%;
}

.login_input__1yz5M input {
    background-color: white !important;
    border: 1px solid #e2e3e7 !important;
    border-radius: 4px !important;
}

.login_input__1yz5M span {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xsmall);
    color: #0b0b0d;
    text-align: left;
}

.login_registerLink__35tTP {
    color: #e14436;
}

.login_registerLabel__2qpAi {
    color: #9da0aa;
    text-align: center !important;
    margin: 0;
}

.login_register__2zAVA {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
}

@media screen and (min-width: 492px) {
    .login_container__2g6aU {
        text-align: center;
        border-radius: 8px;
        max-width: 400px;
        width: calc(100% - 48px);
        margin: auto;
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
        bottom: unset !important;
        top: 20% !important;
    }
}

.register_title__2p7ch {
    align-items: center;
    font-size: var(--font-xxlarge);
    font-weight: 275;
}

.register_container__qnEr2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.register_header__1lw07 {
    margin-top: 0.5rem;
    margin-bottom: 3rem;
    align-items: center;
}

.register_logo__LwSle {
    height: 23px;
}

.register_closeButton__1FRmu {
    cursor: pointer;
}

.register_input__Bxdja {
    min-width: 320px;
    width: 100%;
}

.register_input__Bxdja input {
    background-color: white !important;
    border: 1px solid #e2e3e7 !important;
    border-radius: 4px !important;
    text-align: left;
}

.register_input__Bxdja span {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xsmall);
    color: #0b0b0d;
}

.register_registerLink__26m2T {
    color: #e14436;
}

.register_registerLabel__1cY3H {
    color: #9da0aa;
    text-align: center !important;
    margin: 0;
}

.register_register__1Xh9C {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
}

@media screen and (min-width: 492px) {
    .register_container__qnEr2 {
        text-align: left;
        border-radius: 8px;
        max-width: 400px;
        width: calc(100% - 48px);
        margin: auto;
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
        bottom: unset !important;
        top: 20% !important;
    }
}

.error_error__1sSdS {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    color: var(--chakra-colors-brand-primary-900) !important;
    margin-top: var(--chakra-space-1);
}

.label_label__AvDuQ {
    font-weight: var(--font-weight-regular);
    font-style: normal;
    font-size: var(--font-size-caption);
    color: var(--color-gray);
    margin-bottom: var(--margin-internal);
}

.input_inputContainer__1_EMX {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: var(--margin);
}

.input_inputContainer__1_EMX .input_caption__9rz-O:last-child {
    min-height: var(--font-size-caption);
    margin-bottom: var(--margin-internal);
}

.input_inputContainer__1_EMX.input_error__3bkft .input_caption__9rz-O:last-child {
    color: var(--color-red);
}

.input_input__3faib {
    padding: var(--padding) var(--padding);
    border: thin solid transparent;
    background-color: var(--color-gray-lighter);
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-regular);
    border-radius: var(--radius);
    color: var(--color-gray-darker);
    margin-bottom: var(--margin-internal);
    transition: border 0.3s;
    box-sizing: border-box;
    width: 100%;
}

.input_input__3faib:hover {
    border-bottom: thin solid var(--color-gray-light);
}

.input_input__3faib.input_error__3bkft {
    border: thin solid var(--color-red) !important;
}

.input_input__3faib::-webkit-input-placeholder {
    color: var(--color-gray-light);
    font-weight: var(--font-weight-regular);
}

.input_input__3faib::placeholder {
    color: var(--color-gray-light);
    font-weight: var(--font-weight-regular);
}

.input_input__3faib:disabled {
    background-color: var(--color-gray-lighter);
    border: thin solid var(--color-gray-lighter);
    color: var(--color-gray-light);
    cursor: not-allowed;
}

.input_input__3faib:focus {
    outline: none;
    border-bottom: thin solid var(--color-purple);
}

.modal_modal__2RvZc {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: flex;
    flex-direction: column;
    max-height: 98%;
    top: 2%;
}

@media screen and (min-width: 492px) {
    .modal_modal__2RvZc {
        min-height: 50%;
        top: auto;
        bottom: 0;
    }
}

@media screen and (min-width: 692px) {
    .modal_modal__2RvZc {
        min-height: unset;
        top: 50%;
        bottom: auto;
        border-radius: 16px;
    }
}

.body_body__7RTGE {
    font-weight: var(--font-weight-regular);
    font-style: normal;
    font-size: var(--font-size-body);
    color: var(--color-gray-darker);
    line-height: 1;
}

.h3_h3__3iMLi {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-h3);
    margin: 0 0 var(--margin) 0;
    color: var(--color-gray-darker);
}

.driver-info_labelText__2EjdL {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-semibold);
    margin-right: var(--margin-internal) !important;
    flex: none !important;
}

.driver-info_valueText__3jRSR {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
    display: flex;
    align-items: center;
}

.driver-info_valueText__3jRSR svg {
    width: 16px;
    height: 16px;
    margin-left: var(--margin-internal);
}

.photo_photo__3jLDA {
    border-radius: var(--radius);
    object-fit: cover;
}

.photo_raised__1AM1P {
    box-shadow: 0 0 10px 0 var(--color-gray-light);
}

.photo_noImage__1Q3Hz {
    background-color: var(--color-gray-lighter);
    background-image: url(/static/media/Logo.cc3be875.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.5rem;
    opacity: 0.7;
}

.photo_photo__3jLDA.photo_mini__1mGQA {
    width: 5rem;
    height: 5rem;
    max-width: 5rem;
    max-height: 5rem;
    min-width: 5rem;
    min-height: 5rem;
}

.photo_photo__3jLDA.photo_small__34H2e {
    width: 7rem;
    height: 7rem;
    max-width: 7rem;
    max-height: 7rem;
    min-width: 7rem;
    min-height: 7rem;
}

.photo_photo__3jLDA.photo_medium__1J5zy {
    width: 11rem;
    height: 11rem;
    max-width: 11rem;
    max-height: 11rem;
    min-width: 11rem;
    min-height: 11rem;
}

.photo_photo__3jLDA.photo_large__18f8C {
    width: 14rem;
    height: 14rem;
    max-width: 14rem;
    max-height: 14rem;
    min-width: 14rem;
    min-height: 14rem;
}

.photo_photo__3jLDA.photo_xlarge__4mt6t {
    width: 20rem;
    height: 20em;
    max-width: 20rem;
    max-height: 20rem;
    min-width: 20rem;
    min-height: 20rem;
}

.photo_photo__3jLDA.photo_miniWide__7nf9F {
    width: 7rem;
    height: 5rem;
    max-width: 7rem;
    max-height: 5rem;
    min-width: 7rem;
    min-height: 5rem;
}

.photo_photo__3jLDA.photo_smallWide__13mcl {
    width: 10rem;
    height: 7rem;
    max-width: 10rem;
    max-height: 7rem;
    min-width: 10rem;
    min-height: 7rem;
}

.photo_photo__3jLDA.photo_mediumWide__2gL__ {
    width: 17rem;
    height: 11rem;
    max-width: 17rem;
    max-height: 11rem;
    min-width: 17rem;
    min-height: 11rem;
}

.photo_photo__3jLDA.photo_largeWide__16Xfk {
    width: 22rem;
    height: 12.5rem;
    max-width: 22rem;
    max-height: 12.5rem;
    min-width: 22rem;
    min-height: 12.5rem;
}

.photo_photo__3jLDA.photo_xlargeWide__2onjM {
    width: 50rem;
    height: 28.5rem;
    max-width: 50rem;
    max-height: 28.5rem;
    min-width: 50rem;
    min-height: 28.5rem;
}

.photo_photo__3jLDA.photo_relative__1GYBg {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .photo_photo__3jLDA.photo_xlargeWide__2onjM,
    .photo_photo__3jLDA.photo_xlarge__4mt6t {
        width: 90vw;
        max-width: 90vw;
        min-width: 90vw;
        min-height: 12.5rem;
        max-height: 12.5rem;
        height: 12.5rem;
    }

    .photo_photo__3jLDA.photo_smallWide__13mcl {
        width: 10rem;
        height: 7rem;
        max-width: 10rem;
        max-height: 7rem;
        min-width: 10rem;
        min-height: 7rem;
    }
}

.asset-hero_container__1cky7 {
    display: flex !important;
    flex-direction: row !important;
}

.asset-hero_image__2cv0j {
    width: 104px !important;
    height: 61px !important;
    margin-right: 20px;
}

.asset-hero_title__hEsLV {
    margin-bottom: 2px;
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-medium);
    display: flex;
    justify-content: flex-start;
}

.asset-hero_subtitle__3OBlt {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    color: var(--color-red);
    display: flex;
    justify-content: flex-start;
}

.card_card__188RG {
    background-color: #ffffff;
    border-radius: 40px;
    box-shadow: #00000020 0 25px 84px;
    overflow: hidden;
}

.splitter_splitter__3NNkZ {
    border-bottom: thin solid var(--color-gray-light-border);
    flex: 0 1 !important;
}

.splitter_noMargin__1oQUz {
    margin-bottom: 0 !important;
}

.splitter_small__1q_Q1 {
    margin-bottom: var(--margin-small) !important;
}

.splitter_normal__3FVyS {
    margin-bottom: var(--margin) !important;
}

.splitter_medium__2Cv5Z {
    margin-bottom: var(--margin-medium) !important;
}

.splitter_large__2lzvE {
    margin-bottom: var(--margin-large) !important;
}

.splitter_xlarge__3hr1G {
    margin-bottom: var(--margin-xlarge) !important;
}

.splitter_xxlarge__22DU1 {
    margin-bottom: var(--margin-xxlarge) !important;
}

.splitter_xxxlarge__QGGwQ {
    margin-bottom: var(--margin-xxxlarge) !important;
}

.splitter_xlspace__sUycl {
    margin-bottom: var(--margin-xl-space) !important;
}

.billing-info_labelText__34tLL {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-medium);
    margin-right: var(--margin-internal) !important;
    color: var(--color-black);
    margin-bottom: 4px;
    flex: none !important;
}

.billing-info_valueText__4DfYT {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
    color: var(--color-black);
    margin-bottom: 4px;
}

.billing-info_title__1qVqp {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-medium);
    line-height: var(--line-medium);
    margin-bottom: var(--margin);
    text-align: justify;
}

.billing-info_simple__36v9h .billing-info_labelText__34tLL {
    font-style: normal !important;
    font-weight: var(--font-weight-medium) !important;
    font-size: var(--font-xsmall) !important;
    color: black;
    margin-bottom: 0 !important;
}

.billing-info_simple__36v9h .billing-info_valueText__4DfYT {
    font-style: normal !important;
    font-weight: var(--font-weight-light) !important;
    font-size: var(--font-xsmall) !important;
    color: black;
    margin-bottom: 0 !important;
}

.billing-info_simple__36v9h .billing-info_title__1qVqp {
    margin-bottom: var(--margin-internal);
    font-size: var(--font) !important;
}

.booking-hero_title__2K_r7 {
    margin-bottom: 2px;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    display: flex;
    align-items: center;
}

.booking-hero_container__-37Vz {
    align-items: baseline;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

@media screen and (min-width: 928px) {
    .booking-hero_container__-37Vz {
        flex-direction: row !important;
        justify-content: space-between !important;
    }
}

.location-item_infoContainer__2t5-w {
    align-items: center;
    margin-bottom: var(--margin-internal);
}

.location-item_infoContainer__2t5-w span {
    text-align: start;
}

.location-item_infoContainer__2t5-w:last-child {
    margin-bottom: 0;
}

.location-item_icon__19WkV {
    display: none;
    width: 12px;
    height: 12px;
    margin-right: var(--margin-internal);
}

.location-item_column__31MCh {
    margin-bottom: var(--margin-medium);
}

@media screen and (min-width: 692px) {
    .location-item_icon__19WkV {
        display: block;
        width: 18px;
        height: 18px;
    }
}

.chev-up_chevUp__3lDGV {
    -webkit-transform: rotate(180deg) translate(0px, 1px);
            transform: rotate(180deg) translate(0px, 1px);
}

.price-breakdown_splitter__35mO3 {
    border-top-width: 1px;
    border-top-color: #0b0b0d;
    border-top-style: solid;
    margin-top: var(--margin);
    margin-bottom: var(--margin);
}

.price-breakdown_item__1ATv7 {
    margin-bottom: var(--margin);
}

.price-breakdown_label__1N4ng {
    margin-right: 0 !important;
}

.price-breakdown_label__1N4ng span:first-child {
    text-decoration: underline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    padding-right: 8px;
    text-align: start;
    max-width: 85%;
    font-weight: var(--font-weight-light);
}

.price-breakdown_item__1ATv7 div:last-child {
    flex: 0 1;
}

.price-breakdown_collapse__vGCGW {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1 1;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.price-breakdown_info__20v2Z,
.price-breakdown_titleInfo__34aeq,
.price-breakdown_label__1N4ng span:last-child {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
}

.price-breakdown_label__1N4ng {
    color: var(--color-black);
}

.price-breakdown_info__20v2Z {
    color: var(--color-grayish);
}

.price-breakdown_titleInfo__34aeq {
    margin-left: 4px;
    color: var(--color-grayish);
    display: none;
}

.price-breakdown_titleContainer__mzLWh {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.price-breakdown_buttonLink__3Rqx0 > svg {
    width: 30px !important;
    height: 30px !important;
}

.price-breakdown_priceBreakdownIcon__36SNQ {
    display: none;
}

@media screen and (min-width: 692px) {
    .price-breakdown_label__1N4ng span:first-child {
        max-width: 188px;
    }

    .price-breakdown_priceBreakdownIcon__36SNQ {
        display: block;
    }
}

@media screen and (min-width: 928px) {
    .price-breakdown_info__20v2Z {
        margin-left: 4px;
    }

    .price-breakdown_titleInfo__34aeq {
        display: block;
    }
}

.price-day_price__3Rk7x {
    font-size: var(--font-large);
    line-height: var(--line);
    font-weight: var(--font-weight-semibold);
    margin-right: 5px !important;
    flex: 0 1 !important;
}

.price-day_slash__3U-E2 {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-light);
    text-align: start;
}

.price-day_message__2d_Y8 {
    font-size: var(--font);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
    margin-right: var(--xs_space) !important;
    text-align: start;
}

.price-day_underline__3ZGQY {
    text-decoration: underline;
}

.price-day_infoIcon__2u5Yx {
    width: 20px;
    height: 20px;
    stroke-width: 1px;
    stroke: var(--color-grayish);
}

.price-day_totalContainer__2731V {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.price-day_priceDayContainer__2tLOp {
    min-width: -webkit-max-content;
    min-width: max-content;
}

@media screen and (min-width: 692px) {
    .price-day_price__3Rk7x {
        font-size: var(--font-h5);
        line-height: var(--line-xxlarge);
    }
}

.price-total_title__hRB9b {
    font-size: var(--font-large);
    line-height: var(--line-h5);
    font-weight: var(--font-weight-medium);
    text-align: start;
}

.price-total_light__SIbPN .price-total_title__hRB9b {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-medium);
    line-height: var(--line-medium);
}

.price-total_price__3Gqsp {
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-semibold);
    margin-right: 5px;
    flex: 0 1 !important;
    text-align: start;
}

.price-total_info__USWUA {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
    text-align: start;
}

.price-total_message__3MjC3 {
    font-size: var(--font);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
    color: var(--color-gray);
    text-align: start;
}

.price-total_messageIcon__2gtIT {
    font-size: 1.2rem;
    font-weight: var(--font-weight-bold);
    color: var(--color-red);
}

@media screen and (min-width: 692px) {
    .price-total_price__3Gqsp {
        font-size: var(--font-xxlarge);
        line-height: var(--line-xxlarge);
    }
}

.asset-panel_card__2fUfg {
    box-shadow: rgb(11 11 13 / 8%) 2px 2px 16px;
    border-radius: 8px;
    padding: var(--padding-large);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: unset;
    z-index: 1;
}

.asset-panel_checkReceipt__17F5x {
    background: var(--color-black);
    margin-bottom: 0;
}

.asset-panel_checkReceipt__17F5x span {
    color: white;
}

.asset-panel_marginToPriceBreakdown__2dIiz {
    display: none;
}

.asset-panel_card__2fUfg.asset-panel_relative__2DXsR {
    position: relative;
    width: 100%;
    box-shadow: none;
    padding: 0;
}

.asset-panel_payProfile__1Shz3::first-letter {
    text-transform: capitalize;
}

.asset-panel_payProfile__1Shz3 {
    margin-top: var(--margin);
    font-size: var(--font);
    font-weight: var(--font-weight-medium);
    line-height: var(--line);
}

.asset-panel_cardInfo__3kgnK {
    margin-top: var(--margin-internal);
    margin-bottom: var(--margin);
}

.asset-panel_cardInfoText__2pHS9 {
    margin-left: var(--margin-internal);
    font-size: var(--font-xsmall) !important;
    font-weight: var(--font-weight-light);
}

.asset-panel_cardInfoText__2pHS9 span {
    font-weight: var(--font-weight-medium);
}

@media only screen and (min-width: 692px) {
    .asset-panel_marginToPriceBreakdown__2dIiz {
        display: block;
    }

    .asset-panel_card__2fUfg {
        position: relative;
        width: 100%;
    }

    .asset-panel_submitButton__1qDcH {
        display: block;
    }

    .asset-panel_submitButton__1qDcH {
        margin-top: var(--margin-medium);
    }

    .asset-panel_buttonStack__5d0-T {
        align-items: flex-start !important;
        margin: 0;
    }

    .asset-panel_checkReceipt__17F5x {
        margin-bottom: 0.5rem !important;
    }
}

@media only screen and (min-width: 928px) {
    .asset-panel_buttonStack__5d0-T {
        flex-direction: row !important;
        justify-content: space-around;
    }
}

.asset-panel_message__4hw2l {
    font-size: var(--font);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
    color: var(--color-gray);
    text-align: start;
    margin-bottom: var(--margin-medium);
}

.asset-panel_messageIcon__yv_Cn {
    font-size: 1.2rem;
    font-weight: var(--font-weight-bold);
    color: var(--color-red);
}

.booking-success-hero_title__D9mmk {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    margin-bottom: var(--margin-medium);
    white-space: nowrap;
}

.booking-success-hero_icon__1ASH1 {
    align-self: center;
    width: 36px;
    height: 36px;
    margin-bottom: var(--margin-medium);
    margin-right: var(--margin-medium);
}

.booking-success-hero_info__16vm1 {
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
}

.booking-success-hero_info__16vm1 span {
    font-weight: var(--font-weight-medium);
}

.booking-success-hero_description__9ATap {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    margin-bottom: var(--margin-large);
    max-width: 640px;
}

.booking-success-hero_buttonsRow__1CA4R {
    flex-direction: column !important;
    grid-gap: 1rem;
    gap: 1rem;
}

.booking-success-hero_container__2b45C {
    text-align: start;
    border-radius: 8px;
}

.booking-success-hero_navigateButton__1HuPJ {
    font-size: 1rem !important;
    font-weight: var(--font-weight-regular) !important;
    width: 208px;
}

.booking-success-hero_profileButton__1-cMx {
    font-size: 1rem !important;
    font-weight: var(--font-weight-regular) !important;
    width: 208px;
}

@media screen and (min-width: 492px) {
    .booking-success-hero_title__D9mmk {
        font-size: var(--font-h5);
        line-height: 36px;
    }

    .booking-success-hero_buttonsRow__1CA4R {
        flex-direction: row !important;
        align-items: center;
        justify-content: start;
        margin-bottom: 1rem !important;
    }
}

@media screen and (min-width: 928px) {
    .booking-success-hero_title__D9mmk {
        font-size: var(--font-h4);
        line-height: 40px;
    }

    .booking-success-hero_description__9ATap {
        font-size: var(--font);
        line-height: var(--line);
    }
}

@media screen and (min-width: 1680px) {
    .booking-success-hero_title__D9mmk {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
    }
}

.assets-checkout-success_container__3rnMx {
    position: relative;
    margin-bottom: 120px !important;
}

.assets-checkout-success_stick__3rUGR {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.assets-checkout-success_content__3lf2n {
    flex: unset !important;
    margin-bottom: var(--margin-xlarge);
    flex-direction: column !important;
    justify-content: space-between;
}

.assets-checkout-success_contentDetails__2xikv {
    flex: 1 1 !important;
    margin-right: 0 !important;
    max-width: 800px;
}

.assets-checkout-success_backIcon__1v1rz {
    width: 24px;
    height: 24px;
    margin-bottom: var(--margin);
}

.assets-checkout-success_pageTitle__M7zzb {
    font-size: var(--font-h4);
    line-height: 40px;
    font-weight: var(--font-weight-semibold);
    margin-bottom: var(--margin-large);
}

.assets-checkout-success_sectionTitle__3_eT0 {
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--margin-large);
}

.assets-checkout-success_cancelationFirst__1KVq7 {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}

.assets-checkout-success_cancelationFirst__1KVq7 span {
    font-weight: var(--font-weight-medium);
}

.assets-checkout-success_cancelationLast__3rBsf {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
}

.assets-checkout-success_clickableLink__1psHp {
    color: var(--color-alice);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    text-decoration: underline;
    cursor: pointer;
    align-items: center;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: pre-wrap;
}

.assets-checkout-success_clickableLinkRound__ycVMd {
    color: var(--color-alice);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    text-decoration: underline;
    cursor: pointer;
    align-items: center;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: pre-wrap;
    border: 1px solid #e2e3e7;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px 24px;
}

.assets-checkout-success_bookingButton__2UEBf {
    max-width: 238px;
    max-height: 56px;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-large);
    line-height: var(--line-large);
}

.assets-checkout-success_assetPanel__1bV6a {
    position: -webkit-sticky;
    position: sticky;
    top: var(--margin-xlarge);
    padding: 0;
    width: 100%;
    box-shadow: none;
}

.assets-checkout-success_addDriver__1rxN2 :first-child {
    margin-right: 10px;
}

.assets-checkout-success_panelContainer__1MFVX {
    max-width: 400px;
    flex: 1 1 !important;
    align-items: flex-start;
}

.assets-checkout-success_bookingDetails__IhM4Z {
    flex: 1 1 !important;
    align-items: flex-start;
}

.assets-checkout-success_separator__17Be3 {
    border-bottom: 12px solid #f2f2f4;
    position: static;
    left: 0%;
    right: 0%;
    top: 16.53%;
    bottom: 82.92%;
    margin-left: -15rem;
    margin-right: -15rem;
}

@media screen and (min-width: 692px) {
    .assets-checkout-success_content__3lf2n {
        flex-direction: row !important;
    }

    .assets-checkout-success_panelContainer__1MFVX,
    .assets-checkout-success_contentDetails__2xikv {
        width: calc(50% - var(--margin-xlarge) / 2) !important;
    }
    .assets-checkout-success_contentDetails__2xikv {
        margin-right: var(--margin-xlarge) !important;
    }

    .assets-checkout-success_assetPanel__1bV6a {
        box-shadow: rgb(11 11 13 / 8%) 2px 2px 16px;
        padding: var(--padding-large);
    }
}

@media screen and (min-width: 928px) {
    .assets-checkout-success_panelContainer__1MFVX,
    .assets-checkout-success_contentDetails__2xikv {
        width: calc(50% - var(--margin-xxxlarge) / 2) !important;
    }

    .assets-checkout-success_contentDetails__2xikv {
        margin-right: var(--margin-xxxlarge) !important;
    }

    .assets-checkout-success_separator__17Be3 {
        display: none;
    }
}

@media screen and (min-width: 1360px) {
    .assets-checkout-success_contentDetails__2xikv {
        margin-right: var(--margin-xxxxlarge) !important;
    }

    .assets-checkout-success_bookingDetails__IhM4Z {
        width: -webkit-max-content;
        width: max-content;
    }

    .assets-checkout-success_assetPanel__1bV6a {
        margin-top: -20rem;
    }
}

.dateRangePicker_container__32D6g {
    position: relative;
}

.dateRangePicker_container__32D6g .SingleDatePickerInput,
.dateRangePicker_container__32D6g .SingleDatePicker,
.dateRangePicker_container__32D6g .SingleDatePicker > div:first-child,
.dateRangePicker_container__32D6g .DateInput input,
.dateRangePicker_container__32D6g .DateInput {
    height: 100%;
    display: flex;
    flex: 1 1;
}

.dateRangePicker_container__32D6g .DateRangePickerInput {
    border: none !important;
    outline: none;
    width: 100%;
    cursor: pointer;
    display: flex;
}

.dateRangePicker_container__32D6g .DateRangePickerInput_arrow {
    text-align: center;
    flex: 1 1;
    margin-right: var(--margin);
}

.dateRangePicker_container__32D6g .CalendarDay__selected {
    background-color: var(--color-gray-darker);
    border: none !important;
    outline: none;
}

.dateRangePicker_container__32D6g .DateInput_input {
    outline: none;
    border: none !important;
    width: 90px;
    position: absolute;
}

.dateRangePicker_container__32D6g .DateInput {
    width: 90px;
}

.dateRangePicker_container__32D6g .CalendarDay__blocked_out_of_range {
    text-decoration: line-through;
    background-color: transparent;
    color: var(--color-gray-datepicker);
}

.dateRangePicker_container__32D6g .CalendarDay__blocked_calendar {
    text-decoration: line-through;
    background-color: transparent;
    position: relative;
    color: var(--color-gray-datepicker);
}

.DateRangePicker_picker {
    z-index: 3;
    box-shadow: 0 0 24px 0 #0b0b0d1f;
    border-radius: 8px;
}

.DayPicker_portal__vertical {
    top: 80px;
    position: relative;
}

.DayPickerNavigation__verticalDefault {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 80%;
}

.DayPickerNavigation_button__verticalDefault {
    width: calc(var(--page-max-width) / 2);
    padding: 0;
}

#endDate {
    visibility: hidden;
}

.dateRangePicker_container__32D6g .dateRangePicker_placeholder__3_xdp {
    font-family: var(--font-family-secondary);
    font-size: var(--font-small);
    color: gray;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1 !important;
    background: var(--color-white);
    cursor: pointer;
    margin-right: 0 !important;
    vertical-align: middle !important;
}

.dateRangePicker_container__32D6g .dateRangePicker_renderDate__2EFD3 {
    font-family: var(--font-family-secondary);
    font-size: var(--font-small);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1 !important;
    background: var(--color-white);
    cursor: pointer;
    margin-right: 0 !important;
    display: flex;
    align-items: center;
}

.dateRangePicker_container__32D6g,
.dateRangePicker_container__32D6g .dateRangePicker_renderDate__2EFD3:focus-visible {
    outline: none;
}

.dateRangePicker_division__1wn57 {
    margin: 0 16px !important;
    width: 1px;
}

.dateRangePicker_dateContainer__3us5N {
    display: flex;
}

.dateRangePicker_renderDate__2EFD3 .dateRangePicker_clearButton__7urbO {
    flex: 0 1;
    white-space: nowrap;
    cursor: pointer;
}

.dateRangePicker_clearButton__7urbO:hover {
    opacity: 0.7;
}

@media (min-width: 928px) {
    .CalendarMonthGrid {
        left: 0;
    }

    .CalendarMonth_caption {
        color: var(--color-gray-darker);
        font-size: var(--font);
    }

    .CalendarMonth_caption > strong {
        font-weight: var(--font-weight-medium);
    }

    .DayPicker_weekHeader {
        padding-right: 2px !important;
        padding-left: 2px !important;
    }

    .DayPicker_weekHeader_li {
        font-size: var(--font);
        line-height: var(--line);
        color: var(--color-grayish);
    }

    .CalendarMonth_table {
        margin-top: 20px;
    }

    .CalendarDay {
        border: 0;
    }

    .CalendarDay:hover {
        border: 0;
    }

    .CalendarDay__selected_span {
        background-color: #00000000 !important;
        color: var(--color-gray-darker);
        font-weight: var(--font-weight-medium);
        position: relative;
        padding: 0;
    }

    .CalendarDay__selected_span:hover {
        background-color: #00000000;
        color: var(--color-gray-darker);
        border: 0;
    }

    .CalendarDay__selected_start span,
    .CalendarDay__selected_end span {
        color: #fff;
        font-weight: var(--font-weight-medium);
    }

    .CalendarDay__hovered_span {
        background-color: var(--color-gray-lighter);
        color: var(--color-gray-darker);
    }

    .CalendarDay__hovered_span:hover {
        background-color: var(--color-gray-darker);
        color: var(--color-white);
        border: 0;
        border-radius: 50%;
    }

    .CalendarDay__hovered_span:hover > span {
        background-color: var(--color-gray-darker);
        color: var(--color-white);
        border: 0;
        border-radius: 50%;
    }

    .CalendarDay__hovered_span:hover > .dateRangePicker_layer__lZD-r {
        background-color: var(--color-gray-lighter);
        width: 15px;
        right: 0;
    }

    .CalendarDay__selected_start,
    .CalendarDay__selected_end {
        position: relative;
        padding: 0;
    }

    .CalendarDay__selected_start > .dateRangePicker_layer__lZD-r,
    .CalendarDay__selected_end > .dateRangePicker_layer__lZD-r {
        background-color: var(--color-gray-lighter);
        width: 15px;
        height: 30px;
        top: 4px;
        position: absolute;
        z-index: 0;
    }

    .CalendarDay__selected_start > .dateRangePicker_layer__lZD-r {
        right: 0;
    }

    .CalendarDay__selected_end > .dateRangePicker_layer__lZD-r {
        left: 0;
    }

    .CalendarDay__selected_start,
    .CalendarDay__selected_end {
        border-radius: 50%;
    }

    .CalendarDay__selected_start > span,
    .CalendarDay__selected_end > span {
        width: 100%;
        height: 100%;
        background-color: #000;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
    }

    .CalendarDay__selected_span > .dateRangePicker_layer__lZD-r {
        background-color: var(--color-gray-lighter);
        width: 39px;
        height: 30px;
        top: 4px;
        left: 0;
        position: absolute;
        z-index: 0;
    }

    .CalendarDay__selected_span > span {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .CalendarDay__hovered_span {
        background-color: #00000000;
        position: relative;
    }

    .CalendarDay__hovered_span > .dateRangePicker_layer__lZD-r {
        width: 100%;
        height: 30px;
        background-color: var(--color-gray-lighter);
        position: absolute;
        top: 4px;
        left: 0;
    }

    .CalendarDay__hovered_span > span {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .CalendarDay__selected_start.CalendarDay__selected_end > .dateRangePicker_layer__lZD-r {
        width: 0;
    }

    .dateRangePicker_next__pUtNZ {
        position: absolute;
        right: 24px;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        top: 18px;
        padding: 4px;
    }

    .dateRangePicker_prev__3TtXC {
        position: absolute;
        left: 10px;
        top: 18px;
        padding: 4px;
    }

    .dateRangePicker_next__pUtNZ > svg > path,
    .dateRangePicker_prev__3TtXC > svg > path {
        stroke: black;
    }
}

.division_division__2u1kD {
    display: inline-block;
    max-width: 1px;
    background-color: var(--color-gray-light);
    margin: 0px !important;
    height: 24px;
}

.dateRangeDisplay_container__2vFla {
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #e2e3e7;
    border-radius: 56px;
}

.dateRangeDisplay_noBorder__1Doli {
    border: 0;
    padding: 0;
    padding: 0 var(--margin);
}

.scrollableDateRange_container__3U1_Z .DayPicker_weekHeader {
    padding: 0 !important;
    border-bottom: 1px solid #dbdbdb;
}

.scrollableDateRange_container__3U1_Z .DayPicker_weekHeader_li small {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-regular);
    color: var(--color-black);
}

.scrollableDateRange_container__3U1_Z .CalendarMonthGrid {
    width: 100% !important;
    max-width: 444px;
}

.scrollableDateRange_container__3U1_Z .CalendarMonth_table {
    width: 100%;
    max-width: 444px;
}

.scrollableDateRange_container__3U1_Z .DayPicker_weekHeader_ul {
    display: grid;
    grid-auto-flow: column;
    justify-items: center;
    max-width: 444px;
    height: 40px;
    margin: auto;
}

.scrollableDateRange_container__3U1_Z .DayPicker_transitionContainer {
    padding-top: var(--margin-large);
    padding-bottom: 400px;
}

.scrollableDateRange_container__3U1_Z .CalendarMonth_caption {
    text-align: start;
    padding-top: var(--margin-large);
    padding-bottom: var(--margin);
}

.scrollableDateRange_container__3U1_Z .CalendarMonth_caption strong {
    font-size: var(--font);
    font-weight: var(--font-weight-medium);
    line-height: var(--line);
}

.scrollableDateRange_container__3U1_Z .CalendarDay__default {
    border: none;
    font-size: var(--font);
    font-weight: var(--font-weight-light);
    line-height: var(--line);
    height: 40px !important;
}

.scrollableDateRange_container__3U1_Z .CalendarDay__selected {
    background: none !important;
    padding: 0;
}

.scrollableDateRange_container__3U1_Z .CalendarDay__selected_start .scrollableDateRange_outerBackground__1n_4V {
    background: var(--color-gray-lighter);
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
}

.scrollableDateRange_container__3U1_Z .CalendarDay__selected_end .scrollableDateRange_outerBackground__1n_4V {
    background: var(--color-gray-lighter);
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
}

.scrollableDateRange_container__3U1_Z .CalendarDay__selected .scrollableDateRange_outerBackground__1n_4V .scrollableDateRange_round__fh24c {
    background: var(--color-black);
    font-weight: var(--font-weight-medium);
    border-radius: 100%;
    height: 40px !important;
    width: 40px !important;
    display: inline-block;
    padding: var(--margin-internal);
}

.scrollableDateRange_container__3U1_Z .CalendarDay__selected_span {
    background: var(--color-gray-lighter);
    color: var(--color-black);
    padding: var(--margin-internal);
}

/* Hotfix, should appear at the bottom of months */
.scrollableDateRange_container__3U1_Z .DayPickerNavigation {
    display: none;
}

.scrollableDateRange_container__3U1_Z .CalendarDay__blocked_out_of_range {
    text-decoration: line-through;
    background-color: transparent;
    color: var(--color-gray-datepicker);
}

.scrollableDateRange_container__3U1_Z .CalendarDay__blocked_calendar {
    text-decoration: line-through;
    background-color: transparent;
    position: relative;
    color: var(--color-gray-datepicker);
}

.textV2_text__KABe- {
    font-weight: var(--font-weight-regular);
    font-style: normal;
    font-size: var(--font-size-body);
    color: var(--color-gray-darker);
}

/* Font Sizes */
.textV2_center__N6Wct {
    text-align: center;
}

.textV2_h1__3cQi7 {
    font-size: var(--font-h3);
    line-height: var(--line-h3);
}

.textV2_h2__3MiY5 {
    font-size: var(--font-h4);
    line-height: 40px;
}

.textV2_h3__1OaDZ {
    font-size: var(--font-h5);
    line-height: 36px;
}

.textV2_h4__2lwOV {
    font-size: var(--font-xxlarge);
    line-height: var(--line-xxlarge);
}

.textV2_h5__2LEQF {
    font-size: var(--font-large);
    line-height: var(--line-large);
}

.textV2_md__3SZul {
    font-size: var(--font);
    line-height: var(--line);
}

.textV2_sm__3GRqB {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
}

/* Colors */
.textV2_grayish__-hAZ2 {
    color: var(--color-gray-light);
}

.textV2_gray-light__JP7OJ {
    color: var(--color-gray-light);
}

/* Font Weights */
.textV2_bold__7_gsx {
    font-weight: var(--font-weight-bold);
}

.textV2_semibold__5lHZ0 {
    font-weight: var(--font-weight-semibold);
}

.textV2_medium__1Mj_r {
    font-weight: var(--font-weight-medium);
}

.textV2_regular__2BxUB {
    font-weight: var(--font-weight-regular);
}

.textV2_light__2zEt- {
    font-weight: var(--font-weight-light);
}

.pageContainer_container__3UMFZ {
    max-width: var(--page-max-width);
    width: var(--page-width);

    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
}

.date-time_container__2B0ZX {
    height: 100vh;
    display: grid;
    grid-template-rows: -webkit-min-content 100%;
    grid-template-rows: min-content 100%;
}

.date-time_header__1RSaV {
    margin-top: 9px;
    margin-bottom: var(--margin-medium);
}

.date-time_header__1RSaV svg {
    height: 24px;
    width: 24px;
}

.date-time_headerContainer__2xf75 {
    margin-bottom: var(--margin-medium);
}

.date-time_arrowBack__10oh7 {
    align-self: center;
    margin-right: var(--margin-small);
}

.date-time_headerTitle__2KZzJ {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    align-self: center;
}

.date-time_footer__GWbgE {
    box-shadow: 0px -2px 16px 0px #0b0b0d14;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100vw;
    padding-top: var(--margin-medium) !important;
    padding-bottom: var(--normal-extra_space) !important;
    background-color: var(--color-white);
}

.date-time_dropdownTitle__3anEk {
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: var(--margin-small);
}

.date-time_dateRange__1TL8Q > div {
    max-width: 444px;
    margin: auto;
}

.date-time_inputsContainer__3HIhJ {
    margin-bottom: var(--margin-medium);
    max-width: 260px;
}

.date-time_disabled__UIlvp {
    color: var(--color-gray-light);
}

.date-time_timeDropdown__1onqf {
    height: 0 !important;
    width: 100% !important;
    margin: 0;
}

.date-time_timeDropdown__1onqf input {
    opacity: 0;
    width: 100% !important;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.date-time_timeDropdownInput__1HmPl {
    justify-content: space-between;
    background: var(--color-white);
    padding: 0;
    margin: 0;
    padding-bottom: var(--margin-internal);
    border-bottom: 1px solid #e2e3e7;
    -webkit-appearance: none;
}

.date-time_timeDropdownInput__1HmPl span {
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
}

.date-time_timeDropdownInputDisabled__22xzG {
    background: var(--color-white);
    padding: 0;
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
    padding-bottom: var(--margin-internal);
    border: unset;
    border-bottom: 1px solid #e2e3e7;
}

.date-time_timeDropdownInputDisabled__22xzG span {
    color: var(--color-gray-light);
}

.date-time_timeDropdownInputDisabled__22xzG svg path {
    color: var(--color-gray-light);
}

.date-time_headerClearBtn__48NW1 {
    margin-bottom: var(--margin-medium);
    height: 48px;
}

@media (min-width: 692px) {
    .date-time_header__1RSaV {
        justify-content: center;
    }

    .date-time_headerTitle__2KZzJ {
        margin: auto;
    }
}

.dates-display_arrowForward__1NlVO {
    width: 20px !important;
    height: 20px !important;
}

.dates-display_arrowForward__1NlVO > path {
    stroke-width: 25px;
    stroke: var(--color-red) !important;
}

.dates-display_mobileDatesSelection__77w4b > div:first-child button {
    padding: 0;
    font-size: var(--font-small);
}

.dates-display_mobileDatesSelection__77w4b > div:first-child > div {
    justify-content: space-between;
}

.dates-display_mobileDatesSelection__77w4b {
    max-width: var(--page-max-width);
    width: var(--page-width);
    margin-left: auto;
    margin-right: auto;
}

.input_inputContainer__3A3wF {
    position: relative;
    display: flex;
    flex-direction: column;
}

div.input_inputContainer__3A3wF .input_caption__bK8f6:last-child {
    min-height: var(--font-size-caption);
    margin-bottom: var(--margin-internal);
}

div.input_inputContainer__3A3wF.input_error__DVOJR .input_caption__bK8f6:last-child {
    color: var(--color-red);
}

.input_inputContainer__3A3wF input {
    padding: 10px;
    background-color: #ffffff00;
    font-family: var(--font-family-secondary);
    font-weight: var(--font-weight-regular);
    transition: border 0.3s;
    -webkit-appearance: none;
}

div.input_inputContainer__3A3wF.input_error__DVOJR input {
    border: thin solid var(--color-red) !important;
}

div.input_inputContainer__3A3wF input::-webkit-input-placeholder {
    color: var(--color-gray-light);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-family-secondary);
}

div.input_inputContainer__3A3wF input::placeholder {
    color: var(--color-gray-light);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-family-secondary);
}

div.input_inputContainer__3A3wF input:disabled {
    color: var(--color-gray-light);
    cursor: not-allowed;
}

.input_input__CBN7v {
    white-space: nowrap;
    text-overflow: ellipsis;
}

div.input_inputContainer__3A3wF input:focus {
    outline: none;
}

.autocomplete_results__2WcCv {
    position: absolute;
    list-style-type: none;
    width: var(--page-width);
    max-width: var(--page-max-width);
    top: 55px;
    left: 24px;
    right: 24px;
    z-index: 10;
    padding: 0;
    margin: 0;
    background-color: var(--color-white);
    transition: height ease-in-out 250ms;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    overflow: scroll;
}

.autocomplete_results__2WcCv li {
    padding: 18px;
    font-family: var(--font-family-secondary);
    font-weight: var(--font-weight-regular);
    font-size: var(--font-xsmall);
    border-top: 1px solid var(--color-gray-lighter);
    cursor: pointer;
}

.autocomplete_results__2WcCv li:hover {
    background-color: var(--color-gray-lighter);
}

.autocomplete_results__2WcCv li:first-child {
    border: none;
}

.autocomplete_input__E67Ny {
    flex: 1 1;
}

.autocomplete_input__E67Ny input {
    padding: 0 !important;
    font-weight: var(--font-weight-light) !important;
}

.autocomplete_input__E67Ny input::-webkit-input-placeholder {
    font-weight: var(--font-weight-light) !important;
}

.autocomplete_input__E67Ny input::placeholder {
    font-weight: var(--font-weight-light) !important;
}

.autocomplete_closeIcon__2HXd8 {
    width: 10px;
    height: 10px;
    margin-left: 16px;
}

.autocomplete_input__E67Ny {
    width: 163px !important;
}

@media only screen and (min-width: 492px) {
    .autocomplete_results__2WcCv {
        position: absolute;
        top: 48px;
        background: var(--color-white);
        border-radius: var(--radius);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
        transition: height ease-in-out 250ms;
        overflow: scroll;
        margin: 0 auto;
        max-width: var(--page-max-width);
        width: 100%;
        left: 0;
    }
}

.location_searchInput__rBMA5 div input {
    box-sizing: border-box;
    border-color: #e2e3e7;
    padding: var(--margin-small) var(--margin) !important;
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    margin: 0;
}

.location_searchInput__rBMA5 div {
    margin: 0;
}

.location_searchHeader__E62P8 {
    margin-bottom: var(--margin);
}

.location_arrowBack__1r169 {
    align-self: center;
    margin-right: var(--margin-small);
    min-width: 24px;
    min-height: 24px;
    height: 24px;
    width: 24px;
}

.location_noResultsText__DYZv4 {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    color: var(--color-grayish);
}

.location_noResultsText__DYZv4 strong {
    font-weight: var(--font-weight-medium);
}

.location_resultItem__6ld9G {
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: var(--margin) 0;
    border-bottom: 1px solid var(--color-gray-lighter);
}

.location_resultItem__6ld9G:last-child {
    border-bottom: none;
}

.location_searchResultTitleHighlight__2EAdr {
    font-weight: var(--font-weight-medium);
    background-color: white;
    padding: unset !important;
}

.location_centerSpinner__vuWGz {
    display: flex;
    justify-content: center;
}

.location_addressComplete__3PGbN {
    border: 1px solid var(--color-gray-light-border);
    border-radius: 56px;
    box-sizing: border-box;
}

.location-delivery-display_arrowForward__3Kqa4 {
    width: 20px !important;
    height: 20px !important;
}

.location-delivery-display_arrowForward__3Kqa4 > path {
    stroke-width: 25px;
    stroke: var(--color-red) !important;
}

.location-delivery-display_searchSection__1QoQt {
    max-width: var(--page-max-width);
    width: var(--page-width);

    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
}

.location-return-picker_arrowForward__21HHC {
    width: 20px !important;
    height: 20px !important;
}

.location-return-picker_arrowForward__21HHC > path {
    stroke-width: 25px;
    stroke: var(--color-red) !important;
}

.location-return-picker_searchSection__3iOoC {
    max-width: var(--page-max-width);
    width: var(--page-width);

    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
}

.dropdownV2_chevDownIcon__1CDJb {
    width: 20px;
    height: 20px;
}

.dropdownV2_rotateChevUp__UfVwA {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.dropdownV2_rotateChevDown__4LA5p {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.dropdownV2_select__2xQ2u {
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: var(--margin);
    max-width: 400px;
}

.dropdownV2_select__2xQ2u .select__control {
    list-style: none;
    margin: 0;
    border-radius: 48px;
    padding: 12px 24px;
    box-shadow: none;
    background-color: var(--color-white);
    font-size: inherit;
}

.dropdownV2_select__2xQ2u .select__control--is-focused {
    border: 1px solid var(--color-gray-shadow);
}

.dropdownV2_select__2xQ2u .select__control--menu-is-open {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}

.dropdownV2_select__2xQ2u .select__value-container,
.dropdownV2_select__2xQ2u .select__placeholder {
    font-size: inherit !important;
    margin-right: 16px;
}

.dropdownV2_select__2xQ2u .select__control:hover {
    cursor: pointer;
    border-color: var(--color-gray-shadow);
}

.dropdownV2_select__2xQ2u .select__indicator-separator {
    display: none;
}

.dropdownV2_select__2xQ2u .select__indicator {
    color: var(--color-black);
    padding: 0;
}

.dropdownV2_select__2xQ2u .select__indicators {
    justify-content: flex-end;
    flex-grow: 0;
}

.dropdownV2_select__2xQ2u .select__menu {
    border: 1px solid var(--color-gray-shadow);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    box-shadow: none;
    overflow: hidden;
    margin-top: 0;
}

.dropdownV2_select__2xQ2u .select__menu-list {
    padding: 0;
}

.dropdownV2_select__2xQ2u .select__option {
    padding: 12px 32px;
    color: var(--color-gray-dark);
    font-size: inherit;
    border: thin solid transparent;
    transition: background-color 0.2s, color 0.2s, font-weight 0.2s;
    font-weight: var(--font-weight-light);
}

.dropdownV2_select__2xQ2u .select__option--is-focused {
    cursor: pointer;
    color: var(--color-gray-dark);
    background-color: var(--color-gray-lighter);
    font-weight: var(--font-weight-medium);
}

.dropdownV2_select__2xQ2u .select__option--is-selected {
    color: var(--color-gray-dark);
    background-color: var(--color-gray-lighter);
    font-weight: var(--font-weight-medium);
}

.address-confirmation_confirmLocationButtonContainer__1FAMG {
    position: fixed;
    bottom: var(--normal-extra_space);
    right: 0;
    width: 100vw;
    padding: 0 var(--normal-extra_space);
}

.address-confirmation_header__3q9fI {
    margin-top: var(--margin-small);
    margin-bottom: var(--margin-medium);
}

.address-confirmation_header__3q9fI svg {
    height: 24px;
    width: 24px;
}

.address-confirmation_mainText__3Rr0U {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
}

.address-confirmation_dropdown__ks8Kq {
    margin-top: var(--margin-medium);
    margin-bottom: var(--margin-medium);
    max-width: unset;
}

.address-confirmation_currentLocation__2ywMj {
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    width: 100%;
}

.address-confirmation_currentLocationChevron__hZhlo {
    align-self: center;
}

.address-confirmation_currentLocationContainer__1T3bO {
    margin-bottom: var(--margin-medium);
}

.address-confirmation_description__37_PJ {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    color: var(--color-grayish);
}

.address-confirmation_container__1gqr3 {
    justify-content: space-between;
    height: 100%;
}

@media (min-width: 492px) {
    .address-confirmation_confirmLocationButtonContainer__1FAMG {
        padding: 0;
    }
}

@media (min-width: 692px) {
    .address-confirmation_dropdown__ks8Kq {
        margin-bottom: var(--margin-large);
    }

    .address-confirmation_currentLocation__2ywMj {
        margin-bottom: var(--margin);
    }

    .address-confirmation_currentLocationContainer__1T3bO {
        margin-bottom: var(--margin-large);
    }

    .address-confirmation_updateAddressButton__3XSOZ {
        font-weight: var(--font-weight-medium);
        font-size: var(--font-small);
        line-height: var(--line-small);
        text-decoration: underline;
        color: var(--color-red);
    }
}

.search-mobile_pageContainer__1V7Xc {
    padding-top: var(--margin);
}

.search-mobile_pageContainer__1V7Xc > div {
    height: 100%;
}

.route_container__3kWJL {
    flex: 1 1;
}

.footer_footer__2UL7n {
    background-color: #f9f9f9;
    position: relative;
    padding-top: 40px;
}

.footer_container__1ojXG {
    padding-bottom: 40px !important;
}

.footer_mainSectionsContainer__cmmyw {
    display: flex;
    flex-direction: column;
}

.footer_allLinksSectionsContainer__1LBBx {
    display: flex;
    flex-direction: column;
}

.footer_linksSectionsContainer__v3Qrs {
    display: flex;
    flex-direction: row;
}

.footer_footerHeaderContainer__3aLSk {
    max-width: 362px;
}

.footer_companyLinksContainer__1qBg0 {
    width: 92px;
    margin-right: 96px;
}

.footer_rummoLinksContainer__2IMtZ {
    width: 147px;
}

.footer_logo__3w6C6 {
    padding-bottom: 18px;
}

.footer_logo__3w6C6 div {
    max-width: 152px;
    max-height: 24px;
    height: 24px;
    width: 152px;
}

.footer_footerDescription__1v7yE {
    font-size: var(--font-small);
    line-height: 21px;
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
    font-family: var(--font-family-secondary);
    margin-bottom: 40px;
}

.footer_text__1jsEj {
    color: var(--color-grayish);
    font-weight: var(--font-weight-regular);
    font-family: var(--font-family-secondary);
    font-size: var(--font-small);
    line-height: var(--line-xxlarge);
}

.footer_subtitle__1b19J {
    font-weight: var(--font-weight-semibold);
    font-family: var(--font-family-secondary);
    font-size: var(--font-medium);
    line-height: var(--line-medium);
    margin-bottom: var(--margin);
}

.footer_linkContainer__3vW8h a:link {
    text-decoration: none;
}

.footer_linkContainer__3vW8h a:last-child {
    padding-bottom: 0;
}

.footer_linkContainer__3vW8h a:hover > span {
    color: var(--color-black);
}

.footer_socialMedia__2LD3Z {
    margin: 0 12px;
    margin-left: 0;
}

.footer_contactsContainer__AWz8A {
    margin-top: 40px;
}

.footer_contactsContainer__AWz8A > div:nth-child(2) {
    display: flex;
}

.footer_contactsContainer__AWz8A > div {
    display: flex;
    align-items: flex-end;
}

.footer_contactLink__2LxuH {
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--margin-small);
    font-size: var(--font-large);
    line-height: var(--line-large);
}

.footer_contactsContainer__AWz8A .footer_text__1jsEj {
    margin-bottom: 4px;
    font-size: var(--font-xsmall);
    color: var(--color-grayish);
    font-weight: var(--font-weight-light);
    font-family: var(--font-family-secondary);
    line-height: var(--line-xsmall);
}

.footer_copyrights__1DcQ4 {
    background-color: var(--color-black);
    color: #fff;
    display: flex;
    justify-content: center;
    height: 48px;
    align-items: center;
}

div.footer_linkContainer__3vW8h {
    flex-direction: column;
}

@media only screen and (min-width: 492px) {
    .footer_companyLinksContainer__1qBg0 {
        margin-right: 120px;
    }
}

@media only screen and (min-width: 692px) {
    .footer_footerDescription__1v7yE {
        margin-bottom: 56px;
    }

    .footer_footer__2UL7n {
        padding-top: 56px;
    }

    .footer_container__1ojXG {
        padding-bottom: 56px !important;
    }

    .footer_allLinksSectionsContainer__1LBBx {
        display: flex;
        flex-direction: row;
    }

    .footer_companyLinksContainer__1qBg0 {
        margin-right: 80px;
    }

    .footer_rummoLinksContainer__2IMtZ {
        margin-right: 64px;
    }

    .footer_contactsContainer__AWz8A {
        margin-top: 0;
    }

    .footer_contactsContainer__AWz8A .footer_text__1jsEj {
        line-height: var(--line-xsmall);
        margin-bottom: var(--xs_space);
    }
}

@media (min-width: 928px) {
    .footer_companyLinksContainer__1qBg0 {
        margin-right: 160px;
    }

    .footer_rummoLinksContainer__2IMtZ {
        margin-right: 160px;
    }
}

@media only screen and (min-width: 1360px) {
    .footer_mainSectionsContainer__cmmyw {
        flex-direction: row;
    }

    .footer_footer__2UL7n {
        padding-top: 80px;
    }

    .footer_container__1ojXG {
        padding-bottom: 80px !important;
    }

    .footer_logo__3w6C6 {
        padding-bottom: 26px;
    }

    .footer_logo__3w6C6 div {
        max-width: 180px;
        max-height: 30px;
        width: 180px;
        height: 30px;
    }

    .footer_footerDescription__1v7yE {
        font-size: var(--font);
        line-height: var(--line);
        margin-bottom: 0;
    }

    .footer_footerHeaderContainer__3aLSk {
        margin-right: 160px;
    }

    .footer_companyLinksContainer__1qBg0 {
        width: 102px;
        margin-right: 80px;
    }

    .footer_rummoLinksContainer__2IMtZ {
        width: 156px;
        margin-right: 64px;
    }

    .footer_contactsContainer__AWz8A {
        margin-top: 0;
        width: 320px;
    }

    .footer_copyrights__1DcQ4 {
        height: 80px;
    }

    .footer_subtitle__1b19J {
        font-size: var(--font-large);
        line-height: var(--line-large);
        font-weight: var(--font-weight-semibold);
    }
}

@media only screen and (min-width: 1680px) {
    .footer_footerHeaderContainer__3aLSk {
        max-width: 480px;
    }

    .footer_companyLinksContainer__1qBg0 {
        width: 136px;
    }

    .footer_rummoLinksContainer__2IMtZ {
        width: 208px;
    }
}

div.logo_logoContainer__25FCn {
    height: var(--font-size-h2);
    width: 145px;
    background-image: url(/static/media/Logo_Full.3922d4b9.svg);
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 145px;
    min-width: 145px;
}

div.logo_logoContainer__25FCn.logo_medium__2E0s7 {
    width: var(--font-size-h2);
}

div.logo_logoContainer__25FCn.logo_large__3--K0 {
    width: var(--font-size-h1);
}

.item_item__1_CWC {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: var(--margin-medium);
}

.item_icon__21fR4 {
    margin: 0 var(--margin);
    display: flex;
    align-items: center;
}

.item_icon__21fR4 svg {
    height: 1.3rem;
    width: 1.3rem;
}

.item_icon__21fR4 svg path {
    stroke: var(--color-gray-dark);
    fill: var(--color-gray-dark);
}

.item_children__3rZ6M {
    font-weight: var(--font-weight-regular);
    font-size: var(--font);
    line-height: var(--line);
    text-align: left;
}

.item_childrenMobile__2sECU {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
}

.avatar_avatar__1siPc {
    height: 7rem;
    width: 7rem;
    object-fit: cover;
    object-position: top;
    border-color: var(--color-gray);
    border-style: solid;
    border-width: thin;
    margin-bottom: var(--margin-medium);
    margin-right: var(--margin-medium);
    border-radius: 50%;
}

div.profileRef_container__1Rw2b {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-items: center;
    margin-bottom: var(--margin-large) !important;
    align-items: flex-start;
}

.profileRef_avatar__1W-A6 {
    height: 4rem;
    width: 4rem;
    margin-bottom: 0;
}

.menu_overlay__2zs8K {
    background-color: var(--color-gray-dark);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s;
    margin-right: 0 !important;
}

.menu_overlay__2zs8K.menu_open__i5XNT {
    z-index: 14;
    opacity: 0.6;
    position: fixed;
}

.menu_container__2Uvsj {
    --sidebarWidth: 250px;
    --padding: var(--padding-medium);

    background-color: var(--color-white);
    position: fixed;
    top: 0;
    left: calc(-1 * var(--sidebarWidth) - (var(--padding) * 2));
    bottom: 0;
    width: var(--sidebarWidth);
    z-index: 15;
    transition: left 0.3s;

    padding: var(--padding);
}

.menu_container__2Uvsj.menu_open__i5XNT {
    left: 0;
    box-shadow: 2px 2px 10px var(--color-gray-dark);
}

@media only screen and (max-width: 500px) {
    .menu_container__2Uvsj {
        --sidebarWidth: 75%;
        --padding: var(--padding-medium);
    }

    .menu_container__2Uvsj.menu_open__i5XNT {
        left: 0;
        box-shadow: 2px 2px 10px var(--color-gray-dark);
    }
}

.link_left__2CaUN {
    justify-content: flex-start;
    align-items: center;
    flex-basis: 70% !important;
}

.link_left__2CaUN .link_mobile__s06E1 {
    display: none;
}

.link_left__2CaUN .link_desktop__wacR2 {
    display: flex;
}

.link_linksContainer__3GINi {
    justify-content: flex-start;
    align-items: center;
    display: none;
}

.link_logo__G3Qcj {
    margin-right: 40px !important;
    display: flex;
    align-items: center;
    flex: 0 1 !important;
    cursor: pointer;
    width: auto;
}

.link_logo__G3Qcj > svg {
    max-width: 100px;
    max-height: 16px;
}

.link_right__2IbR8 {
    display: none;
    justify-content: flex-end;
    flex-basis: 30% !important;
    align-items: center;
}

.link_text__2Oelv {
    margin-right: 40px;
    font-size: var(--font-xsmall) !important;
    line-height: var(--line-xsmall);
    font-family: var(--font-family-secondary) !important;
    font-weight: var(--font-weight-light) !important;
    margin-bottom: 0px;
    letter-spacing: 0.5px;
}

@media only screen and (min-width: 320px) {
    .link_left__2CaUN {
        flex-basis: 20% !important;
    }

    .link_left__2CaUN .link_mobile__s06E1 {
        display: block;
    }

    .link_left__2CaUN .link_desktop__wacR2 {
        display: none;
    }

    .link_right__2IbR8 {
        flex-basis: 80% !important;
    }
}

@media screen and (min-width: 691px) {
    .link_left__2CaUN {
        flex-basis: 50% !important;
    }

    .link_left__2CaUN .link_mobile__s06E1 {
        display: block;
    }

    .link_left__2CaUN .link_desktop__wacR2 {
        display: none;
    }

    .link_right__2IbR8 {
        flex-basis: 50% !important;
    }
}

@media screen and (min-width: 928px) {
    .link_left__2CaUN {
        flex-basis: 75% !important;
        margin-right: 0 !important;
    }

    .link_left__2CaUN .link_mobile__s06E1 {
        display: none;
    }

    .link_left__2CaUN .link_desktop__wacR2 {
        display: flex;
    }

    .link_right__2IbR8 {
        flex-basis: 25% !important;
    }

    .link_linksContainer__3GINi {
        display: flex;
    }

    .link_menu__3dvcV {
        display: none !important;
    }

    .link_white__32kXm {
        color: #fff;
    }

    .link_headerLogoPages__6qVmI {
        margin-right: 40px !important;
    }
}

@media screen and (min-width: 1360px) {
    .link_logo__G3Qcj > svg {
        max-width: 140px;
        max-height: 24px;
    }
}

.about_container__2Owq9 {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.fabController_optionsContainer__Unree {
    position: fixed !important;
    bottom: 1rem !important;
    right: 0 !important;
    left: 0 !important;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2;
    bottom: 2.7rem !important;

    flex: 0 1;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
}

.fabController_bottomOptionsWrapper__HG4xT {
    justify-content: stretch;
    align-items: stretch;
    background-color: var(--color-gray-darker);
    white-space: nowrap;
    border-radius: 10rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.fabController_bottomOptionsButton__csRDK {
    min-width: 4.5rem;
    padding: var(--padding) var(--padding-medium);
    color: white;
    margin-right: 0 !important;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
}

.fabController_bottomOptionsIcon__-khVz {
    width: 1.8rem;
    height: 1.8rem;
    min-width: 20px;
    min-height: 20px;
}

.fabController_bottomOptionsButton__csRDK:active {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    opacity: 0.7;
}

.fabController_bottomOptionsSeparator__24aw0 {
    width: 0.5px;
    background-color: white;
    flex: 0 1 auto !important;
    margin-right: 0 !important;
}

.fabController_fixed__3ko43 {
    bottom: 1rem !important;
    position: -webkit-sticky !important;
    position: sticky !important;
}

.fabController_indicator__Bs5hI {
    color: white;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
}

.fabController_indicatorBox__1OcK8 {
    background-color: var(--color-red);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    left: 5px;
    position: relative;
    padding: 0px 4px;
    display: flex;
    justify-content: center;
}

.pagination_container__3aOAO {
    display: flex;
}

.pagination_list__17C_R {
    display: flex;
    list-style: none;
    align-items: center;
    cursor: pointer;
}

.pagination_pageLink__21hgB {
    font-size: var(--font-size-body);
}

.pagination_imgSize__1_9SM {
    height: 1rem;
}

.pagination_list__17C_R li {
    margin-right: var(--padding-medium);
    height: 32px;
    width: 32px;
    justify-content: center;
    align-content: center;
    display: flex;
    border-radius: 20rem;
}

.pagination_list__17C_R li:not(.pagination_active__2iSQV):hover {
    background: rgba(0, 0, 0, 0.08);
}

.pagination_list__17C_R li a {
    align-self: center;
    display: flex;
    outline: 0 !important;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.pagination_active__2iSQV:not(a) {
    background: var(--color-gray-darker);
    color: white;
    font-weight: var(--font-weight-semibold);
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px !important;
    transition: box-shadow 0.2s ease 0s, -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important;
}

.pagination_active__2iSQV a {
    color: white;
}

li.next {
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
    background-color: rgba(255, 255, 255, 0.9) !important;
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px !important;
    transition: box-shadow 0.2s ease 0s, -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important;
}
li.next:hover {
    background-color: var(--color-white);
    color: var(--color-gray-darker) !important;
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 6px 16px !important;
    -webkit-transform: scale(1.04) !important;
            transform: scale(1.04) !important;
}

li.next a {
    align-self: center;
    outline: 0 !important;
}

li.previous {
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
    background-color: rgba(255, 255, 255, 0.9) !important;
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.18) 0px 2px 4px !important;
    transition: box-shadow 0.2s ease 0s, -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s !important;
}
li.previous:hover {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(0, 0, 0) !important;
    box-shadow: transparent 0px 0px 0px 1px, transparent 0px 0px 0px 4px, rgba(0, 0, 0, 0.12) 0px 6px 16px !important;
    -webkit-transform: scale(1.04) !important;
            transform: scale(1.04) !important;
}

li.previous a {
    align-self: center;
    outline: 0 !important;
}

.pagination_disabled__2uJQb {
    display: none !important;
}

.checkbox_container__2QjV3 {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    margin-bottom: var(--margin) !important;
}

.checkbox_container__2QjV3.checkbox_checkmarkRight__2lkfL {
    width: 100%;
}

.checkbox_container__2QjV3.checkbox_checkmarkLeft__1Pq-5 {
    padding-left: 35px;
}

.checkbox_label__voe-H {
    font-size: 1.25rem;
    font-weight: var(--font-weight-light);
}

/* Hide the browser's default checkbox */
.checkbox_container__2QjV3 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkbox_checkmark__2HGY5 {
    position: absolute;
    height: 22px;
    width: 22px;
    border: 1px solid var(--color-gray-light);
    background-color: var(--color-white);
    border-radius: 3px;
}

.checkbox_checkmarkLeft__1Pq-5 {
    top: 0;
    left: 0;
}

.checkbox_checkmarkRight__2lkfL {
    top: 0;
    right: 0;
}

/* On mouse-over, add a grey background color */
.checkbox_container__2QjV3:hover div {
    opacity: 0.6;
}

.checkbox_container__2QjV3:not(.checkbox_checked__gafFW):hover .checkbox_checkmark__2HGY5:after {
    display: block;
    opacity: 0.3;
    background-color: var(--color-white);
}

.checkbox_container__2QjV3.checkbox_checked__gafFW span {
    font-weight: var(--font-weight-medium);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox_checkmark__2HGY5:after {
    content: '';
    position: absolute;
    display: none;
}

.checkbox_container__2QjV3.checkbox_checked__gafFW .checkbox_checkmark__2HGY5 {
    background-color: var(--color-red);
    border: 1px transparent;
}

/* Show the checkmark when checked */
.checkbox_container__2QjV3.checkbox_checked__gafFW .checkbox_checkmark__2HGY5:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox_container__2QjV3 .checkbox_checkmark__2HGY5:after {
    left: 6px;
    top: 0px;
    width: 8px;
    height: 15px;
    border: solid var(--color-white);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.filter-order_dataOrder__2kx46 {
    flex: 0 1 !important;
    margin-bottom: 16px !important;
}

.filter-order_dataOrder__2kx46 div {
    margin-right: 4px !important;
}

@media only screen and (max-width: 520px) {
    .filter-order_dataOrder__2kx46 {
        display: none !important;
    }
}

.filter-order_sortBarContainer__20jU3 {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    flex: 0 1 !important;
    display: flex;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: nowrap;
}

.filter-order_sortBar__3CodP {
    border-radius: 20rem;
    text-align: center;
    padding: 10px 20px;
    border: 1px solid var(--color-gray-light);
    cursor: pointer;
    display: flex;
    flex: 1 1;
    justify-content: stretch;
    align-items: center;
}

.filter-order_dropdown__2I-mA {
    position: absolute;
    z-index: -1;
    left: 0.005rem;
    background: var(--color-white);
    color: var(--color-gray-darker);
    opacity: 0;
    transition: flex ease-in-out 100ms;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    border-radius: 0.3rem;
    padding: 0 var(--padding-medium);
    margin-top: 12px;
}

.filter-order_dropdownItem__1n5eM {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    font-size: var(--font-xsmall) !important;
}

.filter-order_focus__1HKUK .filter-order_dropdown__2I-mA {
    top: 45px;
    opacity: 1;
    z-index: 5;
    flex: 1 1;
}

.filter-order_focus__1HKUK .filter-order_sortBar__3CodP {
    font-weight: var(--font-weight-semibold);
    border: 1px solid black;
}

.filter-order_filteredBox__32BAQ {
    border: 1px solid black;
}

.filter-order_filterIndicator__28_Y0 {
    position: absolute;
    display: none;
    right: 0px;
    color: white;
    cursor: pointer;
    background-color: black;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    justify-content: center;
}

.filter-order_filterIndicatorText__34h3h {
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
}

.filter-order_hasFilters__2ti6l {
    display: inline-block;
}

.filter-order_text__VU_hC {
    font-weight: var(--font-weight-light);
    font-size: 15px;
    line-height: 22px;
}

.grid_dynamic__3rDwH {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 768px) {
    .grid_dynamic__3rDwH {
        display: flex;
        flex-direction: column;
    }
}

.radio_container__3G23O {
    flex: 0 1 !important;
    margin: var(--margin) 0;

    -webkit-flex: unset !important;
    flex-shrink: 0 !important;
}

.radio_option__1q4fV {
    flex: 0 1 !important;
    line-height: 1.6;
    display: flex;
    align-items: center;
    padding: var(--padding-internal) 0 var(--padding-internal) 0;
    margin-bottom: var(--margin-medium);
}

.radio_option__1q4fV > input,
.radio_option__1q4fV > span {
    margin: 0;
}

.radio_option__1q4fV > span {
    font-size: var(--font);
    font-weight: var(--font-weight-light);
}

.radio_option__1q4fV:hover {
    cursor: pointer;
}

.radio_option__1q4fV input {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin-right: 1rem;
    font: inherit;
    color: currentColor;
    width: 24px !important;
    height: 24px;
    border: 1px solid var(--color-grayish);
    border-radius: 50%;
    -webkit-transform: translateY(-0.075em);
            transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

.radio_option__1q4fV input::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: 120ms -webkit-transform ease-in-out;
    transition: 120ms transform ease-in-out;
    transition: 120ms transform ease-in-out, 120ms -webkit-transform ease-in-out;
    box-shadow: inset 1em 1em var(--color-red);
}

.radio_option__1q4fV input:checked {
    border-color: var(--color-red);
}

.radio_option__1q4fV input:checked::before {
    -webkit-transform: scale(1);
            transform: scale(1);
}

.radio_span__3ytSb:checked {
    font-weight: var(--font-weight-medium);
}

.radio_option__1q4fV.radio_disabled__2TY17 {
    opacity: 0.6;
}

.radio_option__1q4fV.radio_disabled__2TY17:hover {
    cursor: unset;
}

.desktop_sortBarContainer__74yQm {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    flex: 1 1 !important;
    display: flex;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    flex-grow: 0 !important;
    white-space: nowrap;
}

.desktop_sortBar__2S43G {
    background: var(--color-gray-darker);
    color: white;
    border-radius: 20rem;
    text-align: center;
    padding: 10px 20px;
    border: 2px solid var(--color-gray-darker);
    cursor: pointer;
    display: flex;
    flex: 1 1;
    justify-content: stretch;
    align-items: center;
}

.desktop_sortBar__2S43G span {
    font-weight: var(--font-weight-light);
    font-size: 15px;
    line-height: 22px;
}

.desktop_dropdown__1gm4C {
    position: absolute;
    right: 0.015rem;
    z-index: -1;
    left: 0.1rem;
    background: var(--color-white);
    color: var(--color-gray-darker);
    opacity: 0;
    transition: flex ease-in-out 100ms;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    border-radius: 0.3rem;
    padding: var(--padding) var(--padding-medium);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.desktop_dropdownItem__39IBg {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
}

.desktop_focus__1s8li .desktop_dropdown__1gm4C {
    top: 45px;
    opacity: 1;
    z-index: 5;
    flex: 1 1;
}

.desktop_active__1O-T_ {
    color: red;
    font-weight: var(--font-weight-medium);
}

.desktop_clearButton__118cJ {
    flex: 0 1 !important;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-black);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    margin-left: 1rem;
}

.desktop_disabledButton__3BZqw {
    flex: 0 1 !important;
    white-space: nowrap;
    text-decoration: underline;
    color: var(--color-grayFaded);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    margin-left: 1rem;
}

.desktop_modalTitle__2xFlD {
    position: absolute;
    margin-top: -3.5rem !important;
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    color: var(--color-black);
    justify-self: center;
}

.desktop_clearButton__118cJ:hover {
    opacity: 0.7;
}

.desktop_actionRow__2_bwF {
    margin-left: -25px;
    margin-right: -25px;
    margin-bottom: -25px;
    background: var(--color-white);
    width: 100%;
    padding: 2rem 1.5rem;
    box-shadow: 0 1px 14px 0 rgb(0 0 0 /14%);
    justify-content: space-between;
    align-items: baseline;
    box-sizing: unset;
    border-radius: 0px 0px 8px 8px;
}

.desktop_filterRow__ZAy8f {
    flex: 0 1 !important;
    justify-content: flex-start;
    align-items: center;
}

.desktop_modalSubtitle__hI2ZR {
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-medium);
    color: var(--color-black);
}

.desktop_modalSpan__1fuWh {
    font-size: var(--font-small);
    line-height: var(--line-small);
    font-weight: var(--font-weight-medium);
    color: var(--color-black);
    margin-right: 0.5rem;
}

.desktop_modalCheckbox__3MaU8 {
    line-height: 24px !important;
    font-size: 16px !important;
}

.desktop_chevronDown__3QT2Q {
    height: 20px !important;
    width: 10px !important;
}

.desktop_showMore__DA4C_ {
    cursor: pointer;
}

.desktop_grid__15kjP {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

@media only screen and (max-width: 520px) {
    .desktop_filterRow__ZAy8f {
        flex-direction: column !important;
    }
    .desktop_filterRow__ZAy8f .desktop_clearButton__118cJ {
        display: none;
    }
}

.checkbox-rounded_container__3TJAo {
    display: block;
    position: relative;
    padding-left: 3rem;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    margin-bottom: var(--margin) !important;
}

/* Hide the browser's default checkbox */
.checkbox-rounded_container__3TJAo input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-rounded_label__2xHmU {
    font-size: 1.25rem;
    font-weight: var(--font-weight-light);
}

/* Create a custom checkbox */
.checkbox-rounded_checkmark__2KhuX {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid var(--color-grayish);
    background-color: var(--color-white);
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.checkbox-rounded_container__3TJAo:hover div {
    opacity: 0.6;
}

.checkbox-rounded_container__3TJAo:not(.checkbox-rounded_checked__3OqTy):hover .checkbox-rounded_checkmark__2KhuX:after {
    display: block;
    opacity: 0.3;
}

.checkbox-rounded_container__3TJAo.checkbox-rounded_checked__3OqTy span {
    font-weight: var(--font-weight-medium);
}

.checkbox-rounded_checkmark__2KhuX.checkbox-rounded_checked__3OqTy {
    background-color: var(--color-red);
    border: 1px solid var(--color-red);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-rounded_checkmark__2KhuX:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-rounded_container__3TJAo.checkbox-rounded_checked__3OqTy .checkbox-rounded_checkmark__2KhuX:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-rounded_container__3TJAo .checkbox-rounded_checkmark__2KhuX:after {
    left: 9px;
    top: 4px;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.mobile_filterRow__tJuh6 {
    flex-direction: column !important;
}

.mobile_filterRow__tJuh6 .mobile_clearButton__1liAA {
    display: none;
}

.mobile_clearButton__1liAA {
    flex: 0 1 !important;
    white-space: nowrap;
    cursor: pointer;
}

.mobile_clearButton__1liAA:hover {
    opacity: 0.7;
}

.mobile_modalButton__2ZDVv {
    flex: 0 1 !important;
    white-space: nowrap;
}

.mobile_container__n1wOn {
    padding: 0;
}

.mobile_content__1-zRP {
    padding: 1rem 1.5rem;
}

.mobile_header__31Bjr {
    flex: 1 1;
    max-height: 2.5rem;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-gray-light);
}

.mobile_headerTitle__2J0Ro {
    font-size: 1.275rem;
    font-weight: var(--font-weight-medium);
}

.mobile_filterTitle__I_jhS {
    position: relative;
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-medium);
    color: var(--color-black);
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.mobile_closeIcon__3PEHN {
    position: absolute;
    left: 1rem;
}

.mobile_closeBar__2LHK6 {
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background: var(--color-grayish);
    position: absolute;
    top: 0.725rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.mobile_label__2qYvR {
    font-size: var(--font) !important;
    line-height: var(--line) !important;
    color: var(--color-black);
    font-weight: var(--font-weight-light);
    text-align: center;
}

.bottomDrawer_container__x8LI3 .drawer__content {
    max-height: calc(80vh - 25px);
    min-height: calc(80vh - 25px);
    padding: 0;
}

.mobile_container__3ZrNa {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.mobile_header__lVouQ {
    flex: 1 1;
    position: relative;
    max-height: 2rem;
    padding: 2rem 2rem;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-gray-light);
}

.mobile_closeIcon__2D5VD {
    position: absolute;
    right: 1rem;
}

.mobile_headerTitle__300w_ {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-medium);
}

.mobile_content__3bI1Z {
    flex: 1 1;
    padding: 1rem 1.5rem 8.875rem;
    overflow-y: scroll;
}

.mobile_actionRow__oBa2x {
    position: absolute;
    background: var(--color-white);
    left: 0;
    bottom: 0;
    right: 0;
    padding: 2rem 1.5rem;
    box-shadow: 0 1px 14px 0 rgb(0 0 0 /14%);
    justify-content: space-between;
    align-items: baseline;
}

.mobile_clearButton__15uGr {
    flex: 0 1 !important;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-black);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    margin-left: 1rem;
}

.mobile_container__1aMZs {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: column;
}

.mobile_header__o9NBB {
    flex: 1 1;
    max-height: 2.5rem;
    padding: 2rem 1rem 0.825rem;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--color-gray-light);
}

.mobile_closeIcon__1GpC8 {
    position: absolute;
    left: 1rem;
}

.mobile_closeBar__rnXIg {
    width: 40px;
    height: 4px;
    border-radius: 4px;
    background: var(--color-grayish);
    position: absolute;
    top: 0.725rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.mobile_headerTitle__KDVOK {
    font-size: 1.275rem;
    font-weight: var(--font-weight-medium);
}

.mobile_content__3caRR {
    flex: 1 1;
    padding: 1rem 1.5rem;
}

.mobile_filterTitle__28Quc {
    font-size: 1.5rem;
    font-weight: var(--font-weight-semibold);
    color: var(--color-black);
    margin-bottom: 1rem;
}

.mobile_actionRow__9ldWT {
    padding: 1.5rem 1.5rem 3rem;
    box-shadow: 0 1px 14px 0 rgb(0 0 0 /14%);
}

.mobile_modalButton__2aHpX {
    flex: 1 1;
    padding: 1.825rem;
    border-radius: 2rem;
}

.mobile_modalButton__2aHpX span {
    font-size: 1.2rem;
    font-weight: var(--font-weight-regular);
}

.mobile_label__1xazf .mobile_span__1H_Kg {
    font-size: var(--font) !important;
    line-height: var(--line) !important;
    color: var(--color-black);
    font-weight: var(--font-weight-light);
    text-align: center;
}

.mobile_radio__2dRK3 .mobile_radio_option__joaG4 {
    font-size: var(--font) !important;
    line-height: var(--line) !important;
    color: var(--color-black);
    font-weight: var(--font-weight-light) !important;
    text-align: center;
}

.caption_caption__39SVv {
    font-weight: var(--font-weight-regular);
    font-style: normal;
    font-size: var(--font-size-caption);
    color: var(--color-gray);
    margin-bottom: var(--margin-internal);
    line-height: 1;
}

.star_active__rRrjW {
    fill: #e14436;
}

.asset-details-modal_header__1Yf8t {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--margin-small) !important;
}

.asset-details-modal_modal__2vMz_ {
    border-radius: 10px;
}

.asset-details-modal_subtitle__2S8Q9 {
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-medium);
    margin: 0 !important;
}

.asset-details-modal_detailsContainer__2OAgn {
    margin-top: var(--margin-xlarge);
    margin-bottom: var(--margin-large);
    flex-wrap: wrap;
    flex-direction: column !important;
}

.asset-details-modal_splitter__3EljA {
    display: flex;
}

.asset-details-modal_largeSplitter__RAGOd {
    display: none;
}

.asset-details-modal_iconTransmission__2hCH5 path {
    fill: black;
}

.asset-details-modal_moreInfoText__1kNf- {
    margin: 0;
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}

.asset-details-modal_regular__2f1yv {
    font-weight: var(--font-weight-light);
}

.asset-details-modal_modalContainer__22yLl {
    padding: 0;
}

.asset-details-modal_modalHeader__1beTl {
    margin: var(--margin-medium);
    justify-content: flex-start;
}

.asset-details-modal_modalHeader__1beTl span {
    margin-left: auto !important;
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-medium);
}

.asset-details-modal_modalHeader__1beTl img {
    margin-left: auto !important;
}

.asset-details-modal_contentContainer__2wvoi {
    margin-bottom: 64px !important;
}

.asset-details-modal_moreInfoSubtitle__uXF9D {
    margin-bottom: var(--margin-medium) !important;
}

.asset-details-modal_marginBottom__2QB0d {
    margin-bottom: var(--margin-internal);
}

@media screen and (min-width: 492px) {
    .asset-details-modal_modal__2vMz_ {
        border-radius: 10px;
        width: 100%;
    }
}

@media screen and (min-width: 692px) {
    .asset-details-modal_detailsContainer__2OAgn {
        margin-top: var(--margin-xlarge);
        margin-bottom: var(--margin-large);
        flex-wrap: wrap;
        flex-direction: row !important;
    }

    .asset-details-modal_splitter__3EljA {
        display: none;
    }

    .asset-details-modal_largeSplitter__RAGOd {
        display: flex;
    }
}

.characteristics-item_itemContainer__1C6-Z {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.characteristics-item_itemContainerRow__1S2y4 {
    padding-bottom: var(--margin);
    align-items: center;
    display: flex;
    flex-direction: row;
}

.characteristics-item_itemContainerRow__1S2y4 > * {
    margin-bottom: unset;
}

.characteristics-item_itemContainer__1C6-Z .characteristics-item_icon__3EfNL {
    height: 35px;
    width: 35px;
    margin-bottom: var(--margin);
}

.characteristics-item_itemContainerRow__1S2y4 .characteristics-item_icon__3EfNL {
    height: 35px;
    width: 35px;
    margin-right: var(--margin-small);
}

.characteristics-item_itemContainer__1C6-Z span {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
    margin-bottom: 0;
    color: var(--color-gray-darker);
    white-space: nowrap !important;
}

.characteristics-item_itemContainerRow__1S2y4 span {
    margin-left: 20px;
    margin-bottom: 0;
    color: var(--color-gray-darker);
    white-space: nowrap !important;
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}

.features-item_itemContainer__1AvRL {
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.features-item_itemContainerRow__1E14z {
    padding-bottom: var(--margin);
    align-items: center;
    display: flex;
    flex-direction: row;
}
.features-item_itemContainer__1AvRL .features-item_icon__2AhzI {
    height: 35px;
    width: 35px;
    margin-bottom: var(--margin);
}

.features-item_itemContainerRow__1E14z .features-item_icon__2AhzI {
    height: 35px;
    width: 35px;
    margin-right: var(--margin-small);
}

.features-item_itemContainerRow__1E14z > * {
    margin-bottom: unset;
}

.features-item_itemContainer__1AvRL span {
    margin-bottom: 0;
    color: var(--color-gray-darker);
    white-space: nowrap !important;
}

.features-item_itemContainerRow__1E14z span {
    margin-left: 20px;
    margin-bottom: 0;
    color: var(--color-gray-darker);
    white-space: nowrap !important;
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}

.list-item-gallery_photo__uPLQc {
    flex: 0 1;
}

.list-item-gallery_photoInput__2BNxm {
    border: 1px solid lightgray;
    cursor: pointer;
}

.list-item-gallery_photoInput__2BNxm:focus {
    outline-width: 0px;
}

.list-item-gallery_photoInput__2BNxm:hover > span {
    text-decoration: underline;
}

.list-item-gallery_carousel__9gTK- .carousel-slider,
.list-item-gallery_carousel__9gTK- .slider,
.list-item-gallery_carousel__9gTK- .slider-wrapper,
.list-item-gallery_carousel__9gTK- .slider-wrapper .slider,
.list-item-gallery_carousel__9gTK- .slider-wrapper .slider .slide {
    height: 100%;
    background-color: transparent;
}

.list-item-gallery_actionIcon__1At4Y {
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: var(--radius);
    background: var(--color-white);
    margin-bottom: var(--margin-small);
    padding: var(--padding-small);
}

.list-item-gallery_actionIcon__1At4Y:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.list-item-gallery_actionContainer__1iKXU {
    flex: 0 0 !important;
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
    box-shadow: -2px -1px 10px -1px var(--color-gray-light);
    background: var(--color-primary);
    padding: var(--padding-small);
    max-height: 11.5rem;
}

.list-item_item__3I0dA {
    margin-bottom: var(--margin-medium);
    margin-top: var(--margin);
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-secondary);
    padding: var(--padding-small);
    align-items: center;
}

.list-item_header__2Jnx7 {
    flex: 0 1 !important;
    flex-direction: row;
    margin-top: var(--margin);
}

.list-item_title__1VlRJ {
    font-size: var(--font-xlarge);
    font-weight: var(--font-weight-semibold);
    line-height: var(--font-large);
    font-family: 'Poppins';
}

.list-item_photo__3Na99 {
    height: 190px;
    max-height: 190px;
    min-height: 190px;
    width: 335px;
    background: rgba(255, 255, 255, 0.8);
    cursor: pointer;
}

.list-item_price__2Pzge {
    font-size: var(--font-large);
    font-weight: var(--font-weight-semibold);
    line-height: var(--line-large);
    margin: 0 var(--margin-internal) 0 0 !important;
}

.list-item_priceInfo__i8dI1 {
    font-size: var(--font-large);
    font-weight: var(--font-weight-light);
    line-height: var(--line-large) !important;
}

.list-item_categoryContainer__38d8R {
    align-items: flex-end;
    margin-right: 0 !important;
}

.list-item_categoryContainer__38d8R :last-child {
    margin-left: 3px;
    color: red;
    text-decoration: underline;
}

.list-item_priceContainer__2jFGQ {
    align-items: flex-end;
    margin-top: var(--margin);
}

.list-item_totalContainer__1wOm0 > span {
    text-decoration: underline;
    color: var(--color-grayish);
}

.list-item_iconsWrapper__2E3Ad {
    align-items: center;
    display: flex;
}

.list-item_iconsWrapper__2E3Ad > svg {
    margin-right: 7px;
    fill: var(--color-grayish);
}

.list-item_iconsWrapper__2E3Ad > svg path {
    fill: var(--color-grayish);
}

.list-item_iconsWrapper__2E3Ad > .list-item_seatsIcon__18yWE path {
    fill: white;
    stroke: var(--color-grayish);
}

.list-item_iconsWrapper__2E3Ad > span {
    color: var(--color-grayish);
}

.list-item_ratingContainer__AhE9u {
    flex-direction: row;
    align-items: center;
}

.list-item_ratingContainer__AhE9u > * {
    margin-right: 3px;
    margin-bottom: 0 !important;
}

.list-item_rating__3kr5F {
    color: var(--color-gray-darker);
    font-weight: var(--font-weight-medium);
    margin-right: 5px;
}

.list-item_ratingIcon__1RNrY {
    width: 12px;
}

.list-item_bottomContainer__79gvN {
    display: flex;
}

.list-item_detailsIcon__3e9ex {
    width: 21px;
    max-width: 21px;
    min-width: 21px;
}

.list-item_iconsWrapper__2E3Ad > *,
.list-item_categoryContainer__38d8R > *,
.list-item_totalContainer__1wOm0 > * {
    margin-bottom: 0 !important;
    font-size: var(--font-xsmall);
    width: -webkit-max-content;
    width: max-content;
}

.list-item_iconsContainer__CRV8h,
.list-item_iconsWrapper__2E3Ad,
.list-item_bottomContainer__79gvN > * {
    flex: 0 1 !important;
}

.list-item_suitcasesInfo__SgAKV {
    display: none !important;
}

.list-item_listItem__3xd6q {
    margin-bottom: 0;
}

.list-item_listItem__3xd6q .list-item_header__2Jnx7 {
    display: block;
    width: 335px;
}

.list-item_listItem__3xd6q .list-item_category__3ng6q {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    color: var(--color-red);
    font-weight: var(--font-weight-light);
    margin-bottom: var(--margin-internal);
}

.list-item_listItem__3xd6q .list-item_bookLink__37jgo {
    width: 100%;
    background-color: var(--color-red);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 48px;
    color: var(--color-white);
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    font-family: 'Poppins';
    margin-top: var(--margin-medium);
}

.list-item_listItem__3xd6q .list-item_bookLinkLeft__2Goar {
    width: 100%;
    background-color: var(--color-red);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 48px;
    color: var(--color-white);
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    font-family: 'Poppins';
    margin-top: 1.5rem;
    display: none;
}

.list-item_listItem__3xd6q .list-item_priceContainer__2jFGQ {
    align-items: flex-start;
}

.list-item_listItem__3xd6q .list-item_bookButtonContainer__1PLNa {
    justify-content: center;
    margin-bottom: var(--margin-xlarge);
}

.list-item_listItem__3xd6q .list-item_priceDay__3To32 {
    margin-bottom: 4px;
    align-items: flex-start;
}

.list-item_listItem__3xd6q .list-item_priceInfo__i8dI1 {
    font-size: var(--font);
    line-height: var(--line-medium);
    margin-bottom: 0;
}

.list-item_listItem__3xd6q .list-item_iconsContainer__CRV8h {
    margin: 16px 0px;
}

.list-item_listItem__3xd6q .list-item_seeDetails__kCpKQ {
    cursor: pointer;
    color: var(--color-red);
    font-size: var(--font-small);
    line-height: var(--line-small);
    text-decoration: underline;
    text-decoration-thickness: 0.5px;
    text-underline-offset: 1px;
    font-weight: var(--font-weight-medium);
}

.list-item_listItem__3xd6q .list-item_seeDetailsContainer__2-Ejo {
    display: block;
    margin-bottom: var(--margin);
}

.list-item_splitter__2YFwj {
    margin-top: 32px !important;
}

.list-item_assetDescription__VK9pA {
    color: var(--color-grayish);
    font-size: var(--font-xsmall);
    line-height: 20px;
    font-weight: var(--font-weight-light);
}

.list-item_seeDetails__kCpKQ {
    text-decoration: underline;
    color: var(--color-red);
}

@media only screen and (min-width: 492px) {
    .list-item_item__3I0dA {
        margin-bottom: var(--margin-medium);
        position: relative;
        display: flex;
        flex-direction: column;
        font-family: var(--font-family-secondary);
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        flex-wrap: wrap;
        width: 100%;
    }
    .list-item_listItem__3xd6q .list-item_bookButtonContainer__1PLNa {
        justify-content: flex-end;
    }

    .list-item_listItem__3xd6q .list-item_header__2Jnx7 {
        margin-top: 0;
        width: 444px;
    }

    .list-item_listItem__3xd6q .list-item_bookLink__37jgo {
        width: 214px;
        margin-top: 0;
    }

    .list-item_listItem__3xd6q .list-item_bookLinkLeft__2Goar {
        width: 214px;
        margin-top: 0;
        display: none;
    }
    .list-item_photo__3Na99 {
        height: 190px;
        max-height: 190px;
        min-height: 190px;
        width: 444px;
        max-width: 444px;
        background: rgba(255, 255, 255, 0.8);
        margin-bottom: var(--margin-small);
    }

    .list-item_listItem__3xd6q .list-item_seeDetailsContainer__2-Ejo {
        display: block;
        margin-bottom: var(--margin-small) !important;
    }

    .list-item_bottomContainer__79gvN {
        flex-direction: column !important;
        width: 100% !important;
    }

    .list-item_priceContainer__2jFGQ {
        align-items: unset;
    }

    .list-item_bookButtonContainer__1PLNa {
        margin-right: auto;
        margin-top: var(--margin-medium);
    }
}

@media only screen and (min-width: 692px) {
    .list-item_item__3I0dA {
        margin-bottom: var(--margin-medium);
        position: relative;
        display: flex;
        flex-direction: row;
        font-family: var(--font-family-secondary);
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        align-items: flex-start;
        flex-wrap: nowrap;
        width: 100%;
    }
    .list-item_listItem__3xd6q .list-item_bookButtonContainer__1PLNa {
        justify-content: flex-end;
    }

    .list-item_listItem__3xd6q .list-item_header__2Jnx7 {
        flex: 1 1 !important;
        max-width: 380px;
        margin-left: var(--margin-medium);
    }

    .list-item_listItem__3xd6q .list-item_bookLink__37jgo {
        width: 210px;
        margin-top: 0;
        display: none;
    }

    .list-item_left__LibTl {
        display: flex;
    }

    .list-item_listItem__3xd6q .list-item_bookLinkLeft__2Goar {
        width: 240px;
        display: flex;
        left: 0;
    }
    .list-item_bookLeftButtonContainer__1joTo {
        margin-top: var(--margin-medium);
        display: flex;
        top: 0;
    }

    .list-item_photo__3Na99 {
        height: 180px;
        max-height: 180px;
        min-height: 180px;
        width: 240px;
        margin-bottom: 0px;
    }

    .list-item_listItem__3xd6q .list-item_seeDetailsContainer__2-Ejo {
        display: block;
        margin-bottom: var(--margin-small) !important;
    }

    .list-item_bottomContainer__79gvN {
        flex-direction: row !important;
        width: 100% !important;
        padding: 0;
        margin-left: var(--margin-medium);
    }

    .list-item_priceContainer__2jFGQ {
        align-items: unset;
        margin-left: 0px;
    }

    .list-item_bookButtonContainer__1PLNa {
        margin-right: auto;
    }
}

@media (min-width: 928px) {
    .list-item_listItem__3xd6q .list-item_header__2Jnx7 {
        flex: 1 1 !important;
        max-width: 332px;
        margin-left: var(--margin);
    }

    .list-item_listItem__3xd6q .list-item_titleContainer__35x4A {
        margin-bottom: var(--margin-small);
        margin-right: 0 !important;
    }

    .list-item_listItem__3xd6q .list-item_title__1VlRJ {
        font-size: var(--font-xxlarge);
        font-weight: var(--font-weight-semibold);
        line-height: var(--line-xxlarge);
    }

    .list-item_listItem__3xd6q .list-item_itemSpecsCol__229K_ {
        flex-direction: row;
        justify-content: space-between;
        flex: 1 1;
    }

    .list-item_listItem__3xd6q .list-item_bookLink__37jgo {
        display: flex;
    }
    .list-item_listItem__3xd6q .list-item_bookLinkLeft__2Goar {
        display: none;
    }
    .list-item_photo__3Na99 {
        height: 158.67px;
        max-height: 158.67px;
        min-height: 158.67px;
        max-width: 280px;
        width: 280px;
        background: rgba(255, 255, 255, 0.8);
    }

    .list-item_listItem__3xd6q .list-item_seeDetailsContainer__2-Ejo {
        display: block;
        margin-bottom: var(--margin-small) !important;
    }

    .list-item_assetDescription__VK9pA {
        color: var(--color-grayish);
        font-size: var(--font-xsmall);
        line-height: var(--line-xsmall);
        font-weight: var(--font-weight-light);
        margin: 0;
        width: 85%;
    }

    .list-item_bottomContainer__79gvN {
        flex-direction: column !important;
        justify-content: space-between;
        align-items: flex-end;
        max-width: 210px;
        margin-left: 0;
    }

    .list-item_listItem__3xd6q .list-item_priceContainer__2jFGQ {
        align-items: flex-end;
    }

    .list-item_listItem__3xd6q .list-item_price__2Pzge {
        font-size: var(--font-large);
        line-height: var(--line-medium);
        margin-bottom: 0;
    }

    .list-item_listItem__3xd6q .list-item_priceInfo__i8dI1 {
        font-size: var(--font-medium);
    }

    .list-item_bookButtonContainer__1PLNa {
        justify-content: center;
    }
}

@media (min-width: 1360px) {
    .list-item_listItem__3xd6q .list-item_header__2Jnx7 {
        flex: 1 1 !important;
        max-width: 420px;
        margin-left: var(--margin);
    }

    .list-item_item__3I0dA {
        margin-bottom: var(--margin-medium);
        position: relative;
        display: flex;
        flex-direction: row;
        font-family: var(--font-family-secondary);
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        flex-wrap: nowrap;
        width: 100%;
    }

    .list-item_listItem__3xd6q .list-item_titleContainer__35x4A {
        margin-bottom: var(--margin);
    }

    .list-item_suitcasesInfo__SgAKV {
        display: flex !important;
    }

    .list-item_listItem__3xd6q .list-item_seeDetailsContainer__2-Ejo {
        margin-bottom: var(--margin-small) !important;
    }
    .list-item_assetDescription__VK9pA {
        color: var(--color-grayish);
        font-size: var(--font-xsmall);
        line-height: var(--line-xsmall);
        font-weight: var(--font-weight-light);
        margin: 0;
        width: 100%;
    }

    .list-item_photo__3Na99 {
        height: 204px;
        max-height: 204px;
        min-height: 204px;
        width: 360px;
        max-width: 360px;
    }
    .list-item_listItem__3xd6q .list-item_bookLink__37jgo {
        width: 211px;
        display: flex;
        right: 0;
        bottom: var(--margin-small);
        margin-left: 7px;
    }

    .list-item_listItem__3xd6q .list-item_title__1VlRJ {
        font-size: var(--font-h5);
        line-height: 36px;
    }

    .list-item_listItem__3xd6q .list-item_price__2Pzge {
        font-size: var(--font-xxlarge);
    }

    .list-item_bottomContainer__79gvN {
        flex-direction: column !important;
        width: 100% !important;
    }

    .list-item_priceContainer__2jFGQ {
        align-items: unset;
        right: 0;
    }
}

@media (min-width: 1680px) {
    .list-item_listItem__3xd6q .list-item_header__2Jnx7 {
        flex: 1 1 !important;
        max-width: 520px;
        margin-left: var(--margin);
    }

    .list-item_item__3I0dA {
        margin-bottom: var(--margin-medium);
        position: relative;
        display: flex;
        flex-direction: row;
        font-family: var(--font-family-secondary);
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        flex-wrap: nowrap;
        width: 100%;
    }

    .list-item_listItem__3xd6q .list-item_titleContainer__35x4A {
        margin-bottom: var(--margin);
        width: 278px;
    }

    .list-item_suitcasesInfo__SgAKV {
        display: flex !important;
    }

    .list-item_listItem__3xd6q .list-item_seeDetailsContainer__2-Ejo {
        margin-bottom: var(--margin-small) !important;
    }
    .list-item_assetDescription__VK9pA {
        color: var(--color-grayish);
        font-size: var(--font-xsmall);
        line-height: var(--line-xsmall);
        font-weight: var(--font-weight-light);
        margin: 0;
        width: 100%;
    }

    .list-item_photo__3Na99 {
        height: 204px;
        max-height: 204px;
        min-height: 204px;
        width: 360px;
        max-width: 360px;
    }
    .list-item_listItem__3xd6q .list-item_bookLink__37jgo {
        width: 211px;
        display: flex;
        right: 0;
        bottom: var(--margin-small);
        margin-left: 7px;
    }

    .list-item_listItem__3xd6q .list-item_title__1VlRJ {
        font-size: var(--font-h5);
        line-height: 36px;
    }

    .list-item_listItem__3xd6q .list-item_price__2Pzge {
        font-size: var(--font-xxlarge);
    }

    .list-item_bottomContainer__79gvN {
        flex-direction: column !important;
        width: 100% !important;
    }

    .list-item_priceContainer__2jFGQ {
        align-items: unset;
        right: 0;
    }
}

.list_gridContainer__xwT47 {
    flex: unset !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 20px;
}

.list_splitter__2jpHT {
    border-color: rgba(0, 0, 0, 0);
}

.list_containerSpinner__ZFiU1 {
    margin: var(--margin-xlarge);
}

.list_spinnerText__ZyGVe {
    color: var(--color-gray);
    text-align: center;
    margin-top: var(--margin);
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-light);
}

/* Loading spinner animation  */
.list_spinnerText__ZyGVe::after {
    display: inline-block;
    -webkit-animation: list_dots__2iFoe steps(1, end) 1s infinite;
            animation: list_dots__2iFoe steps(1, end) 1s infinite;
    content: '';
    width: 0.55rem;
}

@-webkit-keyframes list_dots__2iFoe {
    0% {
        content: '';
    }
    25% {
        content: '.';
    }
    50% {
        content: '..';
    }
    75% {
        content: '...';
    }
    100% {
        content: '';
    }
}

@keyframes list_dots__2iFoe {
    0% {
        content: '';
    }
    25% {
        content: '.';
    }
    50% {
        content: '..';
    }
    75% {
        content: '...';
    }
    100% {
        content: '';
    }
}

@media screen and (min-width: 928px) {
    .list_splitter__2jpHT {
        border-color: var(--color-gray-lighter);
        margin-top: 32px;
    }
}

div .desktop_grid__1uqT0 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: var(--page-max-width);
}

.desktop_left__LudKA {
    justify-self: start;
}

.desktop_right__y5DoO {
    justify-self: end;
}

div .desktop_right__y5DoO svg {
    width: 24px;
    height: 24px;
}

.desktop_icon__2NyWB {
    width: 20px;
    height: 20px;
    stroke: black;
    stroke-width: 1px;
    box-sizing: unset;
    margin-right: var(--margin);
}

.desktop_addressComplete__1i17S > input {
    width: 155px;
}

div .desktop_container__U-PrI {
    align-items: center;
}

div .desktop_container__U-PrI img {
    margin-right: 30px;
}

div .desktop_container__U-PrI .desktop_searchContainer__1UBC9 div div input {
    outline: 0;
    border-width: 0 0 1px;
    border-radius: 0;
    border-color: var(--color-gray-lighter);
    margin-bottom: 0;
}

div .desktop_container__U-PrI .desktop_searchContainer__1UBC9 div div input:focus {
    border-color: var(--color-gray-darker);
}

.desktop_searchContainer__1UBC9 .desktop_inputContainer__2UiNs div {
    margin-bottom: 0;
}

.desktop_container__U-PrI .desktop_clearButton__Bnxy0 {
    flex: 0 1;
    align-self: flex-end;
    white-space: nowrap;
    cursor: pointer;
}

.desktop_clearButton__Bnxy0:hover {
    opacity: 0.7;
}

.desktop_logo__30vT5 {
    display: none;
}

.desktop_leftIcon__3GdzU {
    display: flex;
}

.desktop_logoContainer__37by- {
    justify-self: start;
    margin-right: 0px !important;
}

.desktop_logoContainer__37by- a {
    width: 54px;
}

.desktop_logoContainer__37by- a div {
    background-image: url(/static/media/Logo_Full_Red.71649ae7.svg);
}

.desktop_dateInput___qUQb {
    display: flex;
    align-items: center;
    flex: 1 1;
}

.desktop_dateInput___qUQb:focus-visible {
    outline: none;
}

.desktop_arrowLeft__2RV-7 {
    position: absolute;
    top: 119px;
    left: 22px;
}

.desktop_arrowRight__12IlC {
    position: absolute;
    top: 110px;
    right: 22px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.desktop_closeIcon__3PO7R {
    width: 10px;
    height: 10px;
    margin-left: 10px;
}

.desktop_searchVisible__3SCjh > div {
    right: 0 !important;
    left: -260px !important;
}

@media screen and (min-width: 928px) {
    .desktop_dateInput___qUQb .DayPicker_weekHeader,
    .desktop_dateInput___qUQb .CalendarMonthGrid_month__horizontal {
        margin-top: 85px;
    }

    .desktop_dateInput___qUQb .DayPicker_transitionContainer {
        height: 378px !important;
    }

    .desktop_calendarCloseIcon__OurZQ {
        width: 100px !important;
        height: 100px !important;
        right: 0 !important;
        top: 0 !important;
        z-index: 10000 !important;
        background-color: tomato !important;
    }

    .desktop_leftIcon__3GdzU {
        display: none;
    }

    .desktop_logo__30vT5 {
        display: flex;
        vertical-align: middle;
        margin-right: 1rem;
    }

    .desktop_logoContainer__37by- {
        justify-self: start;
    }

    .desktop_dateInput___qUQb {
        max-width: 350px;
    }

    .desktop_picker__1Ctkc > div {
        padding: 4px 0 !important;
        background-color: var(--color-white) !important;
        border: 0 !important;
        border-bottom: 0.5px solid #0b0b0d33 !important;
    }

    .desktop_picker__1Ctkc > div > div:first-child {
        padding: 0 !important;
    }

    .desktop_picker__1Ctkc > div > div:last-child > div:last-child {
        padding: 0;
    }

    .desktop_buttonsContainer__3lD1a {
        justify-content: space-between;
    }

    .desktop_buttonsContainer__3lD1a > button:first-child {
        margin-left: 13px;
        padding: 5px 20px;
        font-size: var(--font-small);
        font-weight: var(--font-weight-medium);
        text-decoration: underline;
        border: 0;
        text-decoration-thickness: 0.5px;
        text-underline-offset: 1px;
    }

    .desktop_buttonsContainer__3lD1a > button:last-child {
        margin-right: 35px;
        padding: 12px 32px;
        border-radius: 88px;
        background-color: var(--color-gray-darker);
        color: var(--color-white);
        margin-bottom: 32px !important;
    }

    .desktop_calHeader__1rqBO {
        width: calc(100%);
    }

    .desktop_titleContainer__3Lxc7 {
        margin-right: 0 !important;
    }

    .desktop_closeContainer__3iUT8 {
        align-items: flex-end;
    }

    .desktop_calHeader__1rqBO > span {
        display: block !important;
    }

    .desktop_calHeader__1rqBO {
        box-sizing: border-box;
        padding-left: 13px;
        padding-right: 22px;
        position: absolute;
        top: 0;
        top: 25px;
    }

    .desktop_calTitle__3mCcC {
        font-size: var(--font-xlarge);
        color: var(--color-gray-darker);
        font-weight: var(--font-weight-medium);
        line-height: var(--line-xlarge);
        margin-bottom: 8px;
    }

    .desktop_calDates__7RqcR {
        font-size: var(--font-small);
        font-weight: var(--font-weight-light);
        line-height: var(--line-small);
        margin-bottom: 0;
    }

    .desktop_btnLoading__2Nu8L {
        background-color: var(--color-gray-lighter);
    }

    .desktop_picker__1Ctkc > .dropdown__menu {
        margin-bottom: 2px !important;
        max-height: 160px !important;
    }

    .desktop_picker__1Ctkc > .dropdown__menu > div {
        max-height: 150px !important;
    }

    .desktop_pickerContainer__2dckA > span {
        font-weight: var(--font-weight-medium);
        color: var(--color-gray-darker);
        font-size: var(--font);
    }

    .desktop_dropdownArrow__1Jsc3 path {
        stroke: var(--color-gray-darker);
    }
}

@media screen and (min-width: 1359px) {
    .desktop_grid__1uqT0 .desktop_gridContainer__2dxIf {
        justify-self: center !important;
    }
}

.assets_pageContent__6_y_s {
    flex: 1 1;
}

.assets_leftList__1NfHQ {
    flex: 1 1;
    margin-right: 0 !important;
}

.assets_mapContainer__3JzV- {
    flex: 1 1;
}

.assets_leftListWrapper__12oeL {
    margin-right: 0 !important;
}

.assets_leftArrow__2LYaP {
    margin-top: 10px;
    margin-bottom: 15px;
    display: none;
}

.assets_barInfo__2tfum {
    text-align: center;
    color: white;
    margin: var(--margin-medium);
}

.assets_barInfo__2tfum span {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 20rem;
    padding: var(--margin-internal);
    padding-right: 2rem;
    padding-left: 2rem;
}

.assets_leftListHeader__2whkx {
    justify-content: space-between;
    align-items: center;
    flex: 0 1 !important;
    -webkit-flex: unset !important;
    flex-shrink: 0 !important;
}

.assets_headerTitle__10aGf {
    font-size: var(--font-xxlarge);
    line-height: var(--line-xxlarge);
    font-weight: var(--font-weight-medium);
    margin: var(--margin-internal) 0 var(--margin);
}

.assets_mapSearch__GSsLi {
    position: absolute;
    top: var(--margin);
    right: var(--margin);
    z-index: 10;
    cursor: grab;
}

.assets_mapUserLocationActions__3ewcC {
    position: absolute;
    bottom: var(--padding-medium);
    right: var(--padding-large);
    z-index: 10;
    cursor: grab;
}

.assets_resultsText__24spK {
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
}

.assets_text__3VPDe {
    color: var(--color-white);
}

.assets_pagination__3g-NV {
    justify-content: center;
    padding: 0 !important;

    margin-top: var(--margin-medium);
    margin-bottom: var(--margin-large) !important;
}

@media screen and (min-width: 692px) {
    .assets_headerTitle__10aGf {
        margin: var(--margin-internal) 0 var(--margin-medium);
    }
}

@media screen and (min-width: 928px) {
    .assets_headerTitle__10aGf {
        font-size: var(--font-h4);
        line-height: var(--line-h4);
        font-weight: var(--font-weight-semibold);
    }
    .assets_pageContent__6_y_s {
        justify-content: center;
    }

    .assets_leftListWrapper__12oeL {
        max-width: 880px;
        padding: 0;
    }

    .assets_leftArrow__2LYaP {
        margin-top: 10px;
        margin-bottom: 15px;
        display: flex;
    }
}

@media screen and (min-width: 1360px) {
    .assets_leftListWrapper__12oeL {
        max-width: 1360px;
    }
}

.mobile-price-drawer_drawer__3q8jV {
    position: fixed !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    padding: var(--margin) var(--margin-medium) var(--margin-xlarge);
    justify-content: space-around;
    display: flex;
    z-index: 1;

    flex: 0 1;
    /* Prevent selection */
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    /* Old versions of Firefox */
    /* Internet Explorer/Edge */
    user-select: none;
    /* supported by Chrome, Edge, Opera and Firefox */

    /* White */

    background: #ffffff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
}

.mobile-price-drawer_sticky__5XgIe {
    position: absolute;
    width: 134px;
    height: 5px;
    left: calc(50% - 134px / 2);
    bottom: 8px;

    /* Rich Black - Main color */

    background: #0b0b0d;
    border-radius: 100px;
    cursor: pointer;
}

.mobile-price-drawer_modal__1ZqM0 {
    width: 100% !important;
    position: absolute;
    bottom: 0 !important;
    box-shadow: 0px 0px 16px rgba(11, 11, 13, 0.08);
    border-radius: 8px 8px 0px 0px;
    padding-bottom: 16px;
}

.mobile-price-drawer_closeIcon__2Mp4G {
    align-self: baseline;
    margin-bottom: 1rem;
    cursor: pointer;
}

@media screen and (min-width: 492px) {
    .mobile-price-drawer_submitButton__3cXZa {
        width: 221px;
    }
}

.characteristics_container__2di4l {
    margin-bottom: var(--margin-medium);
    flex-direction: column !important;
    display: flex;
}

.characteristics_characteristicsGrid__2I5yD {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: var(--margin-large);
    flex: unset !important;
}

.characteristics_title__2kn0G {
    font-weight: var(--font-weight-medium) !important;
    font-size: var(--font-large) !important;
    line-height: var(--line-large);
    margin: 0 0 var(--margin-large) !important;
}

.characteristics_iconLabel__lq1yj > * {
    font-size: var(--font-xsmall);
    color: var(--color-black);
    line-height: var(--line-xsmall);
    margin-bottom: unset;
}

.characteristics_iconTransmission__2MPx7 svg path {
    fill: black;
}

.characteristics_seeMore__29qh5 {
    color: var(--color-alice);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    text-decoration: underline;
    cursor: pointer;
    /* Prevent selection */
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    /* Old versions of Firefox */
    /* Internet Explorer/Edge */
    user-select: none;
    /* supported by Chrome, Edge, Opera and Firefox */
    white-space: nowrap;
}

.characteristics_grid__yTcfY {
    align-items: flex-start;
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    margin-bottom: var(--margin-xlarge);
}

@media only screen and (min-width: 692px) {
    .characteristics_characteristicsGrid__2I5yD {
        grid-gap: var(--margin-medium);
    }
}

@media only screen and (min-width: 928px) {
    .characteristics_container__2di4l {
        flex-direction: row;
        display: flex;
        justify-content: space-between !important;
    }

    .characteristics_grid__yTcfY {
        align-items: center;
        display: flex;
        flex-direction: column !important;
        align-items: flex-start !important;
        margin-bottom: var(--margin-xlarge);
    }

    .characteristics_title__2kn0G {
        font-weight: var(--font-weight-medium) !important;
        font-size: var(--font-xlarge) !important;
    }
}

@media only screen and (min-width: 1360px) {
    .characteristics_characteristicsGrid__2I5yD {
        grid-gap: var(--margin-xlarge);
    }
}

.header_container__2YnkA {
    --assetDetailsHeader-height: 45rem;

    position: relative;
    height: var(--assetDetailsHeader-height);
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: nowrap;
    overflow: hidden;
}

.header_container__2YnkA .dot {
    outline: none;
}

.header_headerCarousel__3lM8c {
    height: var(--assetDetailsHeader-height);
}

.header_headerCarousel__3lM8c .carousel-slider,
.header_headerCarousel__3lM8c .slider,
.header_headerCarousel__3lM8c .slider-wrapper,
.header_headerCarousel__3lM8c .slider-wrapper .slider,
.header_headerCarousel__3lM8c .slider-wrapper .slider .slide {
    height: 100%;
    background-color: transparent;
}

.header_headerPhoto__2Rirh {
    height: var(--assetDetailsHeader-height);
}

.header_icon__2uGlb {
    flex: 0 1 !important;
}

.header_actionOverlay__3kUSt {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    padding: 16px 24px;
    background: linear-gradient(180deg, rgb(11 11 13 / 50%) 0%, rgb(11 11 13 / 0%) 100%);
}

.header_overlayIconContainer__3B9T5 {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex: 1 1;
}

.header_overlayIconContainer__3B9T5 :last-child {
    flex: 0 1 !important;
}

.header_overlayIconContainer__3B9T5 :last-child > * {
    margin-left: 1.5rem;
}

.header_overlayIconContainer__3B9T5 .header_arrowBack__26aSo {
    height: 30px;
    width: 30px;
    -webkit-filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.35));
            filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.35));
    stroke-width: 0.095rem;
    stroke: white;
    cursor: pointer;
}

.header_overlayIconContainer__3B9T5 .header_share__c4FzO {
    height: 24px;
    width: 24px;
    -webkit-filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.35));
            filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.35));
    stroke-width: 0.1rem;
    stroke: white;
    cursor: pointer;
}

.header_overlayIconContainer__3B9T5 .header_like__1geqN {
    height: 24px;
    width: 24px;
    -webkit-filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.35));
            filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.35));
    stroke-width: 0.1rem;
    stroke: white;
    cursor: pointer;
}

.header_container__2YnkA:hover .header_arrow__3FZP0 {
    opacity: 1;
}

.header_arrow__3FZP0 {
    position: absolute;
    bottom: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1 1;
    z-index: 2;
    opacity: 0;
    transition: all 200ms ease-in;
}

.header_arrowWrapper__1bd_d {
    background-color: white;
    border-radius: 20rem;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0.5rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
    box-shadow: 0 0 10px rgb(0 0 0 / 49%), 0 0 0 1px rgb(0 0 0 / 7%);
    transition: height ease-in-out 250ms;

    cursor: pointer;
}

.header_arrowWrapper__1bd_d:active {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    opacity: 0.7;
}

.header_arrow__3FZP0 img {
    width: unset !important;
}

.header_arrowNext__eoiKG {
    right: 1.5rem;
}

.header_arrowNext__eoiKG img {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
}

.header_arrowPrev__2AuAk {
    left: 1.5rem;
}

.header_arrowPrev__2AuAk img {
    -webkit-transform: translateX(-1px);
            transform: translateX(-1px);
}

@media only screen and (max-width: 1360px) {
    .header_container__2YnkA {
        --assetDetailsHeader-height: 35rem;
    }
}

@media only screen and (max-width: 928px) {
    .header_container__2YnkA {
        --assetDetailsHeader-height: 25rem;
    }
}

@media only screen and (max-width: 691px) {
    .header_container__2YnkA {
        --assetDetailsHeader-height: 20rem;
    }
}

@media only screen and (max-width: 480px) {
    .header_container__2YnkA {
        --assetDetailsHeader-height: 15rem;
    }
}

@media screen and (max-width: 491px) {
    .header_container__2YnkA {
        border-radius: 0px !important;
    }
}

@media screen and (min-width: 692px) {
    .header_container__2YnkA {
        max-width: var(--page-max-width);
        width: var(--page-width);
        margin: var(--margin) auto;
        border-radius: 5px;
    }
}

.title_container__3z3eD {
    margin-bottom: var(--margin-large);
}

.title_title__275EM {
    font-size: var(--font-h5);
    line-height: var(--line-h5);
    font-weight: var(--font-weight-semibold);
    flex: 1 1 !important;
    margin-bottom: 12px;
}

.title_description__1HW_I {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
    color: var(--color-gray-darker);
}

.title_ratingContainer__2ZKZv {
    flex-direction: row;
    align-items: center;
}

.title_ratingContainer__2ZKZv > * {
    margin-right: 5px;
    margin-bottom: 0 !important;
}

.title_rating__qHq-4 {
    color: var(--color-gray-darker);
    font-weight: var(--font-weight-light);
    margin-right: 5px;
    font-size: var(--font-xsmall);
}

.title_reviews__Ft7Zo {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
}

.title_ratingIcon__3iEf9 {
    width: 16px;
    height: 16px;
}

.title_categoryContainer__2aUJz {
    align-items: center;
    margin-bottom: 12px;
}

.title_categoryContainer__2aUJz > * {
    color: var(--color-gray-darker);
    font-weight: var(--font-weight-light);
    margin-right: 5px !important;
    font-size: 13px !important;
    margin-bottom: 0 !important;
}

.title_category__3Qgke {
    color: var(--color-red) !important;
    font-weight: var(--font-weight-light) !important;
}

.title_actions__u2vJl {
    justify-content: space-between;
    align-items: center;
}

.title_actionRow__2pkaJ {
    flex: 0 1 !important;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
}

.title_share__1kUA- {
    stroke: black;
    height: 24px;
    width: 24px;
    stroke-width: 0.1rem;
    cursor: pointer;
}

@media (min-width: 1360px) {
    .title_title__275EM {
        font-size: var(--font-h4);
        line-height: var(--line-h4);
    }

    .title_categoryContainer__2aUJz > * {
        font-size: var(--font-medium) !important;
        line-height: var(--line-medium);
    }

    .title_container__3z3eD {
        margin-bottom: var(--margin-xlarge);
        flex: 0 1 !important;
    }
}

.extras_container__11gjk {
    margin-bottom: var(--margin-large);
    flex: 0 1 !important;
}

.extras_mobileContainer__1NIG1 {
    margin-bottom: var(--margin-large);
}

.extras_extrasItems__11jTe {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--margin);
    grid-gap: var(--margin);
    gap: var(--margin);
    flex: 1 1 !important;
    margin-top: var(--margin-large);
}

.extras_header__1-Sfl {
    font-weight: var(--font-weight-medium) !important;
    font-size: var(--font-xlarge) !important;
    line-height: var(--line-xlarge);
}

.extras_seeMore__11rXb {
    color: var(--color-alice);
    font-weight: var(--font-weight-semibold);
    font-weight: 14px;
    text-decoration: underline;
    cursor: pointer;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: nowrap;
    margin-top: var(--margin-medium);
    margin-bottom: var(--margin-medium);
}

@media screen and (min-width: 1360px) {
    .extras_extrasItems__11jTe {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}

.extras-item_itemContainer__1C_Ds {
    display: flex;
    flex-direction: column;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-gray-light-border);
    border-radius: 3px;
    padding: 16px;
    cursor: pointer;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    position: relative;
}

.extras-item_itemContainer__1C_Ds:hover {
    opacity: 0.6;
}

.extras-item_itemContainer__1C_Ds:active {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
}

.extras-item_title__3ww2z {
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: 2px;
}

.extras-item_subtitle__27ww4 {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    white-space: normal;
}

.extras-item_subtitle__27ww4 > span {
    font-weight: var(--font-weight-light);
}

.extras-item_selectionIndicator__SDzcr {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    background: var(--color-alice);
    opacity: 0;
    transition: opacity 150ms ease-in-out;
    border-radius: 50rem;
}

.extras-item_selected__2Fq6g {
    border: 1px solid var(--color-gray-darker);
}

.extras-item_selected__2Fq6g .extras-item_selectionIndicator__SDzcr {
    opacity: 1;
}

.booking-review-card_cardHeader__1Qom_ {
    margin-bottom: var(--margin-small);
}

.booking-review-card_profileImg__2uoQk {
    justify-content: center;
    border-radius: 50%;
    margin-right: var(--margin-medium);
    width: 4rem;
    max-height: 4rem;
}
.booking-review-card_userRating__2PF_e {
    justify-content: space-around;
}

.booking-review-card_card__1jTt3 {
    flex: 0 1 24%;
    margin-bottom: 0;
    min-width: 200px;
}

.booking-review-card_description__35diR {
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-light);
}

.stars-rating_component__272W2 {
    display: flex;
    margin-right: 1rem;
}

.stars-rating_filled__12Xl9 {
    width: auto;
    height: 1.2rem;
}

.stars-rating_unfilled__WaSgI {
    fill: white;
    width: auto;
    height: 1.2rem;
}

.booking-reviews_title__1_Zrj {
    margin: unset;
    margin-right: var(--margin-medium);
    font-size: var(--font-xxlarge);
    font-weight: var(--font-weight-semibold);
}
.booking-reviews_subtitle__1HXFv {
    margin: unset;
    font-size: var(--font-medium);
}

.booking-reviews_totalReviews__3hhmH {
    margin: unset;
    margin-left: var(--margin-small);
    color: var(--color-gray-light);
    font-size: var(--font-medium);
}
.booking-reviews_starIcon__3PeQW {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 16px;
    width: 18px;
}

.booking-review-modal_modal__4iaeR {
    padding: 0 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-bottom: 48px;
}

.booking-review-modal_modalHeader__1E9t2 {
    font-size: var(--font-xxlarge);
    font-weight: var(--font-weight-semibold);
    margin: unset;
    background-color: var(--color-white);
}

.booking-review-modal_modalRating__3w9oS {
    align-items: center;
}

.booking-review-modal_avgRating__Dlav6 {
    font-size: var(--font-xlarge);
    font-weight: var(--font-weight-medium);
    margin: unset;
    margin-right: 1rem;
}

.booking-review-modal_ratings__2sy6e {
    font-size: var(--font-xlarge);
    margin: unset;
    color: var(--color-gray-light);
}

.booking-review-modal_icon__1uHUt {
    margin-right: 1rem;
    width: auto;
    height: 1.5rem;
}

.booking-review-modal_reviews__1dXhj {
    border-left: 1px solid var(--color-pearl);
}

.booking-review-modal_reviews__1dXhj > span {
    color: var(--color-grayish);
}

.booking-review-modal_optionalRating__3gQc1 {
    justify-content: space-between;
}
.booking-review-modal_optionalRating__3gQc1 span {
    display: flex;
    font-size: var(--font);
    margin-bottom: 0.5rem;
    width: 100%;
}

.booking-review-modal_modal__4iaeR > .booking-review-modal_container__3-9LD {
    padding: 0;
    width: 100%;
}

.booking-review-modal_container__3-9LD > div:last-child {
    flex-direction: column;
}

.booking-review-modal_container__3-9LD > div:last-child > div:last-child {
    border-left: none;
}

.booking-review-modal_container__3-9LD > .booking-review-modal_modalHeader__1E9t2 {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    top: 0;
    margin-left: -24px;
    width: 100vw;
    padding: 35px 24px 14px 24px;
    box-sizing: border-box;
    border-bottom: 1px solid #9da09d29;
    margin-bottom: 32px;
}

.booking-review-modal_modalHeader__1E9t2 > span {
    font-size: var(--font-medium);
    grid-column: 2 / 3;
    justify-self: center;
    font-weight: var(--font-weight-medium);
}

.booking-review-modal_modalHeader__1E9t2 > svg {
    width: 32px;
    height: 32px;
    stroke-width: 1px;
    stroke: #0b0b0d;
    grid-column: 1 / 2;
}

.booking-review-modal_headerSplitter__3CjSJ {
    margin-top: 14px;
}

.booking-review-modal_splitter__1WtdC {
    margin: 32px 0;
}

@media screen and (min-width: 769px) {
    .booking-review-modal_modal__4iaeR {
        width: 90vw;
        height: 80vh;
        max-height: 768px;
        max-width: 1200px;
        box-sizing: border-box;
        padding-left: 40px;
        padding-right: 40px;
        border-radius: 8px;
    }

    .booking-review-modal_container__3-9LD > div:last-child {
        flex-direction: row;
    }

    .booking-review-modal_container__3-9LD > .booking-review-modal_modalHeader__1E9t2 {
        width: 90vw;
        margin-left: -40px;
        padding-left: 40px;
        padding-right: 40px;
        max-width: 1200px;
    }

    .booking-review-modal_reviews__1dXhj {
        max-height: calc(768px - 114px - 48px);
        height: calc(80vh - 114px - 48px);
        overflow-y: scroll;
    }

    .booking-review-modal_modalRating__3w9oS {
        padding-bottom: 36px;
        border-bottom: 1px solid #9da09d29;
    }

    .booking-review-modal_container__3-9LD > div:nth-child(2) > div:first-child {
        margin-right: 0;
    }

    .booking-review-modal_container__3-9LD > div:nth-child(2) > div:nth-child(2) {
        margin: 0 36px;
        border-left: 1px solid #9da09d29;
        margin-bottom: 0 !important;
    }

    .booking-review-modal_modalHeader__1E9t2 > svg {
        grid-column: 3 / 4;
        justify-self: end;
    }
}

.explanation_container__3jr4B {
    margin-bottom: 120px !important;
}

.explanation_container__3jr4B div {
    margin-bottom: var(--margin-xxxlarge) !important;
}

.explanation_container__3jr4B div:last-child {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
}

@media (min-width: 928px) {
    .explanation_container__3jr4B {
        flex-direction: row !important;
    }

    .explanation_container__3jr4B div {
        margin-bottom: 0 !important;
        margin-right: var(--margin-xlarge) !important;
    }
}

@media (min-width: 1360px) {
    .explanation_container__3jr4B div {
        margin-right: var(--margin-xxxlarge) !important;
    }
}

@media (min-width: 1680px) {
    .explanation_container__3jr4B div {
        margin-right: 184px !important;
    }
}

.item_itemContainer__clIvO {
    max-width: 332px;
}

.item_image__3cLyC {
    margin-bottom: var(--margin-large);
}

.item_title__2BT1J {
    text-align: left;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-large);
    line-height: var(--line-large);
    margin-bottom: 12px;
}

.item_description__2A-A- {
    text-align: left;
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: 24px;
}

.item_button__2NcTW {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

@media (min-width: 928px) {
    .item_itemContainer__clIvO {
        max-width: 282px;
    }
}

@media (min-width: 1360px) {
    .item_itemContainer__clIvO {
        max-width: 332px;
    }
}

.assets-details_locationItem__1RXQ8 > div > svg {
    display: block;
}

.insurances_header__2cY7R {
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--margin-large) !important;
}

.insurances_insuranceItems__38Zyn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.insurances_container__5PQ2x {
    display: flex;
    flex-direction: column;
}

.insurances_splitter__2hqbE {
    margin-top: var(--margin-xlarge);
}

/* 
    using !important because it styles injected html from the database 
    that comes with inline styling
*/
.insurance-card_details__ZhBj1,
.insurance-card_details__ZhBj1 * {
    color: var(--color-grayish) !important;
    font-weight: var(--font-weight-light) !important;
}

.accordion_cardContainer__2DZFN {
    margin-bottom: var(--margin);
    background: #fff;
    box-sizing: border-box;
}

.accordion_cardHeader__21r8B {
    color: #000;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.accordion_cardTitle__uQAR8 {
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-xsmall);
}

.accordion_defaultIconStyles__NO2cL {
    width: 20px;
    height: 16px;
}

.accordion_openedIcon__1nIwb {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.accordion_closedIcon__368wi {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.insurance-option_accordion__1mRQw {
    padding: 20px;
    margin-bottom: 28px;
    border: 1px solid var(--color-gray-light-border);
    border-radius: 4px;
    cursor: pointer;
}

.insurance-option_selected__3wPx0 {
    border-color: var(--color-red);
}

/* 
    using !important because it styles injected html from the database 
    that comes with inline styling
*/
.insurance-option_details__3usEs,
.insurance-option_details__3usEs * {
    color: var(--color-grayish);
    font-weight: var(--font-weight-light);
}

.h2_h2__Fnt-g {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h2);
    margin: 0 0 var(--margin) 0;
    color: var(--color-gray-darker);
}

.assets-extras-insurance_container__x0UPv {
    position: relative;
    margin-bottom: 120px !important;
}

.assets-extras-insurance_card__1tLxF {
    box-shadow: rgb(11 11 13 / 8%) 2px 2px 16px;
    border-radius: 8px;
    width: 400px;
}

.assets-extras-insurance_content__9gAzJ {
    flex: unset !important;
    margin-bottom: var(--margin-xlarge);
    flex-direction: column !important;
    justify-content: space-between;
}

.assets-extras-insurance_contentDetails__1g9vt {
    flex: 1 1 !important;
    margin-right: 0 !important;
    max-width: 800px;
}

.assets-extras-insurance_backIcon__2W9Bv {
    width: 24px;
    height: 24px;
    margin-bottom: var(--margin);
    cursor: pointer;
}

.assets-extras-insurance_pageTitle__2JDPs {
    font-size: var(--font-h4);
    line-height: 40px;
    font-weight: var(--font-weight-semibold);
    margin-bottom: 64px;
}

.assets-extras-insurance_panelContainer__Q44Q4 > div {
    position: -webkit-sticky;
    position: sticky;
    top: var(--margin-xlarge);
    padding: 0;
    width: 100%;
    box-shadow: none;
}

@media screen and (min-width: 692px) {
    .assets-extras-insurance_content__9gAzJ {
        flex-direction: row !important;
    }

    .assets-extras-insurance_panelContainer__Q44Q4,
    .assets-extras-insurance_contentDetails__1g9vt {
        width: calc(50% - var(--margin-xlarge) / 2) !important;
    }

    .assets-extras-insurance_contentDetails__1g9vt {
        margin-right: var(--margin-xlarge) !important;
    }

    .assets-extras-insurance_panelContainer__Q44Q4 {
        max-width: 400px;
        flex: unset;
        width: 50%;
    }

    .assets-extras-insurance_panelContainer__Q44Q4 > div {
        /* padding vars in rem have no 24px - it's not a multiple of 14px */
        padding: 24px;
        box-shadow: rgb(11 11 13 / 8%) 2px 2px 16px;
    }
}

@media screen and (min-width: 928px) {
    .assets-extras-insurance_panelContainer__Q44Q4,
    .assets-extras-insurance_contentDetails__1g9vt {
        width: calc(50% - var(--margin-xxxlarge) / 2) !important;
    }

    .assets-extras-insurance_panelContainer__Q44Q4 {
        flex: unset !important;
    }

    .assets-extras-insurance_contentDetails__1g9vt {
        margin-right: var(--margin-xxxlarge) !important;
    }
}

@media screen and (min-width: 1360px) {
    .assets-extras-insurance_contentDetails__1g9vt {
        margin-right: var(--margin-xxxxlarge) !important;
    }
}

.blog_container__3sa_- {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.bookings_navLinks__8F-Wn {
    color: var(--color-grayish);
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
}

.bookings_link__bape0 {
    font-weight: var(--font-weight-light);
    font-size: var(--font-xsmall);
    color: '#9da0aa';
    margin-right: 0.3rem;
    margin-left: 0.3rem;
    text-decoration: underline;
}

.bookings_title__3IMSQ {
    font-size: var(--font-h4);
    line-height: 40px;
    font-weight: var(--font-weight-semibold);
}

.bookings_headerRow__1I-L3 {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.bookings_historyLink__3bw3d {
    text-decoration: underline;
    font-weight: normal;
    font-size: var(--font);
    color: var(--color-red);
    cursor: pointer;
    line-height: var(--line);
}

.bookings_tabs__2fOSP {
    margin-top: 2rem;
}

.bookings_tab__3Lztu {
    text-align: center;
    font-weight: var(--font-weight-light);
    font-size: 1rem;
    margin: auto !important;
    cursor: pointer;
    color: var(--color-grayish);
    width: 335px;
}

.bookings_bookingSpan__3gT7S {
    display: none;
}

.bookings_active__1LM6v {
    color: var(--color-red);
    font-weight: var(--font-weight-medium);
}

.bookings_border__2Rho2 {
    margin-top: 1rem;
    border-bottom: 1px solid var(--color-grayish);
}

.bookings_activeBorder__386NT {
    border-bottom: 2px solid var(--color-red);
}

.bookings_container__2L3yr {
    margin-bottom: 120px !important;
}

@media screen and (max-width: 329px) {
    .bookings_tab__3Lztu {
        margin-bottom: 20px !important;
    }
}

@media screen and (min-width: 492px) {
}

@media screen and (min-width: 928px) {
    .bookings_bookingSpan__3gT7S {
        display: inline;
    }

    .bookings_tab__3Lztu {
        font-size: var(--font-medium);
    }

    .bookings_headerRow__1I-L3 {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .bookings_historyLink__3bw3d {
        border: 1px solid var(--color-gray-light);
        box-sizing: border-box;
        border-radius: 8px;
        text-decoration: underline;
        padding: 12px 16px;
        font-weight: normal;
        font-size: var(--font);
        color: var(--color-red);
        cursor: pointer;
    }

    .bookings_title__3IMSQ {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
        font-weight: var(--font-weight-semibold);
    }
}

.status-icon_container__CvgB9 {
    display: flex;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    align-content: center;
}

.status-icon_label___EjXm {
    font-weight: var(--font-weight-medium);
    white-space: nowrap;
    font-size: var(--font-xsmall);
    color: var(--color-red);
    line-height: var(--line-xsmall);
    margin-left: 0.5rem;
}

.status-icon_pendingLabel__4zsTn {
    color: var(--color-grayish);
}

.status-icon_completedLabel__1i5lR {
    color: var(--color-black);
}

.status-icon_completedLabel__1i5lR path {
    stroke: var(--color-black);
}

.status-icon_activeLabel__EujR5 {
    color: var(--color-red);
}

.status-icon_failedLabel__3d0qP {
    color: var(--color-red);
}

.status-icon_cancelledLabel__2PUP8 {
    color: var(--color-black);
}

@media (max-width: 692px) {
    .booking-card_photoDefault__k4ToU {
        margin-bottom: 10px;
    }
}

.link_link__3H3Vw {
    font-family: var(--font-family-secondary);
    color: var(--color-gray-dark);
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-caption);
    text-decoration: none;
    transition: opacity 0.2s;
}

.link_link__3H3Vw:hover {
    opacity: 0.6;
}

.link_link__3H3Vw:active {
    opacity: 0.8;
}

.breadcrumb_breadcrumb__3kPLk > div {
    flex-direction: row;
    align-items: center;
    margin-right: 0 !important;
    flex: unset !important;
}

.breadcrumb_breadcrumb__3kPLk > div > a,
.breadcrumb_breadcrumb__3kPLk > div > span {
    margin-bottom: 0;
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    font-family: 'Poppins';
    color: var(--color-grayish);
    text-decoration: underline;
    text-underline-offset: 1px;
}

.breadcrumb_breadcrumb__3kPLk > div:not(:last-child) {
    margin-right: 0;
}

.breadcrumb_breadcrumb__3kPLk > div > .breadcrumb_slash__1LoC- {
    margin: 0 4px;
    text-decoration: none;
}

.pageSubtitle_text__MPT09 {
    font-size: var(--font-h4);
    font-weight: var(--font-weight-extra-light);
    line-height: 40px;
    color: var(--color-black);
    margin: 0;
}

@media screen and (min-width: 1680px) {
    .pageSubtitle_text__MPT09 {
        font-size: 40px;
        line-height: 50px;
    }
}

.pageTitle_titleContainer__26NKB {
    margin-top: 16px;
    margin-bottom: 40px;
}

.pageTitle_title__2Sngh {
    font-weight: var(--font-weight-semibold) !important;
    font-size: var(--font-h4);
    line-height: 40px;
    margin: 0;
    display: block;
}

@media screen and (min-width: 928px) {
    .pageTitle_title__2Sngh {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
    }
}

.pageTemplate_container__2Xhey {
    position: relative;
    max-width: 100vw;
    width: 100%;
    padding: 0 var(--padding-medium);
}

.pageTemplate_container__2Xhey > * {
    color: white;
}

.pageTemplate_withBackgroundSub__1uPQC {
    margin-bottom: 140px;
    margin-top: 100px;
    max-width: 600px;
    font-size: var(--font-h4);
    line-height: var(--line-h4);
}

@media (max-width: 324px) {
    .pageTemplate_withBackgroundSub__1uPQC {
        margin-bottom: 100px !important;
    }
}

@media (max-width: 692px) {
    .pageTemplate_withBackgroundSub__1uPQC {
        font-size: var(--font-xxxlarge);
        margin-bottom: 70px;
    }
}

@media (max-width: 324px) {
    .pageTemplate_withBackgroundSub__1uPQC {
        margin-bottom: 100px !important;
    }
}

@media (max-width: 692px) {
    .pageTemplate_withBackgroundSub__1uPQC {
        font-size: var(--font-xxxlarge);
        margin-top: 70px;
        margin-bottom: 160px;
    }
}

.pageTemplate_withBackground__1iLAu {
    margin-bottom: 300px;
}

@media (min-width: 492px) {
    .pageTemplate_container__2Xhey {
        max-width: var(--page-width);
        padding: 0;
    }
}

@media (min-width: 692px) {
    .pageTemplate_withBackgroundSub__1uPQC {
        max-width: 480px;
    }
}

@media (min-width: 928px) {
    .pageTemplate_titleContainer__1mLvB {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media (min-width: 1360px) {
    .pageTemplate_withBackground__1iLAu {
        margin-bottom: 440px;
    }
}

@media (min-width: 2560px) {
    .pageTemplate_withBackground__1iLAu {
        margin-bottom: 550px;
    }
}

.business_pageTitle__uIGPX > span,
.business_breadcrumbs__1lx8Y > div > a,
.business_breadcrumbs__1lx8Y > div > * {
    color: var(--color-white);
}

.business_breadcrumbs__1lx8Y {
    margin-top: 32px;
}

.business_sectionRow__HGshq {
    margin-bottom: 80px !important;
    position: relative;
}

.business_buttonBlack__2Nz1P {
    height: 48px;
    width: 216px;
    border-radius: 88px;
    font-size: var(--font);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    font-weight: var(--font-weight-medium);
    background-color: var(--color-black);
    color: var(--color-white);
    text-decoration: none;
}

.business_hero__TihqI {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 720px;
    text-align: center;
}

.business_heroTitle__QZNws {
    text-align: center;
    font-size: var(--font-h4) !important;
    line-height: 40px !important;
}

.business_background__1lx1U {
    max-height: 532px !important;
    padding-left: 3px;
    margin-left: -2px;
}

.business_background__1lx1U img {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    -webkit-transform: translate(0, -30%);
            transform: translate(0, -30%);
    min-height: 50rem;
    object-fit: cover;
}

@media only screen and (min-width: 692px) {
    .business_heroTitle__QZNws {
        max-width: 480px;
    }
}

@media only screen and (min-width: 1360px) {
    .business_heroTitle__QZNws {
        max-width: 680px;
        text-align: center;
        font-size: var(--font-h2) !important;
        line-height: var(--line-h2) !important;
    }
}

@media (max-width: 492px) {
    .business_background__1lx1U {
        max-height: 540px !important;
    }
}

@media (max-width: 692px) {
    .business_background__1lx1U {
        max-height: 460px !important;
    }

    .business_heroTitle__QZNws {
        text-align: left;
    }

    .business_hero__TihqI {
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }
}

@media (max-width: 928px) {
    .business_background__1lx1U {
        max-height: 440px !important;
    }
}

@media (max-width: 1360px) {
    .business_background__1lx1U {
        max-height: 500px !important;
    }
}

.intro_sectionInfo__2cdpH {
    line-height: var(--line);
    font-size: var(--font-large);
    display: block;
    margin-bottom: 56px;
    color: var(--color-black);
    font-weight: var(--font-weight-light);
    margin-top: 16px;
}

.intro_fakecard__1FDbT {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    height: 100%;
    max-width: 232px;
    margin-left: 1rem;
}

.intro_buttonBlack__1vfU_ {
    height: 48px;
    width: 216px;
    border-radius: 88px;
    font-size: var(--font);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    font-weight: var(--font-weight-medium);
    background-color: var(--color-black);
    color: var(--color-white);
    text-decoration: none;
}

@media only screen and (min-width: 1360px) {
    .intro_sectionTitle__1iyqG {
        font-size: 40px !important;
        line-height: 50px !important;
    }
}

.sectionTitle_sectionTitle__287CL {
    font-size: var(--font-h4);
    font-weight: var(--font-weight-semibold);
    line-height: 40px;
    color: var(--color-black);
    margin: 0;
    word-break: normal;
}

@media screen and (min-width: 1680px) {
    .sectionTitle_sectionTitle__287CL {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
    }
}

.card_card__nPgXd {
    box-sizing: border-box;
    color: black;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 16px #0b0b0d14;
    border-radius: 16px;
    padding: 2rem;
    min-height: 472px;
}

.card_container__EKQqP {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}

.card_title__2NPkW {
    font-size: var(--font-xxxlarge);
    line-height: var(--line-xlarge);
    font-weight: var(--font-weight-semibold);
}

.card_description__3RBWQ {
    display: block;
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: 16px;
    font-weight: var(--font-weight-light);
}

@media only screen and (min-width: 492px) {
    .card_card__nPgXd {
        min-height: 472px;
    }
}

@media only screen and (min-width: 692px) {
    .card_card__nPgXd {
        min-height: 472px;
    }
}

@media only screen and (min-width: 928px) {
    .card_card__nPgXd {
        min-height: 472px;
    }
}

@media only screen and (min-width: 1360px) {
    .card_card__nPgXd {
        min-height: 463px;
    }
}

@media only screen and (min-width: 1680px) {
    .card_card__nPgXd {
        min-height: 472px;
    }
}

.carouselSimple_carousel__2vk3Q {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    margin-bottom: 90px !important;
}

.carouselSimple_arrow__1Bb_Z {
    position: absolute;
    width: 44px;
    height: 44px;
    stroke: var(--color-black);
    stroke-width: 0.75px !important;
    cursor: pointer;
}

.carouselSimple_arrowRight__3hGtJ {
    right: 10px;
    bottom: -70px;
}

.carouselSimple_arrowLeft__DhFRG {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    left: 10px;
    bottom: -70px;
}

.fleets_sectionTitle__1kWXD {
    text-align: center;
}

@media only screen and (min-width: 1360px) {
    .fleets_sectionTitle__1kWXD {
        font-size: 40px !important;
        line-height: 50px !important;
    }
}

.card_card__2sYbH {
    box-sizing: border-box;
    color: black;
    width: 100%;
    height: 100%;
    box-shadow: 0 4px 16px #0b0b0d14;
    border-radius: 16px;
    min-height: 500px;
    overflow: hidden !important;
}

.card_container__3vtUc {
    margin-bottom: 3rem;
    padding-right: 0;
    padding-left: 0;
}

.card_stats__3vuSW {
    display: flex;
    align-items: center;
    flex: 0 1 !important;
}

.card_stats__3vuSW span {
    margin-left: 0.75rem;
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    color: #9da0aa;
    white-space: nowrap;
}

.card_imageContainer__2nII_ {
    position: relative;
    height: 320px;
}

.card_rectangleLeft__21lef {
    position: absolute;
    bottom: -3rem;
    left: -13rem;
}

.card_rectangleRight__1W9IK {
    position: absolute;
    top: -15rem;
    right: -10rem;
}

.card_header__wqBR- {
    padding: 2rem;
}

.card_title__2hXyB {
    font-size: var(--font-xxlarge);
    font-weight: var(--font-weight-semibold);
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.card_description__1X0YN {
    display: block;
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}

.card_description__1X0YN span {
    color: var(--color-red);
}

@media only screen and (min-width: 424px) {
    .card_card__2sYbH {
        min-height: 564px;
    }
    .card_imageContainer__2nII_ {
        height: 420px;
    }
    .card_rectangleLeft__21lef {
        bottom: 0rem;
        left: -13rem;
    }

    .card_rectangleRight__1W9IK {
        top: -14rem;
        right: -10rem;
    }
    .card_title__2hXyB {
        font-size: var(--font-h4);
    }

    .card_description__1X0YN {
        font-size: var(--font-medium);
    }

    .card_stats__3vuSW span {
        font-size: var(--font-medium);
    }
}
@media only screen and (min-width: 692px) {
    .card_card__2sYbH {
        min-height: 444px;
    }
    .card_imageContainer__2nII_ {
        height: 300px;
    }
    .card_rectangleLeft__21lef {
        bottom: -3rem;
        left: -15rem;
    }

    .card_rectangleRight__1W9IK {
        top: -15rem;
        right: -15rem;
    }
    .card_title__2hXyB {
        font-size: var(--font-xxlarge);
    }

    .card_description__1X0YN {
        font-size: var(--font);
    }

    .card_stats__3vuSW span {
        font-size: var(--font);
    }

    .card_container__3vtUc {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
    }
}
@media only screen and (min-width: 928px) {
    .card_card__2sYbH {
        min-height: 564px;
    }
    .card_imageContainer__2nII_ {
        height: 400px;
    }
    .card_rectangleLeft__21lef {
        bottom: -3rem;
        left: -13rem;
    }

    .card_rectangleRight__1W9IK {
        top: -15rem;
        right: -10rem;
    }
    .card_description__1X0YN {
        font-size: var(--font-medium);
    }

    .card_stats__3vuSW span {
        font-size: var(--font);
    }
}
@media only screen and (min-width: 1360px) {
    .card_card__2sYbH {
        min-height: 540px;
    }
    .card_imageContainer__2nII_ {
        height: 340px;
    }
    .card_rectangleLeft__21lef {
        bottom: -3rem;
        left: -13rem;
    }

    .card_rectangleRight__1W9IK {
        top: -15rem;
        right: -10rem;
    }

    .card_stats__3vuSW span {
        font-size: var(--font);
    }
}

@media only screen and (min-width: 1680px) {
    .card_card__2sYbH {
        min-height: 564px;
    }

    .card_imageContainer__2nII_ {
        height: 420px;
    }

    .card_title__2hXyB {
        font-size: var(--font-h4);
    }

    .card_description__1X0YN {
        font-size: var(--font-medium);
    }
    .card_rectangleLeft__21lef {
        bottom: 0rem;
        left: -13rem;
    }

    .card_rectangleRight__1W9IK {
        top: -15rem;
        right: -10rem;
    }

    .card_stats__3vuSW span {
        font-size: var(--font-medium);
    }
}

.car-sharing_container__2RoQk {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.career_container__2CsWJ {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.company_container__1IR8r {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.corporate-profile_container__3byEv {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.custom-search_container__WyAGN {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.driver_container__VFPWR {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.roundedAccordion_cardContainer__Oy6y5 {
    width: 70%;
    max-width: 1000px;
    margin-bottom: var(--margin);
    background: #fff;
    border-radius: 10px;
    padding: 12px 24px;
    box-sizing: border-box;
}

.roundedAccordion_cardHeader__14BPS {
    color: #000;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.roundedAccordion_cardTitle__mbgEZ {
    font-size: var(--font-medium);
    font-weight: var(--font-weight-medium);
}

.roundedAccordion_cardIcon__4vj11 {
    font-size: var(--font-h4);
}

.roundedAccordion_contentPlaceholder__3FlrR {
    padding: 16px 40px 16px 0;
    font-size: 1.145rem;
    color: var(--color-gray);
}

@media (max-width: 540px) {
    .roundedAccordion_cardContainer__Oy6y5 {
        width: 100%;
    }

    .roundedAccordion_cardTitle__mbgEZ {
        font-size: 1.145rem;
    }

    .roundedAccordion_contentPlaceholder__3FlrR {
        padding: 1rem 1rem 1rem 0;
        font-size: 0.975rem;
    }
}

@media (min-width: 492px) {
    .roundedAccordion_cardContainer__Oy6y5 {
        padding: 16px 32px;
    }
}

@media (min-width: 1360px) {
    .roundedAccordion_cardContainer__Oy6y5 {
        margin-bottom: var(--margin-medium);
    }
}

.faq-feedback_container__3HYhs {
    display: flex;
    margin: 1rem;
    font-size: 2rem;
    max-width: var(--page-max-width);
    width: var(--page-width);
    margin: 0 auto 120px auto;
}

.faq-feedback_sectionHeader__2Qqkw {
    max-width: 864px;
    margin: 20px 0 40px;
}

.faq-feedback_sectionTitle__5FDpF {
    font-size: var(--font-xlarge);
    line-height: var(--line-xxlarge);
    font-weight: var(--font-weight-semibold);
    margin: 0;
}

.faq-feedback_sectionDescription__SWE8E {
    font-size: var(--font);
    line-height: var(--line);
    margin-top: 16px;
    font-weight: var(--font-weight-light);
}

.faq-feedback_title__2pKq0 {
    margin-top: 16px;
    margin-bottom: 40px;
}

.faq-feedback_title__2pKq0 > span {
    font-weight: var(--font-weight-semibold) !important;
    font-size: var(--font-xxxlarge);
}

.faq-feedback_container__3HYhs > .faq-feedback_accordionContainer__29__o {
    flex-direction: column;
}

.faq-feedback_accordionContainer__29__o > div {
    margin-right: 0 !important;
    width: 100%;
    max-width: 864px;
}

.faq-feedback_faqAccordion__1FyXz {
    box-shadow: 0 16px 80px #00000014;
    border-radius: 8px;
    margin-bottom: 16px;
}

.faq-feedback_faqAccordion__1FyXz strong {
    font-weight: var(--font-weight-medium);
}

.faq-feedback_faqAccordion__1FyXz > header > span:first-child {
    font-size: var(--font);
}

.faq-feedback_faqAccordion__1FyXz > header > span:last-child {
    box-sizing: border-box;
    padding-left: 32px;
}

.faq-feedback_faqAccordion__1FyXz > section p {
    font-size: var(--font-small);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}

.faq-feedback_faqAccordion__1FyXz > section p:not(:last-child) {
    margin-bottom: 12px;
}

.faq-feedback_faqAccordion__1FyXz > section ol {
    padding: revert;
}

.faq-feedback_faqAccordion__1FyXz > section ul {
    padding: revert;
}

.faq-feedback_viewMore__2kEba {
    border-radius: 48px;
    width: 206px;
    margin-top: 40px;
    font-size: var(--font);
    height: 44px;
}

@media screen and (min-width: 928px) {
    .faq-feedback_pageTitle__21H2w span {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
    }

    .faq-feedback_sectionHeader__2Qqkw {
        margin: 40px 0;
    }

    .faq-feedback_sectionTitle__5FDpF {
        font-size: var(--font-h4);
        line-height: 40px;
    }

    .faq-feedback_title__2pKq0 > span {
        font-size: var(--font-h4);
    }

    .faq-feedback_faqAccordion__1FyXz > header > span:first-child {
        font-size: var(--font-large);
    }

    .faq-feedback_faqAccordion__1FyXz > section p {
        font-size: var(--font);
        line-height: var(--line);
    }

    .faq-feedback_faqAccordion__1FyXz > section p:not(:last-child) {
        margin-bottom: 16px;
    }
}

.gamma_container__3WDK2 {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.help_container__rYMvb {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: opacity 0.25s;
}

.react-toggle-track {
    box-sizing: border-box;
    width: 56px;
    height: 32px;
    padding: 0;
    border-radius: 20px;
    background-color: var(--color-white);
    transition: all 0.2s ease;
    border: var(--color-gray-light) 1px solid;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--color-white);
    border: var(--color-gray-light) 1px solid;
}

.react-toggle--checked .react-toggle-track {
    background-color: var(--color-red);
    border: var(--color-white) 1px solid;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--color-red);
    border: var(--color-white) 1px solid;
}

.react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    transition: opacity 0.25s ease;
}

.react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
    opacity: 0;
}

.react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 4px;
    left: 3px;
    width: 24px;
    height: 24px;
    /* border: 1px solid #4D4D4D; */
    border-radius: 50%;
    background-color: var(--color-gray-light);
    box-sizing: border-box;
    transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
    left: 28px;
    border-color: var(--color-white);
    background-color: var(--color-white);
}

.switcher_switcher__12WWo {
    flex: unset !important;
}

.search-bar_container__28nTG .SingleDatePickerInput,
.search-bar_container__28nTG .SingleDatePicker,
.search-bar_container__28nTG .SingleDatePicker > div:first-child,
.search-bar_container__28nTG .DateInput input,
.search-bar_container__28nTG .DateInput {
    height: 100%;
    width: 100%;
}

.search-bar_container__28nTG #DateTimePicker_DateStart .DateInpu,
.search-bar_container__28nTG #DateTimePicker_DateEnd .DateInpu {
    cursor: default;
}

.search-bar_container__28nTG {
    z-index: 1;
    border-radius: 20px;
    background-color: #ffffff;
    padding: 20px;
    position: relative;
    box-shadow: #00000012 0 25px 84px;
}

.search-bar_container__28nTG > .search-bar_switcherRow__2b4Dx {
    margin-top: 12px;
    display: none;
}

.search-bar_switcherRow__2b4Dx > div {
    display: flex;
    align-items: center;
}

.search-bar_switcherRow__2b4Dx > div span {
    margin-bottom: 0;
}

.search-bar_container__28nTG .search-bar_row__1YTy8 > div:not(:first-child) {
    flex: 0.5 1;
}

.search-bar_wrapper__1v2FH {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.search-bar_division__3EOyd {
    padding: 0rem;
    margin-right: var(--margin) !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: stretch;
    transition: border ease-in-out 1s;
    flex: 0.2 1;
}

.search-bar_lastDivision__3LiMS {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: unset !important;
    margin-top: 28px;
}

.search-bar_lastDivisionIcon__3MV-V {
    padding: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    background: black;
    border-radius: 15px;
    cursor: pointer;
    min-width: 2rem;
    min-height: 2rem;
    margin-right: 0.35rem;
    width: 7rem;
    background-color: #e14436;
    color: #fff;
    height: 31px;
    font-weight: var(--font-weight-medium);
}

.search-bar_lastDivisionIcon__3MV-V:active {
    -webkit-transform: scale(0.96);
            transform: scale(0.96);
}

.search-bar_division__3EOyd > .search-bar_serachInputReturn__3aMon {
    margin-top: 20px;
    display: none;
}

.search-bar_searchInput__ACs-i > div {
    width: 100%;
    margin: 0 !important;
    max-width: 85%;
}

.search-bar_searchInput__ACs-i input[type='text'],
.search-bar_searchInput__ACs-i input[type='password'] {
    outline: 0;
    border-width: 0;
    border-radius: 0;
    margin-bottom: 0;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.search-bar_searchInput__ACs-i input::-webkit-input-placeholder {
    color: var(--color-gray-light);
}

.search-bar_searchInput__ACs-i input::placeholder {
    color: var(--color-gray-light);
}

.search-bar_searchInput__ACs-i > ul {
    width: 100%;
    top: 4.4rem;
    left: 0;
    transition: height ease-in-out 250ms;
}

.search-bar_inputIcon__3r5aP {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    color: var(--color-gray-light);
    stroke-width: 1.5;
}

.search-bar_centeringHelper__3JvEb {
    display: none;
}

.search-bar_dropdown__2m2dL {
    position: absolute;
    right: 1.2rem;
    z-index: -1;
    left: 1.2rem;
    background: var(--color-white);
    border-radius: var(--radius);
    height: 0;
    opacity: 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
    transition: height ease-in-out 250ms;
    padding: var(--margin);
    overflow: scroll;
}

.search-bar_focus__1RRGT {
    top: 4rem;
    height: 200px;
    opacity: 1;
    z-index: 5;
}

.search-bar_whereText__1v0U3 {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    margin: 0 0 8px;
}

.search-bar_datesText__3O7R8 {
    flex: 0 1 !important;
    margin-right: var(--margin-internal) !important;
    font-family: var(--font-family-secondary);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    margin: 0 0 8px;
}

.search-bar_dateInput__heS34 {
    cursor: pointer;
}

.CalendarDay__selected {
    background-color: var(--color-gray-darker);
    border: none !important;
    outline: none;
}

.search-bar_SingleDatePicker__3cWhB {
    height: 10px !important;
}

.search-bar_returnOptions__tYWQk {
    margin-left: 16px;
    display: flex;
    align-items: center;
}

.search-bar_returnOptions__tYWQk > .search-bar_radioContainer__ogMq- {
    margin-top: 0;
    flex-direction: row;
}

.search-bar_returnOptions__tYWQk span {
    font-weight: var(--font-weight-light);
}

.search-bar_radioContainer__ogMq- > div {
    flex: unset !important;
    margin: 0;
    margin-left: 24px;
}

.search-bar_radioContainer__ogMq- > div > input {
    border-radius: 4px;
    cursor: pointer;
}

.search-bar_radioContainer__ogMq- > div > input::before {
    color: var(--color-white);
    z-index: 100;
    box-shadow: none;
    height: 7px;
    width: 12px;
    border-left: var(--color-white) solid 2px;
    border-bottom: var(--color-white) solid 2px;
    border-radius: 0;
    border-bottom-left-radius: 2px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg) !important;
    margin-bottom: 4px;
}

.search-bar_radioContainer__ogMq- > div > input:checked {
    border-radius: 4px;
    background-color: var(--color-red);
}

.search-bar_radioContainer__ogMq- > div > span {
    font-size: var(--font-xsmall);
}

@media (max-width: 320px) {
    .search-bar_division__3EOyd:nth-child(1) {
        display: flex !important;
    }

    .search-bar_locationText__3BV3y {
        display: none;
    }
}

@media (min-width: 321px) and (max-width: 491px) {
    .search-bar_division__3EOyd:nth-child(1) {
        display: flex !important;
    }

    .search-bar_locationText__3BV3y {
        display: none;
    }
}

@media (min-width: 492px) and (max-width: 691px) {
    .search-bar_division__3EOyd:nth-child(1) {
        display: flex !important;
        margin-right: 0 !important;
        border-right: 0 !important;
    }

    .search-bar_lastDivision__3LiMS {
        margin-left: 1rem;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .search-bar_division__3EOyd:first-child:hover {
        border-right: initial !important;
        background: initial !important;
        border-radius: initial !important;
    }
}

@media (max-width: 1359px) {
    .search-bar_division__3EOyd {
        flex: 0.25 1;
    }
}

@media (max-width: 927px) {
    .search-bar_whereText__1v0U3 {
        font-size: 0.9rem;
    }

    .search-bar_division__3EOyd {
        flex: 1 1 !important;
    }

    .search-bar_division__3EOyd:not(:first-child) {
        display: none !important;
    }
}

@media (max-width: 927px) {
    .search-bar_container__28nTG {
        padding: 0.25rem;
        justify-content: center;
        border-radius: 2.5rem;
        border: var(--color-gray-light) solid 1px;
    }

    .search-bar_lastDivision__3LiMS {
        display: none;
    }

    .search-bar_whereText__1v0U3 {
        display: none;
    }

    .search-bar_searchInput__ACs-i {
        margin: 0 !important;
        border: none;
        justify-content: center;
        position: relative;
    }

    .search-bar_inputIcon__3r5aP {
        position: absolute;
        top: 7px;
        left: calc(50% - 100px);
        z-index: 10;
    }

    .search-bar_searchInput__ACs-i > div {
        flex: 1 1 !important;
    }

    .search-bar_searchInput__ACs-i input::-webkit-input-placeholder {
        font-size: var(--font-small) !important;
        color: var(--color-black) !important;
        font-weight: var(--font-weight-light) !important;
        text-align: center;
    }

    .search-bar_searchInput__ACs-i input::placeholder {
        font-size: var(--font-small) !important;
        color: var(--color-black) !important;
        font-weight: var(--font-weight-light) !important;
        text-align: center;
    }

    .search-bar_division__3EOyd {
        margin: 0;
        margin-right: 0 !important;
    }

    .search-bar_inputIcon__3r5aP {
        margin: 0;
    }

    .search-bar_centeringHelper__3JvEb {
        display: block;
        width: 10px;
        background-color: transparent;
        height: 10px;
        width: 24.2px;
        flex: none !important;
        margin: 0 !important;
    }
}

@media (min-width: 928px) {
    .search-bar_container__28nTG > .search-bar_switcherRow__2b4Dx {
        display: block;
    }

    .search-bar_division__3EOyd > .search-bar_serachInputReturn__3aMon {
        display: flex;
    }

    .search-bar_error__NTCcX {
        margin-top: 4px;
        color: var(--color-red);
    }
}

@media (min-width: 1360px) {
    .search-bar_division__3EOyd > div:nth-child(2),
    .search-bar_division__3EOyd > div:nth-child(3) {
        box-sizing: border-box;
        height: 56px;
        align-items: center;
    }

    .search-bar_division__3EOyd > div:nth-child(2) input {
        font-size: var(--font);
    }

    .search-bar_division__3EOyd {
        margin-right: var(--margin-medium) !important;
    }

    .search-bar_lastDivision__3LiMS {
        flex: 0.2 1 !important;
        margin-top: 32px;
    }

    .search-bar_lastDivisionIcon__3MV-V {
        height: 56px;
        box-sizing: border-box;
        width: 160px;
        font-size: var(--font);
        line-height: var(--line);
    }

    .search-bar_whereText__1v0U3 {
        margin: 0 0 12px;
    }

    .search-bar_datesText__3O7R8 {
        margin: 0 0 12px;
    }

    .search-bar_container__28nTG {
        padding: 24px;
    }
}

@media (min-width: 1680px) {
    .search-bar_container__28nTG {
        padding: 32px;
    }

    .search-bar_lastDivision__3LiMS {
        margin-top: 36px;
    }

    .search-bar_lastDivisionIcon__3MV-V {
        font-size: var(--font-medium);
        line-height: var(--line-medium);
    }

    .search-bar_whereText__1v0U3,
    .search-bar_datesText__3O7R8 {
        font-size: var(--font);
        line-height: var(--line);
    }
}

.search-bar_mobileInputPlaceholder__38wGw {
    font-size: var(--font-small);
    line-height: var(--line-small);
}

.search-bar_mobileInputIcon__18nJO {
    width: 20px;
    height: 20px;
    margin-right: var(--margin-small);
    color: var(--color-gray-light);
    stroke-width: 1.5;
}

.location-modal_title__18VCf {
    font-size: var(--font-medium);
    line-height: var(--line);
    font-weight: bold;
    font-family: var(--font-family-secondary);
    margin-bottom: 24px;
}

.location-modal_icon__2NNtw {
    align-self: center;
    width: 56px;
    height: 56px;
    margin-bottom: 24px;
    margin-top: 24px;
}

.location-modal_description__mbOBo {
    font-size: var(--font-small);
    line-height: var(--line-xsmall);
    font-weight: lighter;
    font-family: var(--font-family-secondary);
    margin-bottom: 32px;
}

.location-modal_container__bDY8I {
    text-align: center;
    border-radius: 8px;
    width: 400px;
}

@media screen and (max-width: 691px) {
    .location-modal_container__bDY8I {
        text-align: center;
        border-radius: 8px;
        max-width: 400px;
        width: calc(100% - 48px);
        margin: auto;
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
        bottom: unset !important;
    }
}

.intro-banner_container__1a_7t {
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
    border-radius: 20px;
    max-width: 49%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28rem;
}

.intro-banner_title__3AqmD {
    text-align: start;
    line-height: var(--line-h4);
    font-family: var(--font-family-secondary);
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 24px;
}

.intro-banner_description__sUd5O {
    font-weight: var(--font-weight-light);
    line-height: var(--line);
    font-size: var(--font);
    font-family: var(--font-family-secondary);
    margin-bottom: var(--margin-large);
}

@media screen and (min-width: 928px) {
    .intro-banner_container__1a_7t {
        margin-top: 0;
    }

    .intro-banner_title__3AqmD {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 16px;
    }
}

@media screen and (min-width: 1360px) {
    .intro-banner_title__3AqmD {
        font-size: 56px;
        line-height: 66px;
    }

    .intro-banner_description__sUd5O {
        max-width: 460px;
    }

    .intro-banner_container__1a_7t {
        height: 32rem;
    }
}

@media screen and (min-width: 1680px) {
    .intro-banner_title__3AqmD {
        font-size: var(--font-h1);
        line-height: 82px;
    }

    .intro-banner_description__sUd5O {
        font-size: var(--font-medium);
        line-height: var(--line-large);
    }

    .intro-banner_container__1a_7t {
        height: 42rem;
    }

    .intro-banner_howItWorksButton__2xZ4I {
        font-size: var(--font-medium) !important;
        line-height: var(--line-medium);
    }
}

@media screen and (max-width: 927px) {
    .intro-banner_container__1a_7t {
        max-width: unset;
        height: unset;
        margin-top: 3rem;
    }
}

.car-banner_car__22kEW {
    height: 37vw;
    align-self: center;
    position: absolute;
    right: -11vw;
    top: 10vw;
    bottom: 0;
    margin: auto;
    animation: normal;
    animation-name: car-banner_slideLeft__2rwym;
    animation-duration: 2s;
    -webkit-animation: normal;
    -webkit-animation-name: car-banner_slideLeft__2rwym;
    -webkit-animation-duration: 2s;
}

.car-banner_banner__3ACMT {
    background-image: url(/static/media/intro_banner.d4eb94e0.svg);
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    width: 100%;
    height: 50vw;
    min-height: 40rem;
    z-index: -1;
    right: 0;
    position: absolute;
    left: 0;
    top: 12vh;
    bottom: 0;
    overflow: hidden;
}

.car-banner_wide__19A6b {
    background-image: url(/static/media/intro_banner_wide.de8fb50f.svg) !important;
    width: 152vw;
}

.car-banner_relative__3lBmx {
    position: relative;
}

.car-banner_relative__3lBmx .car-banner_wide__19A6b {
    top: -10.5rem !important;
    height: 118vw !important;
    position: relative !important;
}

.car-banner_relative__3lBmx .car-banner_wide__19A6b .car-banner_car__22kEW {
    height: 86vw !important;
    top: 20vw !important;
    right: 1rem;
}

.car-banner_relative__3lBmx .car-banner_wide__19A6b .car-banner_banner__3ACMT {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
}

.car-banner_carBannerRectangleMd__2xGXk {
    position: absolute;
    top: 37%;
    left: -11rem;
}

.car-banner_carBannerRectangleSm__3GLSV {
    position: absolute;
    top: 8rem;
    left: 2rem;
}

@media screen and (min-width: 692px) {
    .car-banner_relative__3lBmx .car-banner_wide__19A6b .car-banner_car__22kEW {
        top: 0vw !important;
        right: -8rem;
    }

    .car-banner_relative__3lBmx .car-banner_wide__19A6b {
        left: -8rem;
        height: 97vw !important;
    }
}

@media screen and (min-width: 1360px) {
    .car-banner_car__22kEW {
        top: 6vw;
    }

    .car-banner_banner__3ACMT {
        height: 55vw;
        min-height: unset;
    }
}

@media screen and (min-width: 1680px) {
    .car-banner_car__22kEW {
        height: 48rem;
        right: -11vw;
        top: 6rem;
    }

    .car-banner_banner__3ACMT {
        height: 65rem;
        right: 0;
        left: 0;
    }
}

@keyframes car-banner_slideLeft__2rwym {
    0% {
        -webkit-transform: translateX(60rem);
                transform: translateX(60rem);
    }
    100% {
        -webkit-transform: translateX(0rem);
                transform: translateX(0rem);
    }
}

@-webkit-keyframes car-banner_slideLeft__2rwym {
    0% {
        -webkit-transform: translateX(60rem);
                transform: translateX(60rem);
    }
    100% {
        -webkit-transform: translateX(0rem);
                transform: translateX(0rem);
    }
}

:root {
    --color-alice: #e04335;
    --color-primary: #280f55;
    --color-purple: #280f55;
    --color-purple-t-dark: #280f55cc;

    --color-red: #e14436;
    --color-dark-red: #ac362c;
    --color-white: #ffffff;
    --color-gray: #70798c;
    --color-gray-shadow: #73737380;
    --color-pearl: #f5f1ed;
    --color-beige: #c7bfab;
    --color-gray-darker: #0b0b0d;
    --color-gray-dark: #3e424d;

    --color-gray-transparent: #70798c66;

    --color-grayish: #9da0aa;
    --color-gray-light: #b1b5c0;
    --color-gray-light-border: #e2e3e7;

    --color-gray-lighter: #f2f2f4;
    --color-gray-faded: #f9f9f9;
    --color-black: #0b0b0d;

    --color-gray-light-datepicker: #e4e7e7;
    --color-gray-datepicker: #cacccd;
}

:root {
    --font-family: 'Open Sans', sans-serif;
    --font-family-secondary: 'Poppins', sans-serif !important;

    /* FONT WEIGHT */
    --font-weight-bold: 700 !important;
    --font-weight-semibold: 600 !important;
    --font-weight-medium: 500 !important;
    --font-weight-regular: 400 !important;
    --font-weight-light: 300 !important;
    --font-weight-extra-light: 200 !important;

    /* DEFAULT FONTS */
    --font-xxsmall: 12px;
    --font-xsmall: 14px;
    --font-small: 15px;
    --font: 16px;
    --font-medium: 18px;
    --font-large: 20px;
    --font-xlarge: 22px;
    --font-xxlarge: 24px;
    --font-xxxlarge: 26px;

    /* LARGER FONTS */
    --font-h5: 28px;
    --font-h4: 32px;
    --font-h3: 48px;
    --font-h2: 60px;
    --font-h1: 72px;

    /* DEFAULT LINE-HEIGHTS */
    --line-xsmall: 20px;
    --line-small: 22px;
    --line: 24px;
    --line-medium: 26px;
    --line-large: 28px;
    --line-xlarge: 30px;
    --line-xxlarge: 32px;
    --line-xxxlarge: 34px;

    /* LARGER LINE-HEIGHTS */
    --line-h5: 38px;
    --line-h4: 42px;
    --line-h3: 58px;
    --line-h2: 72px;
    --line-h1: 84px;

    /* OLD STUFF [DONT USE] */
    --font-size-h1: 3.5rem;
    --font-size-h2: 2rem;
    --font-size-h3: 1.15rem;
    --font-size-subtitle: 1.15rem;
    --font-size-body: 1rem;
    --font-size-caption: 0.85rem;

    /* OLD STUFF [DONT USE] */
    --ratio: 1.15;
    --p: 0.85rem;
    --input: var(--p);
    --weight-extra-light: 100;

    /* OLD STUFF [DONT USE] */
    --caption: calc(var(--p) / var(--ratio));
    --subcaption: calc(var(--caption) / var(--ratio));
}

@media only screen and (max-width: 768px) {
    :root {
        --ratio: 1.1;
        --input: 16px;
        --p: 14px;
        --caption: 12px;
        --subcaption: 12px;
        --font-size-input: 16px;
    }
}

@media (max-width: 768px) {
    :root {
        --input: 16px;
        --p: 14px;
        --caption: 14px;
        --subcaption: 12px;
        --font-size-input: 16px;
    }
}

.font_family__2aKjM {
    font-family: 'Open Sans', sans-serif;
    font-family: var(--font-family);
}

.font_h1__5p3MT {
    font-weight: 700;
    font-weight: var(--font-weight-bold);
    font-size: 3.5rem;
    font-size: var(--font-size-h1);
    line-height: 1;
    margin: var(--margin-medium) 0;
}

.font_p__OShy_ {
    font-size: 0.85rem;
    font-size: var(--p);
}

.font_caption__3J-oy {
    font-size: calc(0.85rem / 1.15);
    font-size: var(--caption);
}

.font_label__2-BZS {
    font-weight: 400;
    font-weight: var(--font-weight-regular);
    font-style: normal;
    font-size: 0.85rem;
    font-size: var(--font-size-caption);
    color: var(--color-gray);
    margin-bottom: var(--margin-internal);
}

.font_light__2ZvGv {
    font-weight: 300;
    font-weight: var(--font-weight-light);
}

.font_regular__2NdDi {
    font-weight: 400;
    font-weight: var(--font-weight-regular);
}

.font_medium__2hT1T {
    font-weight: 500;
    font-weight: var(--font-weight-medium);
}

.font_semiBold__13vxr {
    font-weight: 600;
    font-weight: var(--font-weight-semibold);
}

.font_bold__2ar35 {
    font-weight: 700;
    font-weight: var(--font-weight-bold);
}

.font_uppercase__18AG_ {
    text-transform: uppercase;
}

:root {
    --padding-small: 0.5rem;
    --padding: 0.7rem;
    --padding-medium: 1.5rem;
    --padding-large: 2rem;
    --padding-xlarge: 3rem;
    --padding-xxlarge: 5rem;

    --xs_space: 4px;
    --margin-internal: 8px;
    --margin-small: 12px;
    --small-extra_space: 14px;
    --margin: 16px;
    --normal-extra_space: 20px;
    --margin-medium: 24px;
    --margin-large: 32px;
    --margin-xlarge: 40px;
    --margin-xxlarge: 48px;
    --margin-xl-space: 56px;
    --xl-extra_space: 64px;
    --margin-xxxlarge: 80px;
    --margin-xxxxlarge: 120px;

    --box-shadow-hover: 0px 2px 1px 0px var(--color-gray-lighter);
    --box-radius: 2px;
    --button-radius: 3px;

    --radius: 2px;
    --input-radius: 40px;

    --tablet-small: 492px;
    --tablet: 692px;
    --desktop-small: 928px;
    --desktop: 1360px;
    --desktop-big: 1680px;
}

.layout_smallPadding__2TiNJ {
    padding: 0.5rem;
    padding: var(--padding-small);
}

.layout_smallMarginBottom__3dUcx {
    margin-bottom: 12px;
    margin-bottom: var(--margin-small);
}

.layout_mediumPaddingHorizontal__YIq1s {
    padding-left: 1.5rem;
    padding-left: var(--padding-medium);
    padding-right: 1.5rem;
    padding-right: var(--padding-medium);
}

.layout_componentPaddingVertical__1TEix {
    padding-top: 0.7rem;
    padding-top: var(--padding);
    padding-bottom: 0.7rem;
    padding-bottom: var(--padding);
}

.layout_internalMarginBottom__2MazN {
    margin-bottom: 8px;
    margin-bottom: var(--margin-internal);
}

.layout_mediumPadding__6xjKO {
    padding: 1.5rem;
    padding: var(--padding-medium);
}

.layout_presentationMarginTop__1b0n_ {
    margin-top: 12px;
    margin-top: var(--margin-small);
}

.layout_componentMarginBottom__2xYO1 {
    margin-bottom: 16px;
    margin-bottom: var(--margin);
}

@media only screen and (max-width: 491px) {
    :root {
        --page-width: calc(100vw - 40px);
        --page-max-width: calc(100vw - 40px);
    }
}

@media only screen and (min-width: 492px) {
    :root {
        --page-width: 444px;
        --page-max-width: 444px;
    }
}

@media only screen and (min-width: 692px) {
    :root {
        --page-width: 644px;
        --page-max-width: 644px;
    }
}

@media only screen and (min-width: 928px) {
    :root {
        --page-width: 880px;
        --page-max-width: 880px;
    }
}

@media only screen and (min-width: 1360px) {
    :root {
        --page-width: 1160px;
        --page-max-width: 1160px;
    }
}

@media only screen and (min-width: 1680px) {
    :root {
        --page-width: 1364px;
        --page-max-width: 1364px;
    }
}

.h1_text__4ur2z {
}

.h1_h1__29yTB {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-h4);
    line-height: 40px;
    margin: var(--margin-medium) 0;
}

@media screen and (min-width: 1360px) {
    .h1_h1__29yTB {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
    }
}

.business_businessContainer__3GvQ3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    background-image: url(/static/media/business_banner.16c279f1.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.business_pageContainer__1ufhx {
    margin-top: 80px;
    margin-bottom: 80px !important;
    flex-direction: row !important;
    justify-content: space-between;
}

.business_descriptionContainer__2anUg {
    width: 50%;
    justify-content: center;
}

.business_cardContainer__3WvJ_ {
    position: relative;
    width: 45%;
    align-items: flex-end;
}

.business_card__3QXee {
    max-width: 400px;
    min-height: 375px;
    padding: 40px 32px;
    position: absolute;
    z-index: 1;
    justify-content: space-between;
}

.business_cardSection__2lLzz {
    align-items: center;
    margin-bottom: 40px;
}

.business_cardSection__2lLzz span {
    margin-top: 32px;
    padding: unset;
    text-align: center;
    font-size: var(--font-medium);
    line-height: var(--line-medium);
}

.business_cardImage__3cm2p {
    width: 200px;
}

.business_title__2FNTt {
    color: #fff;
    margin: unset;
    margin-bottom: 16px;
}

.business_description__1_N4c {
    color: #fff;
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: 32px;
}

.business_cardText__mrTWw span {
    padding: unset;
}

.business_boldText__2vga8 {
    font-weight: var(--font-weight-semibold);
}

.business_lightText__1DwgV {
    font-weight: var(--font-weight-light);
}

@media (hover: hover) and (pointer: fine) {
    .business_button__2bmTK:hover {
        background-color: #fff !important;
        color: #0b0b0d;
    }
}

@media (max-width: 1359px) {
    .business_businessContainer__3GvQ3 {
        justify-content: flex-start;
        min-height: 200px;
    }

    .business_cardContainer__3WvJ_ {
        width: 45%;
        margin-left: 2rem;
    }

    .business_descriptionContainer__2anUg {
        width: 45%;
    }

    .business_cardImage__3cm2p {
        width: 160px;
    }
}

@media (max-width: 927px) {
    .business_pageContainer__1ufhx {
        flex-direction: column !important;
    }

    .business_businessContainer__3GvQ3 {
        flex-direction: column;
        align-items: flex-start;
    }

    .business_descriptionContainer__2anUg {
        width: 100%;
    }

    .business_cardContainer__3WvJ_ {
        margin-top: 2rem;
        margin-left: 0;
        width: 100%;
    }

    .business_card__3QXee {
        margin: 1rem auto 0 auto !important;
        position: relative;
        top: 0;
    }

    .business_cardImage__3cm2p {
        width: 140px;
    }
}

@media screen and (min-width: 692px) {
    .business_pageContainer__1ufhx {
        width: 480px;
    }
}

@media screen and (min-width: 928px) {
    .business_pageContainer__1ufhx {
        width: 100%;
    }

    .business_description__1_N4c {
        margin-bottom: 40px;
    }

    .business_card__3QXee {
        top: -110px;
    }
}

@media screen and (min-width: 1360px) {
    .business_cardSection__2lLzz span {
        font-size: var(--font-large);
        line-height: var(--line-large);
    }

    .business_card__3QXee {
        top: -156px;
    }
}

@media screen and (min-width: 1680px) {
    .business_description__1_N4c {
        font-size: var(--font-medium);
        line-height: var(--line-medium);
    }
}

.how-it-works_container__1mJj9 {
    margin-top: 40px !important;
    position: relative;
}

.how-it-works_section__3PXwJ {
    margin-bottom: 80px !important;
}

.how-it-works_row__iscWp {
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.how-it-works_card__2a3d4 img {
    width: 100%;
    height: auto;
}

.how-it-works_row__iscWp:first-child {
    margin-top: 0;
}

.how-it-works_row__iscWp:nth-child(2) {
    margin: 190px 0;
}

.how-it-works_card__2a3d4 {
    max-width: 100% !important;
}

.how-it-works_cardBody__2-t9W {
    padding: 24px 32px;
}

.how-it-works_cardTitle__1YCI4 h2 {
    margin: 0 0 8px;
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-semibold);
}

.how-it-works_cardText__3XOMB h3 {
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
    color: var(--color-gray);
    margin: unset;
}

@media screen and (max-width: 691px) {
    .how-it-works_row__iscWp {
        flex-direction: column !important;
        align-items: center !important;
    }
    .how-it-works_row__iscWp > div:not(:last-child) {
        margin-right: 0rem !important;
        margin-bottom: 32px !important;
    }

    .how-it-works_section__3PXwJ {
        margin-top: -80px;
    }
}

@media screen and (min-width: 691px) and (max-width: 927px) {
    .how-it-works_row__iscWp {
        flex-direction: column !important;
        align-items: center !important;
    }
    .how-it-works_row__iscWp > div:not(:last-child) {
        margin-right: 0rem !important;
        margin-bottom: 32px !important;
    }

    .how-it-works_section__3PXwJ {
        margin: auto;
    }

    .how-it-works_container__1mJj9 {
        max-width: 480px;
    }

    .how-it-works_header__3Ed6Z {
        display: block;
        margin: unset;
        text-align: center;
    }
}

@media screen and (min-width: 928px) and (max-width: 1359px) {
    .how-it-works_cardText__3XOMB h3 {
        font-size: var(--font) !important;
    }
    .how-it-works_cardTitle__1YCI4 h2 {
        font-size: var(--font-medium) !important;
        line-height: var(--line-medium);
    }
}

@media screen and (min-width: 928px) {
    .how-it-works_cardText__3XOMB h3 {
        font-size: var(--font-small);
        line-height: var(--line-small);
    }

    .how-it-works_container__1mJj9 {
        margin-top: 40px !important;
    }

    .how-it-works_section__3PXwJ {
        margin-top: 80px;
        margin-bottom: 211px !important;
    }

    .how-it-works_container__1mJj9 > .how-it-works_row__iscWp > div:not(:last-child) {
        margin-right: var(--margin-large) !important;
    }

    .how-it-works_cardBody__2-t9W {
        padding: 24px 24px 32px;
    }

    .how-it-works_card__2a3d4 {
        min-height: 375px !important;
    }
}

@media screen and (min-width: 1360px) {
    .how-it-works_section__3PXwJ {
        margin-top: 145px;
        margin-bottom: 226px !important;
    }

    .how-it-works_cardText__3XOMB h3 {
        font-size: var(--font);
        line-height: var(--line);
    }

    .how-it-works_card__2a3d4 {
        min-height: 398px !important;
    }

    .how-it-works_cardBody__2-t9W {
        padding: 32px;
    }

    .how-it-works_container__1mJj9 {
        margin-top: 56px !important;
    }

    .how-it-works_container__1mJj9 > .how-it-works_row__iscWp > div:not(:last-child) {
        margin-right: var(--margin-xlarge) !important;
    }
}

@media screen and (min-width: 1680px) {
    .how-it-works_cardTitle__1YCI4 h2 {
        font-size: var(--font-xxlarge);
        line-height: var(--line-xxlarge);
        margin: 0 0 12px;
    }

    .how-it-works_section__3PXwJ {
        margin-top: 141px;
    }

    .how-it-works_container__1mJj9 {
        margin-top: 80px !important;
    }

    .how-it-works_cardText__3XOMB h3 {
        font-size: var(--font-medium);
        line-height: var(--line-medium);
    }

    .how-it-works_card__2a3d4 {
        min-height: 452px !important;
    }
}

.faq_section__VWNHM {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-white);
    width: 100%;
    position: relative;
    margin-top: 80px;
    margin-bottom: 80px !important;
}

.faq_section__VWNHM > div {
    position: relative;
}

.faq_rectangleSm__LimDF {
    position: absolute;
    top: 0px;
    left: -190px;
}

.faq_rectangleLg__e50g3 {
    position: absolute;
    top: 480px;
    right: -120px;
    width: 220px;
    height: 220px;
}

.faq_content__2pvm_ {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}

.faq_content__2pvm_ li {
    padding: 0.2rem 0;
}

.faq_content__2pvm_ > div {
    width: 100%;
}

.faq_section__VWNHM section {
    overflow: hidden;
}

.faq_button__Re7-s > span {
    font-weight: var(--font-weight-regular);
}

.faq_button__Re7-s:hover > span {
    color: var(--color-white);
}

.faq_titleContainer__12Pir {
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    z-index: 10;
}

.faq_titleContainer__12Pir > span {
    text-align: center;
    margin: 0 0 64px 0;
}

.faq_content__2pvm_ > .faq_faqAccordion__i3yIA {
    box-shadow: 0 16px 80px #00000014;
    border-radius: 8px;
}

.faq_faqAccordion__i3yIA > header > span:first-child {
    font-size: var(--font);
    max-width: 80%;
}

.faq_faqAccordion__i3yIA > section p {
    font-size: var(--font-small);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
}

.faq_faqAccordion__i3yIA > section p:not(:last-child) {
    margin-bottom: 12px;
}

@media screen and (min-width: 692px) {
    .faq_rectangleSm__LimDF {
        left: -150px;
        top: -50px;
    }

    .faq_rectangleLg__e50g3 {
        top: 450px;
        right: -20vw;
    }
}

@media screen and (min-width: 928px) {
    .faq_section__VWNHM {
        margin-top: 220px;
        margin-bottom: 120px !important;
    }

    .faq_faqAccordion__i3yIA > header > span:first-child {
        font-size: var(--font-medium);
    }

    .faq_faqAccordion__i3yIA > section p {
        font-size: var(--font);
        line-height: var(--line);
        font-weight: var(--font-weight-light);
    }

    .faq_faqAccordion__i3yIA > section p:not(:last-child) {
        margin-bottom: 12px;
    }

    .faq_button__Re7-s {
        width: 204px;
    }

    .faq_rectangleSm__LimDF {
        left: -20vw;
        top: 200px;
    }

    .faq_rectangleLg__e50g3 {
        top: 200px;
        right: -18vw;
        width: 300px;
        height: 300px;
    }
}

@media screen and (min-width: 1360px) {
    .faq_section__VWNHM {
        margin-top: 229px;
    }

    .faq_content__2pvm_ {
        width: 1100px;
        margin: auto;
    }

    .faq_rectangleLg__e50g3 {
        right: 0;
        right: calc(0px - 150px - ((100vw - 1280px) / 2));
        top: 200px;
    }

    .faq_rectangleSm__LimDF {
        left: calc(50% - 50vw - 50px);
        top: 200px;
    }
}

@media screen and (min-width: 1680px) {
    .faq_titleContainer__12Pir > span {
        margin: 0 0 80px 0;
    }
}

.home_content__1n19B {
    width: 100%;
    z-index: 0;
    overflow: hidden;
}

@media screen and (max-width: 927px) {
    .home_banner__3LECn {
        display: none;
    }
}

@media screen and (min-width: 928px) {
    .home_bannerWide__18dHx {
        display: none;
    }
}

.imprint_container__3fDxd {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.insurance_container__7oFih {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.not-found_container__2iP21 {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.operations_container__3zhbR {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.partner_container__285Wx {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.policy_container__SxRUa {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.documentsCard_cardHeader__3Crnl {
    align-items: center;
    justify-content: space-between;
}

.documentsCard_cardHeader__3Crnl > span {
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--margin-internal);
}

.documentsCard_description__15w3v {
    font-size: var(--font-small);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
}

.documentsCard_buttonsContainer__rGVLI {
    margin-top: var(--margin-xlarge);
    box-sizing: border-box;
}

.documentsCard_buttonsContainer__rGVLI button {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.documentsCard_buttonsContainer__rGVLI div:not(:first-child) {
    margin-top: var(--margin-medium);
}

.documentsCard_editDocumentButton__ptTGl {
    margin-bottom: var(--margin-medium);
}

.documentsCard_closeIcon__3G7MT {
    visibility: flex;
    cursor: pointer;
}

.documentsCard_editDocumentButton__ptTGl *:not(:first-child) {
    margin-left: 8px;
}

.documentsCard_editDocumentButton__ptTGl :last-child {
    margin-left: 26px;
}

.documentsCard_textNote__3zMPg {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
}

.documentsCard_redIcon__jE_55 {
    color: var(--color-red);
}

.documentsCard_plusIcon__pOZwO {
    width: 24px;
    height: 24px;
    color: var(--color-red);
    margin-right: 8px;
}

.documentsCard_loadingIcon__3spPm {
    color: var(--color-red);
    margin-right: 8px;
    margin-left: 4px;
}

.myWalletCard_container__2ire0 {
    display: flex;
    flex-direction: column;
}

.myWalletCard_cardHeader__26x9l {
    align-items: center;
    margin-bottom: 16px;
}

.myWalletCard_cardHeader__26x9l > span {
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-medium);
    margin: 0;
}

.myWalletCard_description__3iyUz {
    font-size: var(--font-small);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
}

.myWalletCard_accordionContainer__2klrj {
    margin-top: var(--margin);
}

.myWalletCard_accordionHeader__31F5X {
    padding: 12px 0;
    border-bottom: 1px solid var(--color-gray-lighter);
}

.myWalletCard_accountInfo__PlQ5x {
    align-items: center;
    margin: 12px 0;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--color-gray-lighter);
}

.myWalletCard_accountInfo__PlQ5x span:not(:first-child) {
    margin-top: 4px;
}

.myWalletCard_iconsContainer__35nsa {
    align-items: center;
    justify-content: flex-end;
}

.myWalletCard_icon__3VNcT {
    width: 24px;
    height: 24px;
    color: var(--color-red);
    margin-left: 12px;
}

.myWalletCard_link__1Da3D {
    color: var(--color-red);
    margin: 12px 0;
    text-decoration: underline;
    font-size: var(--font-small);
    line-height: var(--line-small);
}

.myWalletCard_mainBilling__-D6ak {
    margin: 12px 0 24px;
}

.myWalletCard_mainBilling__-D6ak span {
    margin-top: 4px;
}

.myWalletCard_mainBilling__-D6ak span:not(:first-child) {
    margin-left: 4px;
}

@media only screen and (min-width: 492px) {
    .myWalletCard_container__2ire0 {
        display: flex;
        flex-direction: column;
    }

    .myWalletCard_cardHeader__26x9l {
        align-items: center;
        margin-bottom: 16px;
    }

    .myWalletCard_cardHeader__26x9l > span {
        font-size: var(--font-large);
        line-height: var(--line-large);
        font-weight: var(--font-weight-medium);
        margin: 0;
    }

    .myWalletCard_description__3iyUz {
        font-size: var(--font-small);
        line-height: var(--line-small);
        font-weight: var(--font-weight-light);
        color: var(--color-grayish);
    }

    .myWalletCard_accordionContainer__2klrj {
        margin-top: var(--margin);
    }

    .myWalletCard_accordionHeader__31F5X {
        padding: 12px 0;
        border-bottom: 1px solid var(--color-gray-lighter);
    }

    .myWalletCard_accountInfo__PlQ5x {
        align-items: center;
        margin: 12px 0;
        padding-bottom: 12px;
        border-bottom: 1px solid var(--color-gray-lighter);
    }

    .myWalletCard_accountInfo__PlQ5x span:not(:first-child) {
        margin-top: 4px;
    }

    .myWalletCard_iconsContainer__35nsa {
        align-items: center;
        justify-content: flex-end;
    }

    .myWalletCard_icon__3VNcT {
        width: 24px;
        height: 24px;
        color: var(--color-red);
        margin-left: 12px;
    }

    .myWalletCard_link__1Da3D {
        color: var(--color-red);
        margin: 12px 0;
        text-decoration: underline;
        font-size: var(--font-small);
        line-height: var(--line-small);
    }

    .myWalletCard_mainBilling__-D6ak {
        margin: 12px 0 24px;
    }

    .myWalletCard_mainBilling__-D6ak span {
        margin-top: 4px;
    }

    .myWalletCard_mainBilling__-D6ak span:not(:first-child) {
        margin-left: 4px;
    }
}

@media only screen and (min-width: 692px) {
    .myWalletCard_container__2ire0 {
        display: flex;
        flex-direction: column;
    }

    .myWalletCard_cardHeader__26x9l {
        align-items: center;
        margin-bottom: 16px;
    }

    .myWalletCard_cardHeader__26x9l > span {
        font-size: var(--font-large);
        line-height: var(--line-large);
        font-weight: var(--font-weight-medium);
        margin: 0;
    }

    .myWalletCard_description__3iyUz {
        font-size: var(--font-small);
        line-height: var(--line-small);
        font-weight: var(--font-weight-light);
        color: var(--color-grayish);
    }

    .myWalletCard_accordionContainer__2klrj {
        margin-top: var(--margin);
    }

    .myWalletCard_accordionHeader__31F5X {
        padding: 12px 0;
        border-bottom: 1px solid var(--color-gray-lighter);
    }

    .myWalletCard_accountInfo__PlQ5x {
        align-items: center;
        margin: 12px 0;
        padding-bottom: 12px;
        border-bottom: 1px solid var(--color-gray-lighter);
    }

    .myWalletCard_accountInfo__PlQ5x span:not(:first-child) {
        margin-top: 4px;
    }

    .myWalletCard_iconsContainer__35nsa {
        align-items: center;
        justify-content: flex-end;
    }

    .myWalletCard_icon__3VNcT {
        width: 24px;
        height: 24px;
        color: var(--color-red);
        margin-left: 12px;
    }

    .myWalletCard_link__1Da3D {
        color: var(--color-red);
        margin: 12px 0;
        text-decoration: underline;
        font-size: var(--font-small);
        line-height: var(--line-small);
    }

    .myWalletCard_mainBilling__-D6ak {
        margin: 12px 0 24px;
    }

    .myWalletCard_mainBilling__-D6ak span {
        margin-top: 4px;
    }

    .myWalletCard_mainBilling__-D6ak span:not(:first-child) {
        margin-left: 4px;
    }
}

@media only screen and (min-width: 928px) {
    .myWalletCard_container__2ire0 {
        display: flex;
        flex-direction: row;
    }

    .myWalletCard_personalWalletCard__3eL3U {
        width: 50%;
        margin-left: var(--margin-xlarge);
    }

    .myWalletCard_businessWalletCard__HoUK6 {
        width: 50%;
    }

    .myWalletCard_cardHeader__26x9l {
        align-items: center;
        margin-bottom: 16px;
    }

    .myWalletCard_cardHeader__26x9l > span {
        font-size: var(--font-large);
        line-height: var(--line-large);
        font-weight: var(--font-weight-medium);
        margin: 0;
    }

    .myWalletCard_description__3iyUz {
        font-size: var(--font-small);
        line-height: var(--line-small);
        font-weight: var(--font-weight-light);
        color: var(--color-grayish);
    }

    .myWalletCard_accordionContainer__2klrj {
        margin-top: var(--margin);
    }

    .myWalletCard_accordionHeader__31F5X {
        padding: 12px 0;
        border-bottom: 1px solid var(--color-gray-lighter);
    }

    .myWalletCard_accountInfo__PlQ5x {
        align-items: center;
        margin: 12px 0;
        padding-bottom: 12px;
        border-bottom: 1px solid var(--color-gray-lighter);
    }

    .myWalletCard_accountInfo__PlQ5x span:not(:first-child) {
        margin-top: 4px;
    }

    .myWalletCard_iconsContainer__35nsa {
        align-items: center;
        justify-content: flex-end;
    }

    .myWalletCard_icon__3VNcT {
        width: 24px;
        height: 24px;
        color: var(--color-red);
        margin-left: 12px;
    }

    .myWalletCard_link__1Da3D {
        color: var(--color-red);
        margin: 12px 0;
        text-decoration: underline;
        font-size: var(--font-small);
        line-height: var(--line-small);
    }

    .myWalletCard_mainBilling__-D6ak {
        margin: 12px 0 24px;
    }

    .myWalletCard_mainBilling__-D6ak span {
        margin-top: 4px;
    }

    .myWalletCard_mainBilling__-D6ak span:not(:first-child) {
        margin-left: 4px;
    }
}

.businessWallet_cardHeader__2fBHT {
    align-items: center;
    margin-bottom: 16px;
}

.businessWallet_cardHeader__2fBHT > span {
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    margin: 0;
}

.businessWallet_description__71Y17 {
    font-size: var(--font-small);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
}

.businessWallet_accordionContainer__3IQaE {
    margin-top: var(--margin);
}

.businessWallet_accordionHeader__IFW9x {
    padding: 12px 0;
    border-bottom: 1px solid var(--color-gray-lighter);
}

.businessWallet_accountInfo__1y4c8 {
    align-items: center;
    margin: 12px 0;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--color-gray-lighter);
}

.businessWallet_accountInfo__1y4c8 span:not(:first-child) {
    margin-top: 4px;
}

.businessWallet_iconsContainer__2bn98 {
    align-items: center;
    justify-content: flex-end;
}

.businessWallet_icon__ZwfP7 {
    width: 24px;
    height: 24px;
    color: var(--color-red);
    margin-left: 12px;
}

.businessWallet_link__1mafz {
    color: var(--color-red);
    margin: 12px 0;
    text-decoration: underline;
    font-size: var(--font-small);
    line-height: var(--line-small);
}

.businessWallet_editLink__p9FYK {
    color: var(--color-red);
    text-decoration: underline;
    font-size: var(--font-small);
    line-height: var(--line-small);
}

.businessWallet_mainBilling__2YEGU {
    margin: 12px 0 24px;
}

.businessWallet_mainBilling__2YEGU span {
    margin-top: 4px;
}

.businessWallet_mainBilling__2YEGU span:not(:first-child) {
    margin-left: 4px;
}

.businessWallet_paymentProfile__3bbsK {
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-xsmall);
    margin-top: var(--margin-large);
}

.businessWallet_briefcaseIcon__29DFT {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.businessWallet_noCards__35pJ- {
    align-items: center;
    margin-top: var(--small-extra_space);
    font-weight: var(--font-weight-medium);
}

.editBillingInfoModal_loadingContainer__28smY {
    flex: 1 1;
    align-items: center;
    justify-content: center;
}

.editBillingInfoModal_loadingSpinner__3Svod {
    width: 48px;
    height: 48px;
    color: var(--color-black);
}

.editBillingInfoModal_mainBilling__2NP-3 input {
    background-color: transparent;
    border: 1px solid var(--color-gray-light-border);
    border-radius: var(--xs_space);
}

.editBillingInfoModal_billingData__3Q3Ho {
    font-style: normal;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    color: black;
}

.editBillingInfoModal_actionRow__2v536 {
    width: 100%;
}

.editBillingInfoModal_cancel__3KS2K {
    align-self: center;
    font-size: var(--font-small);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-small);
    text-decoration: underline;
}

.editBillingInfoModal_cancelBtn__xDtcr {
    display: flex;
    cursor: pointer;
}

.editBillingInfoModal_resetBtn__2BY5d {
    display: none;
}

.editBillingInfoModal_submit__1AH1e {
    flex: 1 1;
}

@media screen and (min-width: 692px) {
    .editBillingInfoModal_actionRow__2v536 {
        width: 100%;
        border-radius: 0px 0px 8px 8px;
    }

    .editBillingInfoModal_cancelBtn__xDtcr {
        display: none;
    }

    .editBillingInfoModal_resetBtn__2BY5d {
        display: flex;
        cursor: pointer;
    }
}

.personalWalletCard_cardHeader__1Y415 {
    align-items: center;
    margin-bottom: 16px;
}

.personalWalletCard_cardHeader__1Y415 > span {
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    margin: 0;
}

.personalWalletCard_trashIcon__s0n2u {
    color: var(--color-red);
}

.personalWalletCard_trashIcon__s0n2u:hover {
    cursor: pointer;
}

.personalWalletCard_description__gVnzv {
    font-size: var(--font-small);
    line-height: var(--line-small);
    font-weight: var(--font-weight-light);
    color: var(--color-grayish);
}

.personalWalletCard_briefcaseIcon__AByFY {
    width: 16px;
    height: 16px;
    color: var(--color-black);
    margin-right: 10px;
}

.personalWalletCard_accordionContainer__jCqdM {
    margin-top: var(--margin);
}

.personalWalletCard_accordionHeader__1k4yH {
    padding: 12px 0;
    border-bottom: 1px solid var(--color-gray-lighter);
}

.personalWalletCard_accountInfo__2fRQB {
    align-items: center;
    border-bottom: 1px solid var(--color-gray-lighter);
    font-weight: var(--font-weight-medium);
    padding-bottom: var(--margin-small);
}

.personalWalletCard_paymentMethodTitle__3x7ey::first-letter {
    text-transform: capitalize;
}

.personalWalletCard_paymentMethodTitle__3x7ey {
    align-items: center;
    margin-top: var(--small-extra_space);
    font-weight: var(--font-weight-medium);
}

.personalWalletCard_accountInfo__2fRQB span:not(:first-child) {
    margin-top: 4px;
    width: 296px;
}

.personalWalletCard_iconsContainer__1qLzK {
    align-items: center;
    justify-content: flex-end;
}

.personalWalletCard_icon__1vEho {
    width: 24px;
    height: 24px;
    color: var(--color-red);
    margin-left: 12px;
}

.personalWalletCard_link__24jDJ {
    color: var(--color-red);
    margin: 12px 0;
    text-decoration: underline;
    font-size: var(--font-small);
    line-height: var(--line-small);
}

.personalWalletCard_mainBilling__3NekG {
    margin: 12px 0 24px;
}

.personalWalletCard_billingData__2hAQA {
    font-weight: var(--font-weight-light) !important;
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
}

.personalWalletCard_mainBilling__3NekG span {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-xsmall);
    margin-bottom: var(--margin-internal);
    line-height: var(--line-xsmall);
}

.personalWalletCard_paymentProfile__1vKEA {
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-xsmall);
    margin-top: var(--margin-large);
}

.personalWalletCard_companyData__1pMcW {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    margin-bottom: var(--margin-internal);
    font-style: normal;
}

.details_checkIcon__1LApL {
    color: var(--color-red);
    margin-left: 8px;
}

.details_container__1o56X {
    margin-left: 32px;
}

.details_container__1o56X strong {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-medium);
}

.details_container__1o56X span {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
    display: flex;
    align-items: center;
}

.details_container__1o56X > div {
    margin-bottom: 16px;
}

.details_link__36l4t {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    font-family: var(--font-family-secondary);
    line-height: var(--line-small);
    text-decoration: underline;
    color: var(--color-red);
}

.details_avatar__2oiLS {
    width: 80px;
    height: 80px;
    margin: 0;
}

.details_editButtons__1Jf6P {
    margin-top: 32px;
}

.details_cancelBtn__2lTx- {
    color: var(--color-grayish);
    border: none;
    text-decoration: underline;
    transition: -webkit-filter 0.2s;
    transition: filter 0.2s;
    transition: filter 0.2s, -webkit-filter 0.2s;
}

.details_cancelBtn__2lTx-:hover {
    box-shadow: none;
    -webkit-filter: opacity(0.5);
            filter: opacity(0.5);
}

@media only screen and (min-width: 928px) {
    .details_avatar__2oiLS {
        width: 96px;
        height: 96px;
    }
}

@media only screen and (min-width: 1360px) {
    .details_avatar__2oiLS {
        width: 160px;
        height: 160px;
    }
}

.phoneInput_phoneInputContainer__2mdaB {
    align-items: center;
    margin-top: 4px;
}

/* Phone Input Select */
.phoneInput_phoneInputSelect__2cpOJ {
    width: 9rem;
    padding: unset;
    flex: unset !important;
    margin-right: 0 !important;
}

.phoneInput_phoneInputSelect__2cpOJ div.dropdown__control {
    min-height: 50px;
    background: transparent;
    border: 1px solid var(--color-gray-light-border);
    margin-top: 4px;
    padding-left: 6px;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.phoneInput_phoneInputSelect__2cpOJ div.dropdown__menu {
    border: 1px solid var(--color-gray-light-border);
    border-radius: 0;
}

.phoneInput_phoneInputSelect__2cpOJ div.dropdown__menu-list {
    max-height: 140px;
    background: transparent;
}

.phoneInput_phoneInputSelect__2cpOJ div.dropdown__option {
    background: transparent;
}

.phoneInput_phoneInputSelect__2cpOJ div.dropdown__option--is-focused {
    cursor: pointer;
    color: var(--color-gray-dark);
    background-color: var(--color-gray-lighter);
    font-weight: var(--font-weight-medium);
}

.phoneInput_phoneInputSelect__2cpOJ div.dropdown__option--is-selected {
    color: var(--color-gray-dark);
    background-color: var(--color-gray-lighter);
    font-weight: var(--font-weight-medium);
}

.phoneInput_phoneInputSelect__2cpOJ div.dropdown__control.dropdown__control--is-focused {
    border-bottom: thin solid var(--color-purple) !important;
}

/* Phone Input Text */

.phoneInput_phoneInputText__266c7 {
    flex: 1 1;
}

.phoneInput_phoneInputText__266c7 input {
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.phoneInput_phoneInputContainer__2mdaB.phoneInput_inputFocus__44wa2 div.dropdown__control,
.phoneInput_phoneInputContainer__2mdaB.phoneInput_inputFocus__44wa2 .phoneInput_phoneInputText__266c7 input {
    border-bottom: thin solid var(--color-purple) !important;
}

.phoneInput_phoneInputContainer__2mdaB.phoneInput_mouseIn__Dhcnm div.dropdown__control,
.phoneInput_phoneInputContainer__2mdaB.phoneInput_mouseIn__Dhcnm .phoneInput_phoneInputText__266c7 input {
    border-bottom: thin solid var(--color-gray-light);
}

.phoneInput_phoneInputContainer__2mdaB.phoneInput_mouseOut__39uIt div.dropdown__control,
.phoneInput_phoneInputContainer__2mdaB.phoneInput_mouseOut__39uIt .phoneInput_phoneInputText__266c7 input {
    border-bottom: thin solid var(--color-gray-lighter);
}

.phoneInput_labelDisabled__2_rEH {
    color: var(--color-gray-dark);
}

.dropdown_dropdown__3OT-c {
    font-size: var(--font-size-body);
    margin-bottom: var(--margin);
}

.dropdown_dropdown__3OT-c .dropdown__control {
    list-style: none;
    margin: 0;
    border: none;
    box-shadow: none;
    background-color: var(--color-gray-lighter);
    font-size: inherit;
    border-radius: var(--radius);
    border: thin solid var(--color-gray-lighter);
}

.dropdown_dropdown__3OT-c .dropdown__value-container,
.dropdown_dropdown__3OT-c .dropdown__placeholder {
    font-size: inherit !important;
}

.dropdown_dropdown__3OT-c .dropdown__control:hover {
    cursor: pointer;
    border: thin solid var(--color-gray-lighter);
    border-bottom: thin solid var(--color-gray-light);
}

.dropdown_dropdown__3OT-c .dropdown__indicator-separator {
    display: none;
}

.dropdown_dropdown__3OT-c .dropdown__indicator {
    color: var(--color-black);
    padding: 9px;
}

.dropdown_dropdown__3OT-c .dropdown__indicators {
    justify-content: flex-end;
    flex-grow: 0;
}

.dropdown_dropdown__3OT-c .dropdown__menu {
    border-radius: var(--radius);
    margin-top: var(--margin-internal);
    z-index: 4 !important;
}

.dropdown_dropdown__3OT-c .dropdown__option {
    padding: var(--padding);
    color: var(--color-gray-dark);
    font-size: var(--font-size-body);
    background-color: var(--color-gray-lighter);
    border: thin solid transparent;
    transition: background-color 0.2s, color 0.2s, font-weight 0.2s;
    font-weight: var(--font-weight-regular);
    flex-grow: 1;
}

.dropdown_dropdown__3OT-c .dropdown__option:active {
    color: var(--color-pearl);
    background-color: var(--color-gray-dark);
    font-weight: var(--font-weight-semibold);
}

.dropdown_dropdown__3OT-c .dropdown__option:hover {
    cursor: pointer;
    background-color: var(--color-gray);
    color: var(--color-gray-lighter);
}

.dropdown_dropdown__3OT-c .dropdown__option--is-focused {
    color: var(--color-pearl);
    background-color: var(--color-gray);
    font-weight: var(--font-weight-semibold);
}

.dropdown_dropdown__3OT-c .dropdown__option--is-selected {
    color: var(--color-pearl);
    background-color: var(--color-gray-dark);
    font-weight: var(--font-weight-semibold);
}

.dropdown_dropdown__3OT-c.dropdown_round__1KccE .dropdown__control {
    border-radius: 25px !important;
    padding-left: 1rem;
    background-color: white !important;
    border: thin solid transparent;
}

.dropdown_dropdown__3OT-c.dropdown_round__1KccE .dropdown__control:hover {
    cursor: pointer;
    border: thin solid transparent;
    border-bottom: thin solid transparent;
}

.edit_loadingContainer__1EmtO {
    flex: 1 1;
    align-items: center;
    justify-content: center;
}

.edit_loadingSpinner__B_hDO {
    width: 48px;
    height: 48px;
    color: var(--color-black);
}

.edit_checkIcon__1f90I {
    color: var(--color-red);
    margin-left: 8px;
}

.edit_container__e90xQ {
    margin-left: 32px;
}

.edit_container__e90xQ strong {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-medium);
}

.edit_container__e90xQ input {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
    padding: 12px 18px;
    margin-top: 4px;
    background-color: transparent;
    border: 1px solid var(--color-gray-light-border);
}

.edit_container__e90xQ > div {
    margin-bottom: 16px;
}

.edit_link__1SNbd {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    font-family: var(--font-family-secondary);
    line-height: var(--line-small);
    text-decoration: underline;
    color: var(--color-red);
}

.edit_avatar__2qHYM {
    width: 80px;
    height: 80px;
    margin: 0;
}

.edit_editButtons__1qZXv {
    margin-top: 32px;
}

.edit_cancelBtn__34d2n:hover {
    box-shadow: none;
    -webkit-filter: opacity(0.5);
            filter: opacity(0.5);
}

@media only screen and (min-width: 928px) {
    .edit_avatar__2qHYM {
        width: 96px;
        height: 96px;
    }
}

@media only screen and (min-width: 1360px) {
    .edit_avatar__2qHYM {
        width: 160px;
        height: 160px;
    }
}

.profileInfoCard_container__1Me4- {
    flex: 1 1;
}

.profileInfoCard_cardHeader__3N4l5 {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.profileInfoCard_cardHeader__3N4l5 > span {
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-medium);
    margin: 0;
}

.profileInfoCard_editIcon__2VZO6 {
    cursor: pointer;
    color: var(--color-red);
    width: 20px;
    height: 20px;
    justify-self: flex-end;
}

.profile_page__1kFKs {
    margin-bottom: 90px !important;
}

.profile_pageTitle__1Gvr3 {
    font-size: var(--font-h3) !important;
    line-height: var(--line-h3) !important;
}

.profile_cardsRow__X9tBC {
    margin-top: var(--margin-medium);
    flex-direction: column !important;
}

.profile_walletContainer__2AzJ8 {
    margin-bottom: var(--margin-medium);
    flex-direction: column !important;
}

.profile_cardsRow__X9tBC > div:not(:first-child) {
    margin-left: 0px !important;
}

.profile_cardsRow__X9tBC strong {
    color: black;
}

.profile_card__3hq3Z {
    border-radius: 8px;
    box-shadow: none;
}

.profile_card__3hq3Z svg {
    stroke-width: 1px;
}

.profile_minHeight__27HGf {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.profile_heightFitContent__1QtDE {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.profile_logoutBtn__1NFB9 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: var(--margin-large);
    color: var(--color-black);
}

@media only screen and (min-width: 492px) {
}

@media only screen and (min-width: 692px) {
}

@media only screen and (min-width: 928px) {
    .profile_card__3hq3Z {
        box-shadow: 0 2px 16px rgba(11, 11, 13, 0.08);
        padding: 32px;
    }

    .profile_cardsRow__X9tBC {
        flex-direction: row !important;
        margin-top: var(--margin);
        flex: 1 1 !important;
    }
    .profile_walletContainer__2AzJ8 {
        margin-bottom: var(--margin-medium);
        flex-direction: column !important;
        margin-right: var(--margin-medium);
    }

    .profile_walletContainer__2AzJ8 {
        margin-top: var(--margin-xlarge);
    }
}

@media only screen and (min-width: 1360px) {
    .profile_cardsRow__X9tBC > div:not(:first-child) {
        margin-left: var(--margin-medium) !important;
    }
    .profile_cardsRow__X9tBC {
        margin-bottom: 0px;
        margin-top: var(--margin-xlarge);
        flex: 1 1 !important;
    }
}

@media only screen and (min-width: 1680px) {
}

.rent-a-car_container__23t-7 {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.subscribe_container__3GZdG {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.leaderCard_leaderCard__3dmkA {
    box-sizing: border-box;
    box-shadow: 0 4px 16px #0b0b0d14;
}

.leaderCard_img__QUpRG {
    left: 0;
    width: 100%;
    height: auto;
}

.leaderCard_leadersTitle__3u-X9 {
    margin-bottom: 40px;
}

.leaderCard_leaderCard__3dmkA > *:not(img) {
    padding: 0 32px;
}

.leaderCard_name__2y5DC {
    font-size: var(--font-large);
    line-height: var(--line-xlarge);
    font-weight: var(--font-weight-semibold);
    margin-top: 32px;
    margin-bottom: 0;
}

.leaderCard_jobTitle__2EHbc {
    margin-bottom: 8px;
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
}

.leaderCard_body__t9mCz {
    display: block;
    font-size: var(--font);
    line-height: var(--line);
    color: var(--color-grayish);
    margin-bottom: 16px;
    font-weight: var(--font-weight-light);
}

.leaderCard_closed__1iz_H {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.leaderCard_seeMore__30Y6z {
    font-size: var(--font-small);
    line-height: 21px;
    font-weight: var(--font-weight-semibold);
    color: var(--color-red);
    text-decoration: underline;
    text-underline-offset: 1px;
    margin-bottom: 32px;
    cursor: pointer;
}

.teamCard_teamCard__6-9Ae {
    width: 100%;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: unset !important;
    max-width: 230px;
}

.teamCard_imgPlaceholder__KRXko {
    width: 100%;
    border-radius: 50%;
    max-width: 206px;
    margin-bottom: 8px;
}

.teamCard_nameText__3RKbL {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-semibold);
    margin-bottom: 0;
}

.teamCard_label__28mPv {
    margin-bottom: 0;
    text-align: center;
    font-size: var(--font-xsmall);
    font-weight: var(--font-weight-light);
}

@media screen and (min-width: 492px) {
    .teamCard_imgPlaceholder__KRXko {
        margin-bottom: 12px;
    }
}

.team_sectionRow__3oiHm {
    margin-bottom: 80px !important;
    position: relative;
}

.team_pageTitle__2OuHD > span,
.team_breadcrumbs__2aEc9 > div > a,
.team_breadcrumbs__2aEc9 > div > * {
    color: var(--color-white) !important;
}

.team_breadcrumbs__2aEc9 {
    margin-top: 32px;
}

.team_teamSection__2eevo {
    flex-direction: column !important;
    margin-top: 40px;
}

div.team_sectionRow__3oiHm > div {
    flex: unset;
    margin-right: 0;
}

div.team_sectionRow__3oiHm > div:not(:last-child) {
    margin-right: 0;
}

.team_col__U_5zN {
    width: 100%;
}

.team_col__U_5zN > .team_carouselContainer__LGGmG {
    overflow: visible;
    margin-top: 4rem !important;
}

.team_carouselItem__3wrxw {
    background-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 0 4px;
}

.team_carouselItem__3wrxw:first-child {
    /*this negative margin aligns the carousel items to the left limit*/
    margin-left: -4px;
}

.team_background__1IuHD > img {
    -webkit-filter: brightness(75%);
            filter: brightness(75%);
}

.team_arrow__3O3Bv {
    position: absolute;
    width: 44px;
    height: 44px;
    stroke: var(--color-black);
    stroke-width: 0.75px !important;
}

.team_arrowRight__2V_xz {
    right: -16px;
    bottom: -70px;
}

.team_arrowLeft__2Zd8k {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    left: -16px;
    bottom: -70px;
}

.team_leaderRectangle__2fbjt {
    z-index: -10;
    left: -110px;
    top: 87%;
    width: 180px;
}

.team_sectionRow__3oiHm > .team_teamColumn__1V_5M {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;
    grid-column-gap: 16px;
}

.team_sectionTitle__1M7DX {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
}

.team_hiringTitle__3h2HW {
    margin-bottom: 16px;
}

.team_hiringBody__3TkUm {
    line-height: var(--line);
    font-size: var(--font);
    display: block;
    margin-bottom: 0;
    font-weight: var(--font-weight-light);
}

.team_teamButton__1rdTX {
    width: 230px;
}

.team_sectionRow__3oiHm > * {
    z-index: 10;
}

.team_rect__3uN2J {
    position: absolute;
}

.team_teamRectangle__-6YK8 {
    z-index: 0;
    width: 200px;
    height: 200px;
    top: 47%;
    left: -130px;
}

.team_sectionRow__3oiHm > .team_hiringRectangle__1QoO2 {
    z-index: 0;
    bottom: -15px;
    right: -30%;
    width: 150px;
    height: 150px;
}

.team_joinTeam__3y3Sg {
    font-weight: var(--font-weight-semibold);
}

@media screen and (min-width: 492px) {
    .team_sectionRow__3oiHm > .team_teamColumn__1V_5M {
        grid-column-gap: 32px;
    }

    .team_leaderRectangle__2fbjt {
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-max-width)) / -2) - 150px);
    }

    /* this keeps the rectangle to the very edge of the screen */
    .team_teamRectangle__-6YK8 {
        left: calc(((100vw - var(--page-max-width)) / -2) - 100px);
    }

    /* this keeps the rectangle to the very edge of the screen */
    .team_sectionRow__3oiHm > .team_hiringRectangle__1QoO2 {
        right: calc(((100vw - var(--page-max-width)) / -2) - 70px);
    }
}

@media screen and (min-width: 692px) {
    .team_teamBackground__3kZFh {
        left: calc((100vw - var(--page-max-width)) / -2);
        width: 100vw;
        -webkit-transform: scale(1 + 1%);
                transform: scale(1 + 1%);
    }

    .team_carouselItem__3wrxw {
        padding: 0 16px;
    }

    .team_carouselItem__3wrxw:first-child {
        /*this negative margin aligns the carousel items to the left limit*/
        margin-left: -16px;
    }

    .team_teamRectangle__-6YK8 {
        top: 230px;
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-max-width)) / -2) - 140px);
    }

    .team_sectionRow__3oiHm > .team_teamColumn__1V_5M {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 928px) {
    .team_sectionRow__3oiHm > .team_teamColumn__1V_5M {
        grid-template-columns: repeat(4, 1fr);
    }

    .team_leaderRectangle__2fbjt {
        width: 300px;
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-width)) / -2) - 180px);
        top: 525px;
    }

    .team_teamSection__2eevo {
        margin-bottom: 120px !important;
        margin-top: 60px !important;
    }

    .team_teamButton__1rdTX {
        margin-top: 40px;
    }

    .team_hiringSection__3axlf {
        margin-bottom: 120px !important;
    }

    .team_hiringCol__3hvrC {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .team_hiringTitle__3h2HW,
    .team_hiringBody__3TkUm {
        text-align: center !important;
    }

    .team_hiringTitle__3h2HW {
        font-size: var(--font-h3);
        line-height: var(--line-h3);
        width: 580px;
        margin-bottom: 24px;
    }

    .team_hiringBody__3TkUm {
        width: 580px;
        font-size: var(--font-large);
        line-height: var(--line-xlarge);
    }

    .team_teamRectangle__-6YK8 {
        top: unset;
        bottom: 25px;
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-width)) / -2) - 150px);
    }

    .team_sectionRow__3oiHm > .team_hiringRectangle__1QoO2 {
        /* this keeps the rectangle to the very edge of the screen */
        right: calc(((100vw - var(--page-width)) / -2) - 70px);
    }
}

@media screen and (min-width: 1360px) {
    .team_carouselItem__3wrxw {
        padding: 0 20px;
    }

    .team_carouselItem__3wrxw:first-child {
        /* this negative margin aligns the carousel items to the left limit */
        margin-left: -20px;
    }

    .team_teamSection__2eevo {
        margin-bottom: 160px !important;
    }

    .team_teamButton__1rdTX {
        margin-top: 56px;
    }

    .team_teamButton__1rdTX > span {
        font-weight: var(--font-weight-light);
    }

    .team_teamRectangle__-6YK8 {
        bottom: -200px;
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-width)) / -2) - 120px);
    }

    .team_sectionRow__3oiHm > .team_teamColumn__1V_5M {
        grid-template-columns: repeat(5, 1fr);
    }

    .team_hiringSection__3axlf {
        margin-bottom: 160px !important;
    }

    .team_hiringTitle__3h2HW {
        font-size: var(--font-h2);
        line-height: var(--line-h2);
    }

    .team_joinTeam__3y3Sg {
        display: block;
        margin-top: 4px;
    }

    .team_sectionTitle__1M7DX {
        margin-bottom: 56px;
    }
}

@media screen and (min-width: 1440px) {
    .team_sectionRow__3oiHm > .team_teamColumn__1V_5M {
        grid-template-columns: repeat(6, 1fr);
    }

    .team_leaderRectangle__2fbjt {
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-max-width)) / -2) - 180px);
    }

    .team_teamRectangle__-6YK8 {
        top: 300px;
        /* this keeps the rectangle to the very edge of the screen */
        left: calc(((100vw - var(--page-max-width)) / -2) - 120px);
    }

    .team_sectionRow__3oiHm > .team_hiringRectangle__1QoO2 {
        /* this keeps the rectangle to the very edge of the screen */
        right: calc(((100vw - var(--page-max-width)) / -2) - 70px);
    }
}

@media screen and (min-width: 1680px) {
    .team_carouselItem__3wrxw {
        max-width: 428px;
    }
}

.terms_container__20h8t {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.vehicles_container__105wZ {
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
}

.payment-profile_icon__3VeSF {
    stroke: black !important;
    margin-right: 7px;
}

.payment-profile_activeIcon__2wRp3 path {
    stroke: white !important;
}

.payment-profile_title__n67uM {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-medium);
    line-height: var(--line-medium);
    margin-bottom: var(--margin);
}

.modal-error_title__QNGA_ {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-h4);
    line-height: 40px;
    margin-bottom: var(--margin-medium);
}

.modal-error_icon__LJtiY {
    align-self: center;
}

.modal-error_description__1smEl {
    font-weight: var(--font-weight-light);
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: var(--margin-large);
}

.modal-error_container__2eaWq {
    text-align: center;
    border-radius: 8px;
}

.assets-checkout_container__3E99S {
    position: relative;
    margin-bottom: 120px !important;
}

.assets-checkout_stick__ghd87 {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.assets-checkout_backIcon__2QJK0 {
    cursor: pointer;
}

.assets-checkout_content__18ROF {
    flex: unset !important;
    margin-bottom: var(--margin-xlarge);
    flex-direction: column !important;
    justify-content: space-between;
}

.assets-checkout_contentDetails__3zQpE {
    flex: 1 1 !important;
    margin-right: 0 !important;
    max-width: 800px;
}

.assets-checkout_backIcon__2QJK0 {
    width: 24px;
    height: 24px;
    margin-bottom: var(--margin);
}

.assets-checkout_sectionTitle__14GZP {
    font-size: var(--font-xlarge);
    line-height: var(--line-xlarge);
    font-weight: var(--font-weight-medium);
    margin-bottom: var(--margin-medium);
}

.assets-checkout_cancelationFirst__2iE2e {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
}

.assets-checkout_cancelationBodyBold__ml4Qz {
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    display: initial;
}

.assets-checkout_cancelationFirst__2iE2e span {
    font-weight: var(--font-weight-medium);
}

.assets-checkout_cancelationLast__3ziDk {
    font-size: var(--font-xsmall);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    margin-bottom: 0;
}

.assets-checkout_clickableLink__VUCHo {
    color: var(--color-alice);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    text-decoration: underline;
    cursor: pointer;
    align-items: center;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: pre-wrap;
}

.assets-checkout_clickableLinkRound__12JmK {
    cursor: pointer;
    align-items: center;
    margin-bottom: 8px;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: pre-wrap;
    border: 1px solid #e2e3e7;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px 24px;
    display: none;
}

.assets-checkout_bookingButton__dx24V {
    max-width: 238px;
    max-height: 56px;
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-large);
    line-height: var(--line-large);
    display: none;
}

.assets-checkout_addDriver__2MCWB :first-child {
    margin-right: 10px;
}

.assets-checkout_panelContainer__1nOKt {
    max-width: 400px;
    flex: 1 1 !important;
    align-items: flex-start;
}

.assets-checkout_splitter__3q6Bq {
    margin-top: 40px;
}

.assets-checkout_addCode__1LvCP {
    margin-bottom: 0;
}

.assets-checkout_assetPanel__2FTE3 {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: var(--margin-xlarge);
    padding: 0;
    width: 100%;
    box-shadow: none;
}

.assets-checkout_paymentMethod__1KKnk {
    max-width: 260px;
}

.assets-checkout_editBilling__2JYBT {
    margin-bottom: 0;
}

.assets-checkout_addDriverContainer__eq9uD {
    margin-top: 24px;
}

@media screen and (min-width: 492px) {
    .assets-checkout_clickableLinkRound__12JmK {
        display: flex;
    }
}

@media screen and (min-width: 692px) {
    .assets-checkout_content__18ROF {
        flex-direction: row !important;
    }

    .assets-checkout_panelContainer__1nOKt,
    .assets-checkout_contentDetails__3zQpE {
        width: calc(50% - var(--margin-xlarge) / 2) !important;
    }
    .assets-checkout_contentDetails__3zQpE {
        margin-right: var(--margin-xlarge) !important;
    }

    .assets-checkout_panelContainer__1nOKt {
        display: flex !important;
    }

    .assets-checkout_assetPanel__2FTE3 {
        box-shadow: rgb(11 11 13 / 8%) 2px 2px 16px;
        padding: var(--padding-large);
    }

    .assets-checkout_bookingButton__dx24V {
        display: block !important;
    }
}

@media screen and (min-width: 928px) {
    .assets-checkout_panelContainer__1nOKt,
    .assets-checkout_contentDetails__3zQpE {
        width: calc(50% - var(--margin-xxxlarge) / 2) !important;
    }

    .assets-checkout_panelContainer__1nOKt {
        flex: unset !important;
    }

    .assets-checkout_contentDetails__3zQpE {
        margin-right: var(--margin-xxxlarge) !important;
    }
}

@media screen and (min-width: 1360px) {
    .assets-checkout_isRow__32UQb {
        flex-direction: row !important;
        justify-content: space-between;
    }

    .assets-checkout_contentDetails__3zQpE {
        margin-right: var(--margin-xxxxlarge) !important;
    }

    .assets-checkout_paymentMethod__1KKnk {
        width: 260px;
    }

    .assets-checkout_addDriverContainer__eq9uD {
        margin-top: 0;
    }
}

:root {
    --color-alice: #e04335;
    --color-primary: #280f55;
    --color-purple: #280f55;
    --color-purple-t-dark: #280f55cc;

    --color-red: #e14436;
    --color-dark-red: #ac362c;
    --color-white: #ffffff;
    --color-gray: #70798c;
    --color-gray-shadow: #73737380;
    --color-pearl: #f5f1ed;
    --color-beige: #c7bfab;
    --color-gray-darker: #0b0b0d;
    --color-gray-dark: #3e424d;

    --color-gray-transparent: #70798c66;

    --color-grayish: #9da0aa;
    --color-gray-light: #b1b5c0;
    --color-gray-light-border: #e2e3e7;

    --color-gray-lighter: #f2f2f4;
    --color-gray-faded: #f9f9f9;
    --color-black: #0b0b0d;

    --color-gray-light-datepicker: #e4e7e7;
    --color-gray-datepicker: #cacccd;
}

html,
body,
div#root {
    font-family: 'Poppins', 'Open Sans', sans-serif;
    font-size: var(--font-xsmall);
    -webkit-appearance: none;
    -moz-appearance: none;
    --radius: 2px;
}

.overflowXHidden {
    overflow-x: hidden;
}

p {
    margin: 0;
}

.noPadding {
    padding: 0;
}

button {
    font-family: 'Poppins', 'Open Sans', sans-serif;
}

.app_contentWrapper__50y3r {
    min-height: 100vh;
    justify-content: space-between;
}

.app_contentWrapper__50y3r > div:not(:last-child) {
    flex: 1 1;
}

.app_contentWrapper__50y3r > div:first-child {
    flex: unset;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-multi-carousel-list {
    overflow: visible !important;
}

.react-multi-carousel-track {
    overflow: visible !important;
}

img {
    /* Prevent image ghost dragging */
    -webkit-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none;
}

a {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

input {
    width: 100% !important;
}

/* Remove Chakra UI Focus*/
:focus,
*[data-focus] {
    box-shadow: none !important;
    outline: 0 !important;
}

