.chevDownIcon {
    width: 20px;
    height: 20px;
}

.rotateChevUp {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
}

.rotateChevDown {
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
}

.select {
    font-size: var(--font);
    line-height: var(--line);
    margin-bottom: var(--margin);
    max-width: 400px;
}

.select :global(.select__control) {
    list-style: none;
    margin: 0;
    border-radius: 48px;
    padding: 12px 24px;
    box-shadow: none;
    background-color: var(--color-white);
    font-size: inherit;
}

.select :global(.select__control--is-focused) {
    border: 1px solid var(--color-gray-shadow);
}

.select :global(.select__control--menu-is-open) {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}

.select :global(.select__value-container),
.select :global(.select__placeholder) {
    font-size: inherit !important;
    margin-right: 16px;
}

.select :global(.select__control):hover {
    cursor: pointer;
    border-color: var(--color-gray-shadow);
}

.select :global(.select__indicator-separator) {
    display: none;
}

.select :global(.select__indicator) {
    color: var(--color-black);
    padding: 0;
}

.select :global(.select__indicators) {
    justify-content: flex-end;
    flex-grow: 0;
}

.select :global(.select__menu) {
    border: 1px solid var(--color-gray-shadow);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    box-shadow: none;
    overflow: hidden;
    margin-top: 0;
}

.select :global(.select__menu-list) {
    padding: 0;
}

.select :global(.select__option) {
    padding: 12px 32px;
    color: var(--color-gray-dark);
    font-size: inherit;
    border: thin solid transparent;
    transition: background-color 0.2s, color 0.2s, font-weight 0.2s;
    font-weight: var(--font-weight-light);
}

.select :global(.select__option--is-focused) {
    cursor: pointer;
    color: var(--color-gray-dark);
    background-color: var(--color-gray-lighter);
    font-weight: var(--font-weight-medium);
}

.select :global(.select__option--is-selected) {
    color: var(--color-gray-dark);
    background-color: var(--color-gray-lighter);
    font-weight: var(--font-weight-medium);
}
