.labelText {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-medium);
    margin-right: var(--margin-internal) !important;
    color: var(--color-black);
    margin-bottom: 4px;
    flex: none !important;
}

.valueText {
    font-size: var(--font);
    line-height: var(--line);
    font-weight: var(--font-weight-light);
    color: var(--color-black);
    margin-bottom: 4px;
}

.title {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-medium);
    line-height: var(--line-medium);
    margin-bottom: var(--margin);
    text-align: justify;
}

.simple .labelText {
    font-style: normal !important;
    font-weight: var(--font-weight-medium) !important;
    font-size: var(--font-xsmall) !important;
    color: black;
    margin-bottom: 0 !important;
}

.simple .valueText {
    font-style: normal !important;
    font-weight: var(--font-weight-light) !important;
    font-size: var(--font-xsmall) !important;
    color: black;
    margin-bottom: 0 !important;
}

.simple .title {
    margin-bottom: var(--margin-internal);
    font-size: var(--font) !important;
}
