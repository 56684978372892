.container {
    margin-bottom: var(--margin-large);
    flex: 0 !important;
}

.mobileContainer {
    margin-bottom: var(--margin-large);
}

.extrasItems {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--margin);
    gap: var(--margin);
    flex: 1 !important;
    margin-top: var(--margin-large);
}

.header {
    font-weight: var(--font-weight-medium) !important;
    font-size: var(--font-xlarge) !important;
    line-height: var(--line-xlarge);
}

.seeMore {
    color: var(--color-alice);
    font-weight: var(--font-weight-semibold);
    font-weight: 14px;
    text-decoration: underline;
    cursor: pointer;
    /* Prevent selection */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
    white-space: nowrap;
    margin-top: var(--margin-medium);
    margin-bottom: var(--margin-medium);
}

@media screen and (min-width: 1360px) {
    .extrasItems {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}
