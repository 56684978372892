div.container {
    height: fit-content;
    align-items: center;
    margin-bottom: var(--margin-large) !important;
    align-items: flex-start;
}

.avatar {
    height: 4rem;
    width: 4rem;
    margin-bottom: 0;
}
