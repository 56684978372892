.clearButton {
    flex: 0 !important;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-black);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    margin-left: 1rem;
}

.disabledButton {
    flex: 0 !important;
    white-space: nowrap;
    text-decoration: underline;
    color: var(--color-grayFaded);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-small);
    line-height: var(--line-small);
    margin-left: 1rem;
}

.modalTitle {
    position: absolute;
    margin-top: -3.5rem !important;
    font-weight: var(--font-weight-medium);
    font-size: var(--font);
    line-height: var(--line);
    color: var(--color-black);
    justify-self: center;
}

.clearButton:hover {
    opacity: 0.7;
}

.actionRow {
    margin-left: -25px;
    margin-right: -25px;
    margin-bottom: -25px;
    background: var(--color-white);
    width: 100%;
    padding: 2rem 1.5rem;
    box-shadow: 0 1px 14px 0 rgb(0 0 0 /14%);
    justify-content: space-between;
    align-items: baseline;
    box-sizing: unset;
    border-radius: 0px 0px 8px 8px;
}

.filterRow {
    flex: 0 !important;
    justify-content: flex-start;
    align-items: center;
}

.modalSubtitle {
    font-size: var(--font-large);
    line-height: var(--line-large);
    font-weight: var(--font-weight-medium);
    color: var(--color-black);
}

.modalSpan {
    font-size: var(--font-small);
    line-height: var(--line-small);
    font-weight: var(--font-weight-medium);
    color: var(--color-black);
    margin-right: 0.5rem;
}

.modalCheckbox {
    line-height: 24px !important;
    font-size: 16px !important;
}

.chevronDown {
    height: 20px !important;
    width: 10px !important;
}

.showMore {
    cursor: pointer;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

@media only screen and (max-width: 520px) {
    .filterRow {
        flex-direction: column !important;
    }
    .filterRow .clearButton {
        display: none;
    }
}
