.splitter {
    border-bottom: thin solid var(--color-gray-light-border);
    flex: 0 !important;
}

.noMargin {
    margin-bottom: 0 !important;
}

.small {
    margin-bottom: var(--margin-small) !important;
}

.normal {
    margin-bottom: var(--margin) !important;
}

.medium {
    margin-bottom: var(--margin-medium) !important;
}

.large {
    margin-bottom: var(--margin-large) !important;
}

.xlarge {
    margin-bottom: var(--margin-xlarge) !important;
}

.xxlarge {
    margin-bottom: var(--margin-xxlarge) !important;
}

.xxxlarge {
    margin-bottom: var(--margin-xxxlarge) !important;
}

.xlspace {
    margin-bottom: var(--margin-xl-space) !important;
}
