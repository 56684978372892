div .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: var(--page-max-width);
}

.left {
    justify-self: start;
}

.right {
    justify-self: end;
}

div .right svg {
    width: 24px;
    height: 24px;
}

.icon {
    width: 20px;
    height: 20px;
    stroke: black;
    stroke-width: 1px;
    box-sizing: unset;
    margin-right: var(--margin);
}

.addressComplete > input {
    width: 155px;
}

div .container {
    align-items: center;
}

div .container img {
    margin-right: 30px;
}

div .container .searchContainer div div input {
    outline: 0;
    border-width: 0 0 1px;
    border-radius: 0;
    border-color: var(--color-gray-lighter);
    margin-bottom: 0;
}

div .container .searchContainer div div input:focus {
    border-color: var(--color-gray-darker);
}

.searchContainer .inputContainer div {
    margin-bottom: 0;
}

.container .clearButton {
    flex: 0;
    align-self: flex-end;
    white-space: nowrap;
    cursor: pointer;
}

.clearButton:hover {
    opacity: 0.7;
}

.logo {
    display: none;
}

.leftIcon {
    display: flex;
}

.logoContainer {
    justify-self: start;
    margin-right: 0px !important;
}

.logoContainer a {
    width: 54px;
}

.logoContainer a div {
    background-image: url('../../../../../../assets/images/Logo_Full_Red.svg');
}

.dateInput {
    display: flex;
    align-items: center;
    flex: 1;
}

.dateInput:focus-visible {
    outline: none;
}

.arrowLeft {
    position: absolute;
    top: 119px;
    left: 22px;
}

.arrowRight {
    position: absolute;
    top: 110px;
    right: 22px;
    transform: rotate(180deg);
}

.closeIcon {
    width: 10px;
    height: 10px;
    margin-left: 10px;
}

.searchVisible > div {
    right: 0 !important;
    left: -260px !important;
}

@media screen and (min-width: 928px) {
    .dateInput :global(.DayPicker_weekHeader),
    .dateInput :global(.CalendarMonthGrid_month__horizontal) {
        margin-top: 85px;
    }

    .dateInput :global(.DayPicker_transitionContainer) {
        height: 378px !important;
    }

    .calendarCloseIcon {
        width: 100px !important;
        height: 100px !important;
        right: 0 !important;
        top: 0 !important;
        z-index: 10000 !important;
        background-color: tomato !important;
    }

    .leftIcon {
        display: none;
    }

    .logo {
        display: flex;
        vertical-align: middle;
        margin-right: 1rem;
    }

    .logoContainer {
        justify-self: start;
    }

    .dateInput {
        max-width: 350px;
    }

    .picker > div {
        padding: 4px 0 !important;
        background-color: var(--color-white) !important;
        border: 0 !important;
        border-bottom: 0.5px solid #0b0b0d33 !important;
    }

    .picker > div > div:first-child {
        padding: 0 !important;
    }

    .picker > div > div:last-child > div:last-child {
        padding: 0;
    }

    .buttonsContainer {
        justify-content: space-between;
    }

    .buttonsContainer > button:first-child {
        margin-left: 13px;
        padding: 5px 20px;
        font-size: var(--font-small);
        font-weight: var(--font-weight-medium);
        text-decoration: underline;
        border: 0;
        text-decoration-thickness: 0.5px;
        text-underline-offset: 1px;
    }

    .buttonsContainer > button:last-child {
        margin-right: 35px;
        padding: 12px 32px;
        border-radius: 88px;
        background-color: var(--color-gray-darker);
        color: var(--color-white);
        margin-bottom: 32px !important;
    }

    .calHeader {
        width: calc(100%);
    }

    .titleContainer {
        margin-right: 0 !important;
    }

    .closeContainer {
        align-items: flex-end;
    }

    .calHeader > span {
        display: block !important;
    }

    .calHeader {
        box-sizing: border-box;
        padding-left: 13px;
        padding-right: 22px;
        position: absolute;
        top: 0;
        top: 25px;
    }

    .calTitle {
        font-size: var(--font-xlarge);
        color: var(--color-gray-darker);
        font-weight: var(--font-weight-medium);
        line-height: var(--line-xlarge);
        margin-bottom: 8px;
    }

    .calDates {
        font-size: var(--font-small);
        font-weight: var(--font-weight-light);
        line-height: var(--line-small);
        margin-bottom: 0;
    }

    .btnLoading {
        background-color: var(--color-gray-lighter);
    }

    .picker > :global(.dropdown__menu) {
        margin-bottom: 2px !important;
        max-height: 160px !important;
    }

    .picker > :global(.dropdown__menu) > div {
        max-height: 150px !important;
    }

    .pickerContainer > span {
        font-weight: var(--font-weight-medium);
        color: var(--color-gray-darker);
        font-size: var(--font);
    }

    .dropdownArrow path {
        stroke: var(--color-gray-darker);
    }
}

@media screen and (min-width: 1359px) {
    .grid .gridContainer {
        justify-self: center !important;
    }
}
