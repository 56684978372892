.title {
    font-size: var(--font-medium);
    line-height: var(--line);
    font-weight: bold;
    font-family: var(--font-family-secondary);
    margin-bottom: 24px;
}

.icon {
    align-self: center;
    width: 56px;
    height: 56px;
    margin-bottom: 24px;
    margin-top: 24px;
}

.description {
    font-size: var(--font-small);
    line-height: var(--line-xsmall);
    font-weight: var(--font-weight-light);
    font-family: var(--font-family-secondary);
    margin-bottom: 32px;
}

.buttonsRow {
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem !important;
}

.container {
    text-align: center;
    border-radius: 8px;
    width: 400px;
}

.navigateButton {
    font-size: 1rem !important;
    font-weight: var(--font-weight-regular) !important;
}

.profileButton {
    font-size: 1rem !important;
    font-weight: var(--font-weight-regular) !important;
}

@media screen and (max-width: 691px) {
    .container {
        text-align: center;
        border-radius: 8px;
        max-width: 400px;
        width: calc(100% - 48px);
        margin: auto;
        height: fit-content !important;
        bottom: unset !important;
        top: 20% !important;
    }
}
