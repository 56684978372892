.dynamic {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 768px) {
    .dynamic {
        display: flex;
        flex-direction: column;
    }
}
