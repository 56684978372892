div.row,
div.dynamic {
    display: flex;
    flex-direction: row;
}

div.rowEnd {
    justify-content: flex-end;
}

div.row > div:not(:last-child),
div.row > button:not(:last-child),
div.dynamic > div:not(:last-child),
div.dynamic > button:not(:last-child) {
    margin-right: var(--margin-medium);
}

div.row > div,
div.dynamic > div {
    flex: 1;
}
